import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Founder() {
    return (
        <>
            <IndexNavbar fixed />
            <section className="bg-neutral-100">
                <div className="container mx-auto  md:px-28 px-6 md:pt-32 md:pb-32 pt-32">
                    <div className="w-full lg:w-12/12 md:w-12/12">
                        <div className="flex flex-wrap">
                            <div className="lg:w-2/12 md:w-2/12 md:pt-20">
                                <img
                                    alt="..."
                                    className="max-w-full w-24"
                                    src={require("assets/img/founderibc.png").default}
                                />
                                <h1 className="font-extrabold text-4xl pt-2">
                                    A Word From Founder
                                </h1>
                            </div>
                            <div className="lg:w-9/12 md:w-9/12 md:pt-20 md:px-12">
                                <p className="md:text-3xl text-xl font-light text-black pt-4">
                                    Let us brainstorm together on how we can succeed, specifically, a sustainable and increasingly responsible future.
                                </p>
                                <p className="md:text-3xl text-xl font-light text-black pt-6">
                                    This can be understood by looking at the daily news about economic conditions imposing a new reality, our
                                    business decisions are deeply rooted in our desire to provide good services to our members, and so we encourage
                                    you to take that first.
                                </p>
                                <p className="md:text-3xl text-xl font-light text-black pt-6">
                                    However, we have learned to see every limitation as an opportunity to act and, guided by our values,
                                    offer our customers good choices many organizations are also facing insecurity, labor issues, and
                                    rising operating costs.
                                </p>
                                <p className="md:text-3xl text-xl font-light text-black pt-6">
                                    With several years of experience in the Canadian and international markets, iBusiness Consulting has
                                    an expert vision of the many ways we can reach our goals, it also means ensuring that our products
                                    and services meet today's needs.
                                </p>
                                <div className=" md:pt-12 pt-12 pb-32">
                                    <div className="flex align">
                                        <img
                                            alt="..."
                                            className="max-w-full w-18 p-2"
                                            src={require("assets/img/founderpen.png").default}
                                        />
                                        <h1 className="font-bold text-2xl pt-4">
                                            HAKIM SAFA
                                        </h1>
                                    </div>
                                    <p className="font-normal text-xl md:-mt-4 -mt-4 pr-16 text-right">
                                        Founder
                                    </p>
                                </div>
                            </div>
                            <div className="lg:w-1/12 md:w-1/12">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}