import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";


export default function Company() {
    return (
        <>
            <IndexNavbar fixed />
            <section className="bg-neutral-100 md:px-28">
            <div className="container mx-auto  pl-2 md:pt-32 pt-32 ">
                <div className="w-full lg:w-12/12 md:w-12/12 px-4">
                    <div className="flex flex-wrap">
                        <div className="lg:w-3/12 md:w-3/12 md:pt-20">
                            <img
                                alt="..."
                                className="max-w-full w-24 "
                                src={require("assets/img/companyibc.png").default}
                            />
                            <h1 className="font-extrabold text-4xl ">
                                Our Company
                            </h1>
                        </div>
                        <div className="lg:w-8/12 md:w-8/12 pt-6">
                            <h1 className="font-extrabold md:text-3xl text-2xl text-black md:pt-20">
                                How We Become Experts?
                            </h1>
                            <p className="md:text-3xl text-xl font-light text-black pt-4">
                                Several years of experience in Canadian and international markets and we founded the consulting firm iBusiness.
                            </p>
                            <p className="md:text-3xl text-xl font-light text-black pt-4">
                                It is a specialized business consultant who provides management advice to improve organizational performance and effectiveness.
                            </p>
                            <p className="md:text-3xl text-xl font-light text-black pt-4">
                                As a professional advisor, we help companies achieve their goals and streamline their operations in specific business
                                areas such as
                            </p>
                            <p className="md:text-3xl text-xl font-light text-white pt-4">
                                <span>Management, IT, marketing, human resources and operations, In addition, we provided individual support
                                    services to entrepreneurs.</span>
                            </p>
                        </div>
                        <div className="lg:w-1/12 md:w-1/12">
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto md:px-6 md:pt-12 md:pb-32 pt-12 pb-32">
                <div className="w-full lg:w-12/12 md:w-12/12 px-6">
                    <div className="flex flex-wrap">
                        <div className="lg:w-3/12 md:w-3/12 md:pt-10">
                        </div>
                        <div className="lg:w-8/12 md:w-8/12 ">
                            <h1 className="font-extrabold md:text-3xl text-2xl text-black ">
                                What makes us a high-level consulting firm?
                            </h1>
                            <p className="md:text-3xl text-xl font-light text-black pt-6">
                                IBC's team of subject matter expert’s work with law firms and accountants to provide the organizational
                                knowledge, expertise, confidence and energy to achieve your business goals. Today digital world.
                            </p>
                            <p className="md:text-3xl text-xl font-light text-black pt-6">
                                As a solid company, we identified ourselves as an iBusiness consultancy and SAFA as a fonder.
                            </p>
                        </div>
                        <div className="lg:w-1/12 md:w-1/12">
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <Footer />
        </>
    );
}