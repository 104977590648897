import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form } from 'semantic-ui-react';
import IndexNavbar from "components/Navbars/IndexNavbar.js";


export default function Signin() {

    const [error, setError] = useState("");
    const [isActive, setActive] = useState(false);
    const history = useHistory();
    let user = JSON.parse(localStorage.getItem('user-info'));

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        // console.log(data);
        signin(data);
    }

    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            history.push(`/profile/dashboard/${user.id}`)
        }
    }, []);

    function getWithExpiry(reset) {
        const itemStr = localStorage.getItem(reset)

        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }

        const item = JSON.parse(itemStr)
        const now = new Date()

        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(reset)
            return null
        }
        return item.value
    }

    async function signin(data) {
        //  let item={email,password}
        //  console.warn(data)
        let result = await fetch("http://localhost:8000/admin/public/index.php/admin/customerreact/login", {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json()
        //   console.warn("Result",result)
        if (result.status === 400 || result.status === 404) {
            setError(result.messages.error);
        }
        else if (result.status === 500) {
            alert("Oops! Something went wrong. Please try again.");
        }
        else {
            localStorage.setItem("user-info", JSON.stringify(result))
            history.push(`/profile/dashboard/${JSON.parse(localStorage.getItem('user-info')).id}`)
        }
    }

    function myFunction() {
        var x = document.getElementById("pass");
        if (isActive) {
            setActive(false);
            x.type = "password";
        }
        else {
            setActive(true);
            x.type = "text";
        }
    }

    return (
        <>
            <IndexNavbar fixed />
            <div className="sm:lg:container mx-auto md:pt-32 lg:pt-16 pt-8 px-4">
                <div className="flex content-center items-center justify-center h-full lg:pt-20">
                    <div className="w-full lg:w-4/12 px-4  bg-white rounded-lg lg:px-16 md:px-24 lg:pt-8 pt-12 pb-12">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg  border-0">
                            <div className="rounded-t mb-0 px-6 py-6 ">
                                <div className="text-center pt-2">
                                    <h6 className="block uppercase  text-blueGray-700 text-xl font-bold">
                                        <div class="text-black ttt">
                                            Sign in with credentials
                                        </div>
                                    </h6>
                                </div>
                            </div>

                            <p className="font-bold text-green-600 text-center">{getWithExpiry("reset_to_login")}</p><br />
                            <p className="font-bold text-red-500 text-center">{error}</p>
                            <div className="flex-auto px-4 lg:px-10 py-4 pt-0">
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Field>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block  text-black ttt text-base font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Username
                                            </label>
                                            <input
                                                type="email" {...register("email",
                                                    {
                                                        required: {
                                                            value: "required",
                                                            message: "Email id is required"
                                                        },
                                                        pattern: {
                                                            value: /\S+@\S+\.\S+/,
                                                            message: "Please enter a valid email id"
                                                        }
                                                    })}
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="username"
                                            />
                                        </div>
                                    </Form.Field>
                                    {errors.email && <p className="font-bold text-red-500">{errors.email.message}</p>}

                                    <Form.Field>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block  text-black ttt text-base font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Password
                                            </label>
                                            <div className="absolute inset-y-px right-0 pr-4 pt-5 flex items-center">
                                                <a class="btn" onClick={myFunction} role="button"><i className={isActive ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></a>
                                            </div>
                                            <input id="pass"
                                                type="password" {...register("password", {
                                                    required: {
                                                        value: "required",
                                                        message: "Password is required"
                                                    },
                                                    pattern: {
                                                        value: /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                                                        message: "Password must contain at least 8 characters, one uppercase, one number and one special case character"
                                                    }
                                                })}
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Password"
                                            />
                                        </div>
                                    </Form.Field>
                                    {errors.password && <p className="font-bold text-red-500">{errors.password.message}</p>}

                                    <div className="text-center mt-6">
                                        <button className="bg-lightBlue-600 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type='submit'>Submit</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="flex flex-wrap px-4 md:px-10 break-words relative">
                            <div className="w-1/2 font-bold ">
                                <Link to="/auth/password" className="text-blueGray-700">
                                    <medium>Forgot password?</medium>
                                </Link>
                            </div>
                            <div className="w-1/2 font-bold text-right">
                                <Link to="/auth/password" className="text-blueGray-700">
                                    <medium>Change password?</medium>
                                </Link>
                            </div>
                        </div>
                        <div className="w-full w-12/12 lg:pt-6 pt-6">
                            <div className="lg:flex lg:px-0 px-6">
                                <div className="lg:w-6/12 w-12/12 px-4 items-center flex shadow-md rounded-lg ">
                                    <img
                                        className=" w-12 pt-2 text-right"
                                        src={require("assets/img/SERVICE/google.svg").default}
                                        alt="..."
                                    /> <div className="w-full text-black text-sm ttt font-semibold">
                                        Sign Up with Google
                                    </div>
                                </div>
                                <div className="lg:mx-2 lg:w-6/12 w-12/12 items-center flex lg:mt-0 mt-6 px-2 shadow-md rounded-lg ">
                                    <img
                                        className=" w-12 pt-2 text-right"
                                        src={require("assets/img/SERVICE/linkedin.svg").default}
                                        alt="..."
                                    /> <div className="w-full text-black text-sm ttt font-semibold">
                                        Sign Up with LinkedIn
                                    </div>
                                </div>

                            </div>
                        </div>
                        <h1 className="text-center lg:text-lg lg:pt-10 pt-12">
                            Don't have an account? <span className="text-lightBlue-600 px-1 lg:text-xl">Sign Up for free</span>
                        </h1>
                    </div>
                </div>
            </div>
        </>
    );
}