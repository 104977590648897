import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import { useForm } from "react-hook-form";
import { Form, Button } from 'semantic-ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Spinner from "react-spinkit";
import Moment from 'moment';

export default function Payment() {

    const location = useLocation()
    const { plan_name, price, type } = location.state;
    const [id, setId] = useState("");
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [company, setCompany] = useState("");
    const [mobile, setMobile] = useState("");
    const [purchased, setPurchased] = useState("");
    const [starts, setStarts] = useState("");
    const [expired, setExpired] = useState("");
    const [duration, setDuration] = useState("");
    const [amount, setAmount] = useState("");
    const [amounts, setAmounts] = useState('No option selected');
    const [amt, setAmt] = useState("");
    const [mon, setMon] = useState('No option selected');
    const [err, setErr] = useState("");
    const [items, setItems] = useState([]);
    const [disable, setDisable] = useState(true);
    const [coupons, setCoupons] = useState([]);
    const [suc, setSuc] = useState("");
    const [couponsuc, setCouponsuc] = useState("");
    const [couponerr, setCouponerr] = useState("");
    const [singleValue, setSingleValue] = useState("");
    const [discount_status, setDiscount_status] = useState(false);
    const [discount_value, setDiscount_value] = useState(0);
    const [DataisLoaded, setDataisLoaded] = useState(false);
    const history = useHistory();
    let user = JSON.parse(localStorage.getItem('user-info'));

    let year = new Date().getFullYear();
    let month = ((new Date().getMonth() + 1) < 10) ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`;
    let date = ((new Date().getDate()) < 10) ? `0${new Date().getDate()}` : `${new Date().getDate()}`;
    let date1 = new Date(year + '-' + month + '-' + date);
    let date2 = new Date(expired);
    let date3 = new Date(starts);
    let balance = Math.round(amount - ((Math.ceil((Math.abs(date1 - date3)) / (1000 * 60 * 60 * 24))) * (amount / duration / 30)));

    const formSchema = Yup.object().shape({
        payment: Yup.mixed()
            .required('Please choose Payment method'),
        duration: Yup.mixed()
            .required('Please choose number of months'),
        coupon: Yup.mixed()
            .notRequired(),
        terms: Yup.bool()
            .oneOf([true], "You must accept Terms and Conditions")
    })
    const formOptions = { resolver: yupResolver(formSchema) }
    const { register, resetField, getValues, setValue, handleSubmit, formState } = useForm(formOptions)
    const { errors } = formState

    const onSubmit = (data) => {
        //   console.log(JSON.stringify(data, null, 4))
        fetch(`https://ibusinessconsulting.ca/admin/upgradereact/create`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        }).then((resp) => {
            resp.json().then((result) => {
                //  console.warn("Result", result)
                if (result.status === 400) {
                    //  setErr(result.errors);
                    setErr("Oops! Something went wrong. Please try again.");
                }
                else {
                    setSuc("Success");
                    history.push(`/profile/dashboard/${JSON.parse(localStorage.getItem('user-info')).id}`)
                }
            })
        })
    }

    useEffect(() => {
        fetch("https://ibusinessconsulting.ca/admin/Chargesreact")
            .then(res => res.json())
            .then(
                (result) => {
                    setDataisLoaded(true);
                    setItems(result);
                })
    }, [])
    
    let loop = true;

    async function discount(e) {
        e.preventDefault();
        setSingleValue(getValues("coupon"));
        fetch("https://ibusinessconsulting.ca/admin/Couponreact")
            .then(res => res.json())
            .then(
                (result) => {
                    setCoupons(result);
                    /*       console.log("Array: ", result);
                           console.warn("Length", result.length)
                           console.warn("String: ", JSON.stringify(result))
                           console.warn("Coupon code: ", JSON.stringify(result, ["coupon_code"])) */
                    if (getValues("coupon") != '') {
                        for (let i = 0; i < result.length; i++) {
                            /*     console.log(result[i]['coupon_code']);
                                 console.log("Coupon code", getValues("coupon"))
                                console.log(result[i]['coupon_code'].localeCompare(getValues("coupon"), undefined, { numeric: true, sensitivity: 'accent' }) === 0 ? "string equal" : "unequal");
                                 if (result[i]['coupon_code'] === getValues("coupon")) { //case sensitive //only alphabets
                                 if (result[i]['coupon_code'].localeCompare(getValues("coupon"), undefined, { numeric: true, sensitivity: 'accent' }) === 0) {
                                 if (result[i]['coupon_code'].toUpperCase() === getValues("coupon").toUpperCase()) {  //case insensitive  */
                            if (loop) {
                                if (result[i]['coupon_code'].localeCompare(getValues("coupon"), undefined, { numeric: true, sensitivity: 'accent' }) === 0) {
                                    loop = false;
                                    if (result[i]['status'] === "Active") {
                                        if (result[i]['end_date'] >= (year + '-' + month + '-' + date) && result[i]['start_date'] <= (year + '-' + month + '-' + date)) {
                                            if (amounts > result[i]['min_cart'] || result[i]['min_cart'] === 'NA') {
                                                if (result[i]['discount_type'] === "Percentage") {
                                                    if (result[i]['max_discount'] > (amounts * result[i]['discount'] / 100) || result[i]['max_discount'] === 'NA') {
                                                        setCouponsuc("Coupon code has been applied and redeemed successfully");
                                                        setCouponerr("");
                                                        resetField("coupon");
                                                        setDiscount_status(true);
                                                        setDiscount_value(Math.round((amounts - (amounts * result[i]['discount'] / 100)) * 100) / 100);
                                                        setValue("amount_after_coupon", Math.round((amounts - (amounts * result[i]['discount'] / 100)) * 100) / 100);
                                                        setValue("coupon_discount", Math.round((amounts * result[i]['discount'] / 100) * 100) / 100);
                                                    }
                                                    else {
                                                        setCouponsuc("Coupon code has been applied and redeemed successfully");
                                                        setCouponerr("");
                                                        resetField("coupon");
                                                        setDiscount_status(true);
                                                        setDiscount_value(amounts - result[i]['max_discount']);
                                                        setValue("amount_after_coupon", amounts - result[i]['max_discount']);
                                                        setValue("coupon_discount", result[i]['max_discount']);
                                                    }
                                                }
                                                else {
                                                    setCouponsuc("Coupon code has been applied and redeemed successfully");
                                                    setCouponerr("");
                                                    resetField("coupon");
                                                    setDiscount_status(true);
                                                    setDiscount_value(amounts - result[i]['discount']);
                                                    setValue("amount_after_coupon", amounts - result[i]['discount']);
                                                    setValue("coupon_discount", result[i]['discount']);
                                                }
                                            }
                                            else {
                                                setCouponsuc("");
                                                setCouponerr("Minimum cart value not satisfied");
                                                setDiscount_status(false);
                                                setValue("amount_after_coupon", amounts);
                                                setValue("coupon_discount", Number(0));
                                            }
                                        }
                                        else {
                                            setCouponsuc("");
                                            setCouponerr("Coupon code date mismatch");
                                            setDiscount_status(false);
                                            setValue("amount_after_coupon", amounts);
                                            setValue("coupon_discount", Number(0));
                                        }
                                    }
                                    else {
                                        setCouponsuc("");
                                        setCouponerr("Coupon code status is expired");
                                        setDiscount_status(false);
                                        setValue("amount_after_coupon", amounts);
                                        setValue("coupon_discount", Number(0));
                                    }
                                }
                                else {
                                    setCouponsuc("");
                                    setCouponerr("Coupon code doesn't exist");
                                    setDiscount_status(false);
                                    setValue("amount_after_coupon", amounts);
                                    setValue("coupon_discount", Number(0));
                                }
                            }
                        }
                    }
                    else {
                        setCouponsuc("");
                        setCouponerr("Please enter Coupon code");
                        setDiscount_status(false);
                        setValue("amount_after_coupon", amounts);
                        setValue("coupon_discount", Number(0));
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    useEffect(() => {
        getUserById();
    }, []);

    const getUserById = async () => {
        await fetch(`https://ibusinessconsulting.ca/admin/customerreact/single/${user.id}`)
            .then(res => res.json())
            .then(
                (result) => {
                    //  console.log("Result", result)
                    setId(result.id)
                    setFname(result.fname)
                    setLname(result.lname)
                    setEmail(result.email)
                    setStreet(result.street)
                    setCity(result.city)
                    setCountry(result.country)
                    setState(result.state)
                    setZipcode(result.zipcode)
                    setCompany(result.company)
                    setMobile(result.mobile)
                    /*    setPurchased(result.purchased)
                        setAmount(result.amount)
                        setDuration(result.duration)
                        setStarts(result.starts)
                        setExpired(result.expired)  */
                    setDataisLoaded(true)
                })

        await fetch(`https://ibusinessconsulting.ae/admin/upgradereact/customers_upgrade/${user.id}`)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("Result", result)
                    setPurchased(result[0].purchased)
                    setAmount(result[0].amount)
                    setDuration(result[0].duration)
                    setStarts(result[0].starts)
                    setExpired(result[0].expired)
                })
    }

    if (!DataisLoaded) return <div class="flex h-screen justify-center items-center">
        <div class="text-center bg-blue-400">
            <Spinner name="chasing-dots" />
        </div>
    </div>;
    return (
        <>
            <IndexNavbar fixed />
            <p className="font-bold text-green-600 text-center">{suc}</p>
            <p className="font-bold text-red-500 text-center">{err}</p>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div class="lg:flex sm:flex-row sm:pb-100 pl-6">
                    <div class="flex-1 w-64 pt-12 mb-12 px-12">
                        <h6 className="text-blueGray-500 block uppercase  text-center pb-4 text-xl font-bold">
                            Purchase Information
                        </h6>
                        <br />{expired >= (year + '-' + month + '-' + date) ? "Current Membership Valid Till: " + Moment(expired).format('DD/MM/YYYY') : "Current Membership expired"}
                        {/*                <br />{expired >= (year + '-' + month + '-' + date) ? "Active" : "Inactive"}
                        <br />Amount {amount}
                        <br />Duration {duration}
                        <br />Per Month {(amount / duration)}
                        <br />Per Day {(amount / duration / 30)}
                        <br />Remaining days {expired >= (year + '-' + month + '-' + date) ? Math.ceil((Math.abs(date2 - date1)) / (1000 * 60 * 60 * 24)) : "0"}
                        <br />Completed days {Math.ceil((Math.abs(date1 - date3)) / (1000 * 60 * 60 * 24))}
                        <br />Amount spent so far {(Math.ceil((Math.abs(date1 - date3)) / (1000 * 60 * 60 * 24))) * (amount / duration / 30)}
                        <br />Balance amount {amount - ((Math.ceil((Math.abs(date1 - date3)) / (1000 * 60 * 60 * 24))) * (amount / duration / 30))}
                        <br />Balance amount round off {Math.round(amount - ((Math.ceil((Math.abs(date1 - date3)) / (1000 * 60 * 60 * 24))) * (amount / duration / 30)))}
                         */}
                        <Form.Field>
                            <div className="relative w-full mt-3  mb-6">
                                <ul>
                                    <div className="  flex break-words transition ease-out duration-150" style={{
                                        padding: "1%"
                                    }}>
                                        {items.map(item => (
                                            <li key={item.id}>
                                                <div className=" flex border bg-white transition ease-out duration-150 hover:bg-lightBlue-200 rounded-lg">
                                                    <label>
                                                        <div className="block uppercase text-blueGray-600 text-sm font-bold mb-2 px-4 py-6">
                                                            <input type="radio" value={item.month} name="duration"  {...register('duration')}
                                                                className={`form-control ${errors.duration ? 'is-invalid' : ''}`}
                                                                onClick={() => {
                                                                    setAmt((Number(price) * Number(item.month)).toFixed(2));
                                                                    setAmounts((Number(price) * Number(item.month) - balance > 0 ? Number(price) * Number(item.month) - balance : 0).toFixed(2));
                                                                    setMon(Number(item.month) * 30);
                                                                    setValue("amount", (Number(price) * Number(item.month)).toFixed(2));
                                                                    setValue("amount_after_coupon", (Number(price) * Number(item.month) - balance > 0 ? Number(price) * Number(item.month) - balance : 0).toFixed(2));
                                                                    setValue("coupon_discount", Number(0));
                                                                    setDisable(false);
                                                                    setDiscount_status(false);
                                                                    setCouponerr("");
                                                                    setCouponsuc("");
                                                                    setValue("original_amount", (Number(price) * Number(item.month) - balance > 0 ? Number(price) * Number(item.month) - balance : 0));
                                                                    setValue("fname", fname);
                                                                    setValue("lname", lname);
                                                                    setValue("email", email);
                                                                    setValue("mobile", mobile);
                                                                    setValue("street", street);
                                                                    setValue("city", city);
                                                                    setValue("state", state);
                                                                    setValue("country", country);
                                                                    setValue("zipcode", zipcode);
                                                                }} />&nbsp;
                                                            Choose {item.name} membership
                                                        </div>
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                    </div>
                                </ul>
                            </div>
                        </Form.Field>
                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.duration?.message}</div>

                        <Form.Field>
                            <div className="relative w-full mb-3">
                                <input name="customer_id" defaultValue={user.id}
                                    type="hidden" {...register('customer_id')}
                                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Customer id"
                                />
                            </div>
                        </Form.Field>
                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.customer_id?.message}</div>

                        <Form.Field>

                            <div className="relative w-full mb-3">
                                <input name="type" defaultValue={type}
                                    type="hidden" {...register('type')}
                                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Type"
                                />
                            </div>
                        </Form.Field>
                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.type?.message}</div>

                        <Form.Field>
                            <div className="relative w-full mb-3">
                                <input name="amount"
                                    type="hidden" {...register('amount')}
                                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Amount"
                                />
                            </div>
                        </Form.Field>
                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.amount?.message}</div>

                        <Form.Field>
                            <div className="relative w-full mb-3">
                                <input name="original_amount"
                                    type="hidden" {...register('original_amount')}
                                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Original Amount"
                                />
                            </div>
                        </Form.Field>
                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.original_amount?.message}</div>

                        <Form.Field>
                            <div className="relative w-full mb-3">
                                <input name="amount_after_coupon"
                                    type="hidden" {...register('amount_after_coupon')}
                                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Amount after coupon"
                                />
                            </div>
                        </Form.Field>
                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.amount_after_coupon?.message}</div>

                        <Form.Field>
                            <div className="relative w-full mb-3">
                                <input name="coupon_discount"
                                    type="hidden" {...register('coupon_discount')}
                                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Coupon Discount"
                                />
                            </div>
                        </Form.Field>
                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.coupon_discount?.message}</div>

                        <Form.Field>
                            <div className="relative w-full mb-3">
                                <input name="purchased" defaultValue={plan_name}
                                    type="hidden" {...register('purchased')}
                                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Purchased"
                                />
                            </div>

                        </Form.Field>
                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.purchased?.message}</div>
                        <div className="text-left mb-6">
                            <h6 className="text-blueGray-500 text-sm font-bold">
                                Payment Information
                            </h6>
                            CHOOSE YOUR PAYMENT METHOD
                        </div>

                        <Form.Field>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-6/12 pl-3">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-sm font-bold mb-2">
                                            <input type="radio" value="credit" name="payment" {...register('payment')}
                                                className={`form-control ${errors.payment ? 'is-invalid' : ''}`} />&nbsp;
                                            Credit Card&nbsp;&nbsp;
                                        </label>
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 pl-3">
                                    <div className="relative w-full mb-3">

                                        <label
                                            className="block uppercase text-blueGray-600 text-sm font-bold mb-2">
                                            <input type="radio" value="bank" name="payment" {...register('payment')}
                                                className={`form-control ${errors.payment ? 'is-invalid' : ''}`} />&nbsp;
                                            Bank Payment&nbsp;&nbsp;
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Form.Field>
                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.payment?.message}</div>

                        <div className="text-left mt-3 mb-3">
                            <h6 className="text-blueGray-500 text-sm font-bold">
                                Coupon Code
                            </h6>
                        </div>

                        {/*
                        {coupons.some(checkAdult) ?
                            <>
                                {coupons.map(coupon => (
                                    <p key={coupon.id}>
                                        {coupon.coupon_code === singleValue ?
                                            <>
                                                <br />Code {coupon.coupon_code}
                                                <br />Discount {coupon.discount}
                                                <br />Type {coupon.discount_type}
                                                <br />Discount perc {coupon.discount_type == "Percentage" ? (coupon.discount / 100) : coupon.discount}
                                                <br />Discount value {coupon.discount_type == "Percentage" ? (amount * (coupon.discount / 100)) : coupon.discount}
                                                <br />Amt {amounts}
                                                <br />Amount {coupon.discount_type == "Percentage" ? amounts - (amount * (coupon.discount / 100)) : amounts - coupon.discount}
                                                <br />Minimum  cart value {coupon.min_cart}
                                                <br />Maximum discount amount {coupon.max_discount}
                                                <br />Status {coupon.status}
                                                <br />Start date {coupon.start_date}
                                                <br />End date {coupon.end_date}
                                                <br />Calculation {coupon.status === "Active" ?
                                                    <>
                                                        {coupon.end_date >= (year + '-' + month + '-' + date) ?
                                                            <>
                                                                {amounts > coupon.min_cart ?
                                                                    <>
                                                                        {coupon.discount_type == "Percentage" ?
                                                                            <>
                                                                                <p>Discount type is Percentage</p>
                                                                                {amount * (coupon.discount / 100)}
                                                                                {coupon.max_discount > amount * (coupon.discount / 100) ?
                                                                                    amounts - (amount * (coupon.discount / 100))
                                                                                    :
                                                                                    amounts - (coupon.max_discount)
                                                                                }
                                                                            </>
                                                                            : <><p>Discount type is amount</p>{amounts - coupon.discount}</>}
                                                                    </>
                                                                    : <><p>Minimum cart value not satisfied</p></>}
                                                            </>
                                                            : <><p>Coupon code date is expired</p></>}
                                                    </>
                                                    : <><p>Coupon code status is expired</p></>}
                                            </>
                                            :
                                            null
                                        }
                                    </p>
                                ))}
                            </>
                            :
                            <><p>Coupon code doesn't exist</p></>}
                             */}
                        <Form.Field>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Coupon Code
                                </label>

                                <div className="text-center flex justify-between">
                                    <input name="coupon"
                                        type="text" {...register('coupon')}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Coupon Code"
                                    />
                                    <button
                                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        type="button" disabled={disable} onClick={discount}
                                    >
                                        Apply
                                    </button>
                                </div>
                                <p className="font-bold text-green-600 text-center">{couponsuc}</p>
                                <p className="font-bold text-red-500 text-center">{couponerr}</p>
                            </div>
                        </Form.Field>
                    </div>

                    <div class="flex-1 w-64 px-12 pt-12 pb-6 bg-neutral-100">
                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.coupon_name?.message}</div>
                        <div className="text-left pt-4  mb-6">
                            <h6 className="text-blueGray-500 text-sm font-bold">
                                Review Order Details
                            </h6>
                        </div>

                        <div className="relative w-full border-2 mb-3">
                            <table class="border-collapse w-full">
                                <tr>
                                    <th className="text-left  py-2 px-6 border border-slate-400">Plan</th>
                                    <td className=" border border-slate-400 text-right px-6">{plan_name}</td>
                                </tr>
                                <tr>
                                    <th className="text-left py-2 px-6 border border-slate-400">Duration</th>
                                    <td className="border border-slate-400 text-right px-6">{mon != 'No option selected' ? mon + " Days" : mon}</td>
                                </tr>
                                <tr>
                                    <th className="text-left py-2 px-6 border border-slate-400">SUBTOTAL</th>
                                    <td className="border border-slate-400 font-bold text-xl text-right px-6">
                                        {discount_status ?
                                            <>{"$ " + discount_value}&nbsp;
                                                <span className='font-bold text-xl text-right pl-6 line-through'>{amounts != 'No option selected' ? "$ " + amounts : amounts}</span>&nbsp;
                                                <span className="font-bold text-xl text-right pl-6 line-through">{amt != '' ? "$ " + amt : amt}</span>
                                            </>
                                            :
                                            <>
                                                {amounts != 'No option selected' ? "$ " + amounts : amounts}&nbsp;<span className="font-bold text-xl text-right pl-6 line-through">{amt != '' ? "$ " + amt : amt}</span>
                                            </>
                                        }
                                    </td>

                                </tr>
                                <tr>
                                    <th className="text-left py-2 px-6 border border-slate-400">TOTAL DUE TODAY</th>
                                    <td className="text-right border font-bold text-xl border-slate-400 px-6">
                                        {discount_status ? "$ " + discount_value : (amounts != 'No option selected' ? "$ " + amounts : amounts)}
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <Form.Field>
                            <div>
                                <label className="inline-flex items-center cursor-pointer">
                                    <input
                                        id="customCheckLogin" name="terms" {...register('terms')}
                                        type="checkbox"
                                        className="form-checkbox border-2 mt-4 shadow-lg rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                    />
                                    <span className="ml-2 mt-4 text-sm font-semibold text-blueGray-600">
                                        I agree with the{" "}
                                        <a
                                            href="#pablo"
                                            className="text-lightBlue-500"
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            Privacy Policy
                                        </a>
                                    </span>
                                </label>
                            </div>
                        </Form.Field>
                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.terms?.message}</div>
                        <div className="text-center mt-6 px-12">
                            <Button className="ui secondary button bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type='submit'>Submit</Button>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    )
}