import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import { useForm } from "react-hook-form";
import { Form, Button } from 'semantic-ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export default function Register() {

  const location = useLocation()
  const { plan_name, price } = location.state;
  // console.log({ plan_name });

  const [amounts, setAmounts] = useState('No option selected');
  const [mon, setMon] = useState('No option selected');
  const [err, setErr] = useState("");
  const [isActive, setActive] = useState(false);
  const [isActivecp, setActivecp] = useState(false);
  const [couponsuc, setCouponsuc] = useState("");
  const [couponerr, setCouponerr] = useState("");
  const [discount_status, setDiscount_status] = useState(false);
  const [discount_value, setDiscount_value] = useState(0);
  const [disable, setDisable] = useState(true);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const history = useHistory();
  let user = JSON.parse(localStorage.getItem('user-info'));

  let year = new Date().getFullYear();
  let month = ((new Date().getMonth() + 1) < 10) ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`;
  let date = ((new Date().getDate()) < 10) ? `0${new Date().getDate()}` : `${new Date().getDate()}`;

  const formSchema = Yup.object().shape({
    email: Yup.string().email()
      .required('Email id is mandatory')
      .matches(/^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/, "Please enter valid Email id"),
    password: Yup.string()
      .required('Password is mandatory')
      .min(8, 'Password must contain at least 8 characters')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confpassword: Yup.string()
      .required('Confirm Password is mandatory')
      .oneOf([Yup.ref('password')], 'Password & Confirm Password does not match'),
    fname: Yup.string()
      .required('Please enter First name')
      .min(4, 'First name is too short')
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    lname: Yup.string()
      .required('Please enter Last name')
      .min(4, 'Last name is too short')
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    street: Yup.string()
      .required('Please enter Street Address')
      .min(10, 'Street Address is too short'),
    city: Yup.string()
      .required('Please enter City')
      .min(4, 'City is too short')
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    zipcode: Yup.string()
      .required('Please enter ZIP Code')
      .min(4, 'ZIP Code is too short')
      .matches(/^[0-9]*$/, "ZIP Code is numeric"),
    country: Yup.string()
      .required('Please enter Country')
      .min(4, 'Country is too short'),
    state: Yup.string()
      .required('Please enter State')
      .min(4, 'State is too short')
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    company: Yup.string()
      .nullable()
      .notRequired(),
    mobile: Yup.string()
      .required('Please enter Mobile number')
      .min(10, 'Mobile number is too short')
      .matches(
        /^[6-9]\d{9}$/,
        "Please enter valid Mobile number"
      ),
    purchased: Yup.mixed()
      .notRequired(),
    payment: Yup.mixed()
      .required('Please choose Payment method'),
    duration: Yup.mixed()
      .required('Please choose number of months'),
    coupon: Yup.mixed()
      .notRequired(),
    terms: Yup.bool()
      .oneOf([true], "You must accept Terms and Conditions")
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { register, resetField, handleSubmit, getValues, setValue, formState } = useForm(formOptions)
  const { errors } = formState

  const onSubmit = (data) => {
    //  console.log(JSON.stringify(data, null, 4))
    signup(data)
    return false
  }

  useEffect(() => {
    if (localStorage.getItem('user-info')) {
      history.push(`/profile/dashboard/${user.id}`)
    }
  }, []);

  async function signup(data) {
    //  console.warn(data)
    let result = await fetch("https://ibusinessconsulting.ca/admin/customerreact/register", {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
    result = await result.json()
    //  console.warn("Result", result)
    if (result.status === 400) {
      setErr("Email id is already in use. Please try with different email.");
    }
    else {
      localStorage.setItem("user-info", JSON.stringify(result))
      history.push(`/profile/dashboard/${JSON.parse(localStorage.getItem('user-info')).id}`)
    }
  }


  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch("https://ibusinessconsulting.ca/admin/Chargesreact")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  let loop = true;

  async function discount(e) {
    e.preventDefault();
    fetch("https://ibusinessconsulting.ae/admin/Couponreact")
      .then(res => res.json())
      .then(
        (result) => {
          if (getValues("coupon") != '') {
            for (let i = 0; i < result.length; i++) {
              if (loop) {
                if (result[i]['coupon_code'].localeCompare(getValues("coupon"), undefined, { numeric: true, sensitivity: 'accent' }) === 0) {
                  loop = false;
                  if (result[i]['status'] === "Active") {
                    if (result[i]['end_date'] >= (year + '-' + month + '-' + date) && result[i]['start_date'] <= (year + '-' + month + '-' + date)) {
                      if (amounts > result[i]['min_cart'] || result[i]['min_cart'] === 'NA') {
                        if (result[i]['discount_type'] === "Percentage") {
                          if (result[i]['max_discount'] > (amounts * result[i]['discount'] / 100) || result[i]['max_discount'] === 'NA') {
                            setCouponsuc("Coupon code has been applied and redeemed successfully");
                            setCouponerr("");
                            resetField("coupon");
                            setDiscount_status(true);
                            setDiscount_value(Math.round(amounts - (amounts * result[i]['discount'] / 100)));
                            setValue("coupon_discount", Math.round((amounts * result[i]['discount'] / 100) * 100) / 100);
                          }
                          else {
                            setCouponsuc("Coupon code has been applied and redeemed successfully");
                            setCouponerr("");
                            resetField("coupon");
                            setDiscount_status(true);
                            setDiscount_value(amounts - result[i]['max_discount']);
                            setValue("coupon_discount", result[i]['max_discount']);
                          }
                        }
                        else {
                          setCouponsuc("Coupon code has been applied and redeemed successfully");
                          setCouponerr("");
                          resetField("coupon");
                          setDiscount_status(true);
                          setDiscount_value(amounts - result[i]['discount']);
                          setValue("coupon_discount", result[i]['discount']);
                        }
                      }
                      else {
                        setCouponsuc("");
                        setCouponerr("Minimum cart value not satisfied");
                        setDiscount_status(false);
                        setValue("coupon_discount", Number(0));
                      }
                    }
                    else {
                      setCouponsuc("");
                      setCouponerr("Coupon code date mismatch");
                      setDiscount_status(false);
                      setValue("coupon_discount", Number(0));
                    }
                  }
                  else {
                    setCouponsuc("");
                    setCouponerr("Coupon code status is expired");
                    setDiscount_status(false);
                    setValue("coupon_discount", Number(0));
                  }
                }
                else {
                  setCouponsuc("");
                  setCouponerr("Coupon code doesn't exist");
                  setDiscount_status(false);
                  setValue("coupon_discount", Number(0));
                }
              }
            }
          }
          else {
            setCouponsuc("");
            setCouponerr("Please enter Coupon code");
            setDiscount_status(false);
            setValue("coupon_discount", Number(0));
          }
        },
        (error) => {
          //  console.log(error);
        }
      )
  }

  function myFunction() {
    var x = document.getElementById("pass");
    if (isActive) {
      setActive(false);
      x.type = "password";
    }
    else {
      setActive(true);
      x.type = "text";
    }
  }

  function myFunctiontwo() {
    var x = document.getElementById("cpass");
    if (isActivecp) {
      setActivecp(false);
      x.type = "password";
    }
    else {
      setActivecp(true);
      x.type = "text";
    }
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <IndexNavbar fixed />
        <p className="font-bold text-red-500 text-center">{err}</p>
        <Form onSubmit={handleSubmit(onSubmit)} className="md:block hidden">
          <div class="flex flex-wrap  sm:pt-52 pb-20 ">
            <div class="flex-col  w-8/12 lg:pt-12 mb-12 lg:px-40  mr-48" id="left">
              <h6 className="text-blueGray-500 block uppercase  font-sans text-left pb-4 text-xl font-bold">
                Sign up with credentials
              </h6>
              <h6 className="text-blueGray-500 text-left text-sm font-bold md:pb-6">
                Account information (fields marked with * are required)
              </h6>

              <Form.Field>
                <div className="relative w-full mb-3 pr-2">
                  <label
                    className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email*
                  </label>
                  <input name="email"
                    type="email" {...register("email")}
                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                  />
                </div>
              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.email?.message}</div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 pr-2">
                  <Form.Field>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Password*
                      </label>
                      <div className="absolute inset-y-px right-0 pr-4 pt-5 flex items-center">
                        <a class="btn" onClick={myFunction} role="button"><i className={isActive ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></a>
                      </div>
                      <input id="pass" name="password"
                        type="password" {...register('password')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Password"
                      />
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.password?.message}</div>
                </div>
                <div className="w-full lg:w-6/12 pr-2">
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Confirm Password*
                      </label>
                      <div className="absolute inset-y-px right-0 pr-4 pt-5 flex items-center">
                        <a class="btn" onClick={myFunctiontwo} role="button"><i className={isActivecp ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></a>
                      </div>
                      <input id="cpass" name="confpassword"
                        type="password" {...register('confpassword')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Confirm Password"
                      />
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.confpassword?.message}</div>
                </div>
              </div>
              <div className="text-left mt-3 mb-6">
                <h6 className="text-blueGray-500 text-sm font-bold">
                  Client Information (fields marked with * are required)
                </h6>

              </div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 pr-2 ">
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        First Name*
                      </label>
                      <input name="fname"
                        type="text" {...register('fname')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="First Name"
                      />
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.fname?.message}</div>
                </div>
                <div className="w-full lg:w-6/12 pr-2">
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Last Name*
                      </label>
                      <input name="lname"
                        type="text" {...register('lname')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Last Name"
                      />
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.lname?.message}</div>
                </div>
              </div>
              <div className="w-full pr-2">
                <Form.Field>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Street Address*
                    </label>
                    <input name="street"
                      type="text" {...register('street')}
                      className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Street Address"
                    />
                  </div>
                </Form.Field>
                <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.street?.message}</div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 pr-2">
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        City*
                      </label>
                      <input name="city"
                        type="text" {...register('city')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="City"
                      />
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.city?.message}</div>
                </div>
                <div className="w-full lg:w-6/12 pr-2">
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        ZIP Code*
                      </label>
                      <input name="zipcode"
                        type="text" {...register('zipcode')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="ZIP Code"
                      />
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.zipcode?.message}</div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 pr-2">
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Country*
                      </label>
                      <select name="country"
                        {...register('country')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Country"
                      >
                        <option value="" disabled selected hidden>Select country</option>
                        <option value="Afganistan">Afghanistan</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bonaire">Bonaire</option>
                        <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                        <option value="Brunei">Brunei</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Canary Islands">Canary Islands</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Channel Islands">Channel Islands</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos Island">Cocos Island</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote DIvoire">Cote DIvoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curaco">Curacao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="East Timor">East Timor</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands">Falkland Islands</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Ter">French Southern Ter</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Great Britain">Great Britain</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="India">India</option>
                        <option value="Iran">Iran</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea North">Korea North</option>
                        <option value="Korea Sout">Korea South</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Laos">Laos</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libya">Libya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macau">Macau</option>
                        <option value="Macedonia">Macedonia</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Midway Islands">Midway Islands</option>
                        <option value="Moldova">Moldova</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Nambia">Nambia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherland Antilles">Netherland Antilles</option>
                        <option value="Netherlands">Netherlands (Holland, Europe)</option>
                        <option value="Nevis">Nevis</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau Island">Palau Island</option>
                        <option value="Palestine">Palestine</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Phillipines">Philippines</option>
                        <option value="Pitcairn Island">Pitcairn Island</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Republic of Montenegro">Republic of Montenegro</option>
                        <option value="Republic of Serbia">Republic of Serbia</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russia">Russia</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="St Barthelemy">St Barthelemy</option>
                        <option value="St Eustatius">St Eustatius</option>
                        <option value="St Helena">St Helena</option>
                        <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                        <option value="St Lucia">St Lucia</option>
                        <option value="St Maarten">St Maarten</option>
                        <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                        <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                        <option value="Saipan">Saipan</option>
                        <option value="Samoa">Samoa</option>
                        <option value="Samoa American">Samoa American</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syria">Syria</option>
                        <option value="Tahiti">Tahiti</option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania">Tanzania</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Erimates">United Arab Emirates</option>
                        <option value="United States of America">United States of America</option>
                        <option value="Uraguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Vatican City State">Vatican City State</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                        <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                        <option value="Wake Island">Wake Island</option>
                        <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zaire">Zaire</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.country?.message}</div>
                </div>
                <div className="w-full lg:w-6/12 pr-2">
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        State*
                      </label>
                      <input name="state"
                        type="text" {...register('state')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="State"
                      />
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.state?.message}</div>
                </div></div>
              <Form.Field>
                <div className="relative w-full mb-3 pr-2">
                  <label
                    className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Company
                  </label>
                  <input name="company"
                    type="text" {...register('company')}
                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Company"
                  />
                </div>
              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.company?.message}</div>

              <Form.Field>
                <div className="relative w-full mb-3 pr-2">
                  <label
                    className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Mobile number*
                  </label>
                  <input name="mobile"
                    type="text" {...register('mobile')}
                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Mobile number"
                  />
                </div>
              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.mobile?.message}</div>
              <div className="text-left mb-3 pr-2">
                <h6 className="block uppercase text-blueGray-500 text-lg font-bold">
                  Purchase Information
                </h6>
              </div>
              <Form.Field>
                <div className="relative w-full mt-3  mb-6">
                  <ul>
                    <div className="  flex break-words transition ease-out duration-150" style={{
                      padding: "1%"
                    }}>
                      {items.map(item => (
                        <li key={item.id}>
                          <div className=" flex mr-2 bg-blueGray-100  border bg-white transition ease-out duration-150 hover:bg-lightBlue-200 rounded">
                            <label>
                              <div className="block uppercase text-blueGray-600 text-sm font-bold mb-2 px-2 lg:px-4 py-6">
                                <input type="radio" value={item.month} name="duration"  {...register('duration')}
                                  className={`form-control ${errors.duration ? 'is-invalid' : ''}`}
                                  onClick={() => {
                                    setAmounts((Number(price) * Number(item.month)).toFixed(2));
                                    setMon(Number(item.month) * 30);
                                    setValue("amount", (Number(price) * Number(item.month)).toFixed(2));
                                    setValue("coupon_discount", Number(0));
                                    setDisable(false);
                                    setDiscount_status(false);
                                    setCouponerr("");
                                    setCouponsuc("");
                                  }} />&nbsp;
                                {item.name} membership
                              </div>
                            </label>
                          </div>
                        </li>
                      ))}
                    </div>
                  </ul>
                </div>

              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.duration?.message}</div>
              <Form.Field>

                <div className="relative w-full mb-3">
                  <input name="amount"
                    type="hidden" {...register('amount')}
                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Amount"
                  />
                </div>
              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.amount?.message}</div>
              <Form.Field>
                <div className="relative w-full mb-3">
                  <input name="purchased" value={plan_name}
                    type="hidden" {...register('purchased')}
                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Purchased"
                  />
                </div>

              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.purchased?.message}</div>
              <div className="text-left mb-6">
                <h6 className="text-blueGray-500 text-sm font-bold">
                  Payment Information
                </h6>
                CHOOSE YOUR PAYMENT METHOD
              </div>
              <Form.Field>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 pl-3">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-sm font-bold mb-2">
                        <input type="radio" value="credit" name="payment" {...register('payment')}
                          className={`form-control ${errors.payment ? 'is-invalid' : ''}`} />&nbsp;
                        Credit Card&nbsp;&nbsp;
                      </label>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 pl-3">
                    <div className="relative w-full mb-3">

                      <label
                        className="block uppercase text-blueGray-600 text-sm font-bold mb-2">
                        <input type="radio" value="bank" name="payment" {...register('payment')}
                          className={`form-control ${errors.payment ? 'is-invalid' : ''}`} />&nbsp;
                        Bank Payment&nbsp;&nbsp;
                      </label>
                    </div>
                  </div>
                </div>
              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.payment?.message}</div>
            </div>

            <div class="flex-1 w-32 px-8 lg:px-12 md:pt-12 sm:pb-40 bg-neutral-100 " id="right">
              <div className="text-left mt-3 mb-3">
                <h6 className="text-blueGray-500 text-sm font-bold">
                  Coupon Code
                </h6>
              </div>
              <Form.Field>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Coupon Code
                  </label>

                  <div className="text-center flex justify-between">
                    <input name="coupon"
                      type="text" {...register('coupon')}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Coupon Code"
                    />
                    <button
                      className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button" disabled={disable} onClick={discount}
                    >
                      Apply
                    </button>
                  </div>
                  <p className="font-bold text-green-600 text-center">{couponsuc}</p>
                  <p className="font-bold text-red-500 text-center">{couponerr}</p>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="relative w-full mb-3">
                  <input name="coupon_discount"
                    type="hidden" {...register('coupon_discount')}
                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Coupon Discount"
                  />
                </div>
              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.coupon_discount?.message}</div>


              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.coupon_name?.message}</div>
              <div className="text-left pt-4  mb-6">
                <h6 className="text-blueGray-500 text-sm font-bold">
                  Review Order Details
                </h6>
              </div>

              <div className="relative w-full border-2 mb-3">
                <table class="border-collapse w-full">
                  <tr>
                    <th className="text-left  py-2 px-6 border border-slate-400">Plan</th>
                    <td className=" border border-slate-400 text-right px-6">{plan_name}</td>
                  </tr>
                  <tr>
                    <th className="text-left py-2 px-6 border border-slate-400">Duration</th>
                    <td className="border border-slate-400 text-right px-6">{mon != 'No option selected' ? mon + " Days" : mon}</td>
                  </tr>
                  <tr>
                    <th className="text-left py-2 px-6 border border-slate-400">SUBTOTAL</th>
                    <td className="border border-slate-400 font-bold text-xl text-right px-6">
                      {discount_status ?
                        <>{"$ " + discount_value}&nbsp;
                          <span className='font-bold text-xl text-right pl-6 line-through'>{amounts != 'No option selected' ? "$ " + amounts : amounts}</span>
                        </>
                        :
                        <>
                          {amounts != 'No option selected' ? "$ " + amounts : amounts}
                        </>
                      }
                    </td>

                  </tr>
                  <tr>
                    <th className="text-left py-2 px-6 border border-slate-400">TOTAL DUE TODAY</th>
                    <td className="text-right border font-bold text-xl border-slate-400 px-6">
                      {discount_status ? "$ " + discount_value : (amounts != 'No option selected' ? "$ " + amounts : amounts)}
                    </td>
                  </tr>
                </table>
              </div>

              <Form.Field>
                <div>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      id="customCheckLogin" name="terms" {...register('terms')}
                      type="checkbox"
                      className="form-checkbox border-2 mt-4 shadow-lg rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                    />
                    <span className="ml-2 mt-4 text-sm font-semibold text-blueGray-600">
                      I agree with the{" "}
                      <a
                        href="#pablo"
                        className="text-lightBlue-500"
                        onClick={(e) => e.preventDefault()}
                      >
                        Privacy Policy
                      </a>
                    </span>
                  </label>
                </div>
              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.terms?.message}</div>
              <div className="text-center mt-6 px-12">
                <Button className="ui secondary button bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type='submit'>Submit</Button>
              </div>
            </div>
          </div>
        </Form>




        {/* Mobile View */}

        <Form onSubmit={handleSubmit(onSubmit)} className="md:hidden block">
          <div class="flex flex-wrap  sm:pt-52 pb-20 ">
            <div class="flex-col lg:flex-1 w-64 lg:pt-12 mb-12 pr-8 pl-4 lg:px-32">
              <h6 className="text-blueGray-500 block uppercase  font-sans text-center pb-4 text-xl font-bold">
                Sign up with credentials
              </h6>
              <h6 className="text-blueGray-500 text-center text-sm font-bold">
                Account information (fields marked with * are required)
              </h6>

              <Form.Field>
                <div className="relative w-full mb-3 pr-2">
                  <label
                    className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email*
                  </label>
                  <input name="email"
                    type="email" {...register("email")}
                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                  />
                </div>
              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.email?.message}</div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 pr-2">
                  <Form.Field>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Password*
                      </label>
                      <div className="absolute inset-y-px right-0 pr-4 pt-5 flex items-center">
                        <a class="btn" onClick={myFunction} role="button"><i className={isActive ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></a>
                      </div>
                      <input id="pass" name="password"
                        type="password" {...register('password')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Password"
                      />
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.password?.message}</div>
                </div>
                <div className="w-full lg:w-6/12 pr-2">
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Confirm Password*
                      </label>
                      <div className="absolute inset-y-px right-0 pr-4 pt-5 flex items-center">
                        <a class="btn" onClick={myFunctiontwo} role="button"><i className={isActivecp ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></a>
                      </div>
                      <input id="cpass" name="confpassword"
                        type="password" {...register('confpassword')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Confirm Password"
                      />
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.confpassword?.message}</div>
                </div>
              </div>
              <div className="text-left mt-3 mb-6">
                <h6 className="text-blueGray-500 text-sm font-bold">
                  Client Information (fields marked with * are required)
                </h6>

              </div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 pr-2 ">
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        First Name*
                      </label>
                      <input name="fname"
                        type="text" {...register('fname')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="First Name"
                      />
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.fname?.message}</div>
                </div>
                <div className="w-full lg:w-6/12 pr-2">
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Last Name*
                      </label>
                      <input name="lname"
                        type="text" {...register('lname')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Last Name"
                      />
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.lname?.message}</div>
                </div>
              </div>
              <div className="w-full pr-2">
                <Form.Field>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Street Address*
                    </label>
                    <input name="street"
                      type="text" {...register('street')}
                      className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Street Address"
                    />
                  </div>
                </Form.Field>
                <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.street?.message}</div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 pr-2">
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        City*
                      </label>
                      <input name="city"
                        type="text" {...register('city')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="City"
                      />
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.city?.message}</div>
                </div>
                <div className="w-full lg:w-6/12 pr-2">
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        ZIP Code*
                      </label>
                      <input name="zipcode"
                        type="text" {...register('zipcode')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="ZIP Code"
                      />
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.zipcode?.message}</div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 pr-2">
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Country*
                      </label>
                      <select name="country"
                        {...register('country')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Country"
                      >
                        <option value="" disabled selected hidden>Select country</option>
                        <option value="Afganistan">Afghanistan</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bonaire">Bonaire</option>
                        <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                        <option value="Brunei">Brunei</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Canary Islands">Canary Islands</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Channel Islands">Channel Islands</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos Island">Cocos Island</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote DIvoire">Cote DIvoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curaco">Curacao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="East Timor">East Timor</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands">Falkland Islands</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Ter">French Southern Ter</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Great Britain">Great Britain</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="India">India</option>
                        <option value="Iran">Iran</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea North">Korea North</option>
                        <option value="Korea Sout">Korea South</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Laos">Laos</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libya">Libya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macau">Macau</option>
                        <option value="Macedonia">Macedonia</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Midway Islands">Midway Islands</option>
                        <option value="Moldova">Moldova</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Nambia">Nambia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherland Antilles">Netherland Antilles</option>
                        <option value="Netherlands">Netherlands (Holland, Europe)</option>
                        <option value="Nevis">Nevis</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau Island">Palau Island</option>
                        <option value="Palestine">Palestine</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Phillipines">Philippines</option>
                        <option value="Pitcairn Island">Pitcairn Island</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Republic of Montenegro">Republic of Montenegro</option>
                        <option value="Republic of Serbia">Republic of Serbia</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russia">Russia</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="St Barthelemy">St Barthelemy</option>
                        <option value="St Eustatius">St Eustatius</option>
                        <option value="St Helena">St Helena</option>
                        <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                        <option value="St Lucia">St Lucia</option>
                        <option value="St Maarten">St Maarten</option>
                        <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                        <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                        <option value="Saipan">Saipan</option>
                        <option value="Samoa">Samoa</option>
                        <option value="Samoa American">Samoa American</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syria">Syria</option>
                        <option value="Tahiti">Tahiti</option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania">Tanzania</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Erimates">United Arab Emirates</option>
                        <option value="United States of America">United States of America</option>
                        <option value="Uraguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Vatican City State">Vatican City State</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                        <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                        <option value="Wake Island">Wake Island</option>
                        <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zaire">Zaire</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.country?.message}</div>
                </div>
                <div className="w-full lg:w-6/12 pr-2">
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        State*
                      </label>
                      <input name="state"
                        type="text" {...register('state')}
                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="State"
                      />
                    </div>
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.state?.message}</div>
                </div></div>
              <Form.Field>
                <div className="relative w-full mb-3 pr-2">
                  <label
                    className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Company
                  </label>
                  <input name="company"
                    type="text" {...register('company')}
                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Company"
                  />
                </div>
              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.company?.message}</div>

              <Form.Field>
                <div className="relative w-full mb-3 pr-2">
                  <label
                    className="block uppercase text-blueGray-600 text-lg font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Mobile number*
                  </label>
                  <input name="mobile"
                    type="text" {...register('mobile')}
                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Mobile number"
                  />
                </div>
              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.mobile?.message}</div>
            </div>

            <div class="flex-1 w-32 px-8 lg:px-12 pt-12 sm:pb-40 bg-neutral-100">

              <div className="text-left mb-3 pr-2">
                <h6 className="block uppercase text-blueGray-500 text-lg font-bold">
                  Purchase Information
                </h6>
              </div>
              <Form.Field>
                <div className="relative w-full mt-3  mb-6">
                  <ul>
                    <div className="  flex break-words transition ease-out duration-150" style={{
                      padding: "1%"
                    }}>
                      {items.map(item => (
                        <li key={item.id}>
                          <div className=" flex bg-blueGray-100 mr-2 border bg-white transition ease-out duration-150 hover:bg-lightBlue-200 rounded-lg">
                            <label>
                              <div className="block uppercase text-blueGray-600 text-sm font-bold mb-2 px-2 lg:px-4 py-6">
                                <input type="radio" value={item.month} name="duration"  {...register('duration')}
                                  className={`form-control ${errors.duration ? 'is-invalid' : ''}`}
                                  onClick={() => {
                                    setAmounts((Number(price) * Number(item.month)).toFixed(2));
                                    setMon(Number(item.month) * 30);
                                    setValue("amount", (Number(price) * Number(item.month)).toFixed(2));
                                    setValue("coupon_discount", Number(0));
                                    setDisable(false);
                                    setDiscount_status(false);
                                    setCouponerr("");
                                    setCouponsuc("");
                                  }} />&nbsp;
                                Choose {item.name} membership
                              </div>
                            </label>
                          </div>
                        </li>
                      ))}
                    </div>
                  </ul>
                </div>

              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.duration?.message}</div>
              <Form.Field>

                <div className="relative w-full mb-3">
                  <input name="amount"
                    type="hidden" {...register('amount')}
                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Amount"
                  />
                </div>
              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.amount?.message}</div>

              <Form.Field>
                <div className="relative w-full mb-3">
                  <input name="purchased" value={plan_name}
                    type="hidden" {...register('purchased')}
                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Purchased"
                  />
                </div>

              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.purchased?.message}</div>
              <div className="text-left mb-6">
                <h6 className="text-blueGray-500 text-sm font-bold">
                  Payment Information
                </h6>
                CHOOSE YOUR PAYMENT METHOD
              </div>

              <Form.Field>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 pl-3">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-sm font-bold mb-2">
                        <input type="radio" value="credit" name="payment" {...register('payment')}
                          className={`form-control ${errors.payment ? 'is-invalid' : ''}`} />&nbsp;
                        Credit Card&nbsp;&nbsp;
                      </label>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 pl-3">
                    <div className="relative w-full mb-3">

                      <label
                        className="block uppercase text-blueGray-600 text-sm font-bold mb-2">
                        <input type="radio" value="bank" name="payment" {...register('payment')}
                          className={`form-control ${errors.payment ? 'is-invalid' : ''}`} />&nbsp;
                        Bank Payment&nbsp;&nbsp;
                      </label>
                    </div>
                  </div>
                </div>
              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.payment?.message}</div>

              <div className="text-left mt-3 mb-3">
                <h6 className="text-blueGray-500 text-sm font-bold">
                  Coupon Code
                </h6>

              </div>

              <Form.Field>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Coupon Code
                  </label>

                  <div className="text-center flex justify-between">
                    <input name="coupon"
                      type="text" {...register('coupon')}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Coupon Code"
                    />
                    <button
                      className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button" disabled={disable} onClick={discount}
                    >
                      Apply
                    </button>
                  </div>
                  <p className="font-bold text-green-600 text-center">{couponsuc}</p>
                  <p className="font-bold text-red-500 text-center">{couponerr}</p>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="relative w-full mb-3">
                  <input name="coupon_discount"
                    type="hidden" {...register('coupon_discount')}
                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Coupon Discount"
                  />
                </div>
              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.coupon_discount?.message}</div>


              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.coupon_name?.message}</div>
              <div className="text-left pt-4  mb-6">
                <h6 className="text-blueGray-500 text-sm font-bold">
                  Review Order Details
                </h6>
              </div>

              <div className="relative w-full border-2 mb-3">
                <table class="border-collapse w-full">
                  <tr>
                    <th className="text-left  py-2 px-6 border border-slate-400">Plan</th>
                    <td className=" border border-slate-400 text-right px-6">{plan_name}</td>
                  </tr>
                  <tr>
                    <th className="text-left py-2 px-6 border border-slate-400">Duration</th>
                    <td className="border border-slate-400 text-right px-6">{mon != 'No option selected' ? mon + " Days" : mon}</td>
                  </tr>
                  <tr>
                    <th className="text-left py-2 px-6 border border-slate-400">SUBTOTAL</th>
                    <td className="border border-slate-400 font-bold text-xl text-right px-6">
                      {discount_status ?
                        <>{"$ " + discount_value}&nbsp;
                          <span className='font-bold text-xl text-right pl-6 line-through'>{amounts != 'No option selected' ? "$ " + amounts : amounts}</span>
                        </>
                        :
                        <>
                          {amounts != 'No option selected' ? "$ " + amounts : amounts}
                        </>
                      }
                    </td>

                  </tr>
                  <tr>
                    <th className="text-left py-2 px-6 border border-slate-400">TOTAL DUE TODAY</th>
                    <td className="text-right border font-bold text-xl border-slate-400 px-6">
                      {discount_status ? "$ " + discount_value : (amounts != 'No option selected' ? "$ " + amounts : amounts)}
                    </td>
                  </tr>
                </table>
              </div>

              <Form.Field>
                <div>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      id="customCheckLogin" name="terms" {...register('terms')}
                      type="checkbox"
                      className="form-checkbox border-2 mt-4 shadow-lg rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                    />
                    <span className="ml-2 mt-4 text-sm font-semibold text-blueGray-600">
                      I agree with the{" "}
                      <a
                        href="#pablo"
                        className="text-lightBlue-500"
                        onClick={(e) => e.preventDefault()}
                      >
                        Privacy Policy
                      </a>
                    </span>
                  </label>
                </div>
              </Form.Field>
              <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.terms?.message}</div>
              <div className="text-center mt-6 px-12">
                <Button className="ui secondary button bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type='submit'>Submit</Button>
              </div>
            </div>
          </div>
        </Form>
      </>
    );
  }
}