import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import Password from "views/auth/Password.js";
import Reset_password from "views/auth/Reset_password.js";
import Payment from "views/auth/Payment.js";

export default function Auth() {
  return (
    <>
      <main>
      <section className="relative w-full h-full lg:pt-99 sm:pt-52  min-h-screen">
          <div
            className="absolute top-0 w-full h-full  bg-no-repeat bg-full"
          ></div>
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Route path="/auth/password" exact component={Password} />
            <Route path="/auth/payment" exact component={Payment} />
            <Route path="/auth/reset_password/:param" exact component={Reset_password} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
        </section>
      </main>
    </>
  );
}
