import React from 'react';
import { createGlobalStyle } from "styled-components";
import { createPdfFromHtml } from "./logic";

const Global = createGlobalStyle`
  /* paper.css */
  https://github.com/cognitom/paper-css

  /* @page { margin: 0 } */
  #print {
    margin: 0;
    font-family: "IPAexGothic", sans-serif;
  }
  .sheet {
    margin: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    page-break-after: always;
  }
  
  
  /** Paper sizes **/
  #print.A3               .sheet { width: 297mm; height: 419mm }
  #print.A3.landscape     .sheet { width: 420mm; height: 296mm }
  #print.A4               .sheet { width: 210mm; height: 296mm }
  #print.A4.landscape     .sheet { width: 297mm; height: 209mm }
  #print.A5               .sheet { width: 148mm; height: 209mm }
  #print.A5.landscape     .sheet { width: 210mm; height: 147mm }
  #print.letter           .sheet { width: 216mm; height: 279mm }
  #print.letter.landscape .sheet { width: 280mm; height: 215mm }
  #print.legal            .sheet { width: 216mm; height: 356mm }
  #print.legal.landscape  .sheet { width: 357mm; height: 215mm }
  
  /** Padding area **/
  .sheet.padding-10mm { padding: 10mm }
  .sheet.padding-15mm { padding: 15mm }
  .sheet.padding-20mm { padding: 20mm }
  .sheet.padding-25mm { padding: 25mm }
  
  /** For screen preview **/
  @media screen {
    body {
      background: #E0E0E0;
      height: 100%;
    }
    .sheet {
      background: #FFFFFF;
      /* margin: 5mm auto; */
      padding: 5mm 0;
    }
  }
  
  /** Fix for Chrome issue #273306 **/
  @media print {
    #print.A3.landscape            { width: 420mm }
    #print.A3, #print.A4.landscape { width: 297mm }
    #print.A4, #print.A5.landscape { width: 210mm }
    #print.A5                      { width: 148mm }
    #print.letter, #print.legal    { width: 216mm }
    #print.letter.landscape        { width: 280mm }
    #print.legal.landscape         { width: 357mm }
  }
`;

export default class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      fname: '', lname: '', street: '', city: '', country: '', state: '', zipcode: '', mobile: '',
      purchased: props.location.state.purchased,
      duration: props.location.state.duration,
      amount: props.location.state.amount,
      discount: props.location.state.discount,
      amount_paid: props.location.state.amount_paid,
      expired: props.location.state.expired,
      starts: props.location.state.starts
    };
  }

  componentDidMount() {
    fetch(`https://ibusinessconsulting.ae/admin/customerreact/single/${JSON.parse(localStorage.getItem('user-info')).id}`)
      .then(response => {
        return response.json();
      }).then(result => {
        this.setState({
          fname: result.fname,
          lname: result.lname,
          street: result.street,
          city: result.city,
          country: result.country,
          state: result.state,
          zipcode: result.zipcode,
          mobile: result.mobile,
        });
      });
  }

  printContent;
  render() {
    return (
      <>

        <div className='top-0 lg:left-80 lg:right-80 fixed z-50 flex flex-wrap justify-center w-full lg:w-auto px-3 py-6   bg-lightBlue-500  '>
          <img
            alt="..."
            src={require("assets/img/ibusinesslogo.png").default}
            className="max-w-280-px flex justify-center"
          />
          <div className='relative flex flex-grow  justify-end'>
            <button className="bg-lightBlue-500 hover:bg-lightBlue-600 cursor-pointer text-2xl text-white leading-none px-4 py-4 rounded-full outline-none focus:outline-none"
              title='Back'
              onClick={this.props.history.goBack}>
              <i class="fa fa-arrow-left"></i>
            </button>
            <button className="bg-lightBlue-500 hover:bg-lightBlue-600 cursor-pointer text-2xl text-white leading-none px-4 py-4 rounded-full outline-none focus:outline-none"
              title='Download'
              onClick={this.handleClick}>
              <i class="fas fa-download"></i>
            </button>

          </div>
        </div>

        <Global />

        <div className="mt-20 flex flex-wrap justify-center">
          <div id="print" className="A4">
            {/* <Sheet /> */}
            <div className="sheet padding-10mm">
              <div className="flex flex-wrap justify-center">
                <div className="w-1/2 pl-52 lg:pl-0  lg:w-6/12 pr-2">
                  <div className='font-bold  text-3xl '>iBusiness Consulting</div>
                  <div className='py-4 text-lg font-semibold'>
                    <p>2180 boul. Marcel-Laurin Montréal</p>
                    <p>(Québec) H4R1J9 Canada</p>
                    <p>service@ibusinessconsulting.ca</p>
                    <p> 123645987</p>
                  </div>
                  <div className='font-bold text-lg text-lightBlue-500'>Billed To</div>
                  <div className='py-4 text-lg font-semibold'>
                    <p>{this.state.fname} {this.state.lname},</p>
                    <p>{this.state.street}, {this.state.city},</p>
                    <p> {this.state.state}, {this.state.country} - {this.state.zipcode}</p>
                    <p>Mobile: {this.state.mobile}</p>
                  </div>
                </div>

                <div className="w-1/2 pl-10 lg:w-6/12 flex justify-end pr-2">
                  <div className='relative flex-row'>
                    <h2 className='font-bold text-lg'>Invoice Total</h2>
                    <h2 className='font-extrabold text-xl text-lightBlue-500'>$ {Math.trunc((this.state.amount_paid - this.state.discount)*100)/100}</h2>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap pb-6">
                <div className='table pl-52 lg:pl-0 w-full '>
                  <tr>
                    <th className='text-left text-lightBlue-500'>Invoice Date </th>
                    <th className='text-middle text-lightBlue-500'>Terms</th>
                    <th className='text-right text-lightBlue-500'>Due Date</th>
                  </tr>
                  <tr>
                    <th className='text-left font-semibold'>{this.state.starts}</th>
                    <th className='text-middle font-semibold'>{this.state.duration * 30} Days</th>
                    <th className='text-right font-semibold'>{this.state.expired}</th>
                  </tr>
                </div>
              </div>

              <table className="table pl-52 lg:pl-0 w-full  border-dashed border-t-2 border-lightBlue-500">
                <tr className=" text-lightBlue-500 ">
                  <th className='pl-52 lg:pl-0 py-3 text-left' >Description</th>
                  <th className='lg:text-middle'>Unit Cost</th>
                  <th className='lg:text-middle'>Qty / Hr Rate</th>
                  <th className='text-right'>Amount</th>
                </tr>
                <tr className='border-solid border-2 border-r-0 border-l-0'>
                  <th className='pl-52 lg:pl-0 py-3 text-left' >{this.state.purchased}</th>
                  <th className='lg:text-middle' >$ {this.state.amount}</th>
                  <th className='lg:text-middle'>1</th>
                  <th className='text-right'>$ {this.state.amount}</th>
                </tr>
              </table>

              <div className='pt-12 w-full  flex justify-end'>
                <div className="w-24 flex justify-start text-lg font-semibold text-lightBlue-500">
                  <h2>Sub Total</h2>

                </div>
                <div className="w-1/4 flex justify-end">
                  <h2>$ {this.state.amount}</h2>
                </div>
              </div>

              <div className='pt-4 w-full  flex justify-end'>
                <div className="w-24 flex justify-start text-lg font-semibold text-lightBlue-500">
                  <h2>Coupon Discount</h2>
                </div>
                <div className="w-1/4 flex justify-end">
                  <h2>-$ {this.state.discount}</h2>
                </div>
              </div>

              <div className='pt-4 w-full  flex justify-end'>
                <div className="w-24 flex justify-start text-lg font-semibold text-lightBlue-500">
                  <h2>Previous Membership Amount Deduction </h2>
                </div>
                <div className="w-1/4 flex justify-end">
                  <h2>-$ {Math.trunc((this.state.amount - this.state.amount_paid)*100)/100}</h2>
                </div>
              </div>

              <div className='pt-4 w-full  flex justify-end'>
                <div className="w-24 flex justify-start text-lg font-semibold text-lightBlue-500">
                  <h2>Tax</h2>
                </div>
                <div className="w-1/4 flex justify-end">
                  <h2>+$ 0.00</h2>
                </div>
              </div>

              <div className='mt-2 py-5 w-full  text-white bg-lightBlue-500 flex justify-end'>
                <div className="w-24 text-lg font-bold flex justify-start">
                  <h2>Total</h2>
                </div>
                <div className="w-1/4 text-xl font-bold flex justify-end">
                  <h2>$ {Math.trunc((this.state.amount_paid - this.state.discount)*100)/100}</h2>
                </div>
              </div>

            </div>
            <div style={{ position: "fixed", top: "200vh" }}>
              <div
                ref={el => {
                  this.printContent = el;
                }}
              >
                <div className='top-0 left-0 flex flex-wrap justify-start w-full px-3 py-6   bg-lightBlue-500  '>
                  <img
                    alt="..."
                    src={require("assets/img/ibusinesslogo.png").default}
                    className="max-w-280-px flex justify-start"
                  />
                </div>

                {/*    <Sheet />  */}
                <div className="sheet padding-10mm">
                  <div className="mt-0 flex flex-wrap justify-center">
                    <div className="w-full lg:w-6/12 pr-2">

                      <div className='font-bold  text-3xl'>iBusiness Consulting</div>
                      <div className='py-4 text-lg font-semibold'>
                        <p>2180 boul. Marcel-Laurin Montréal</p>
                        <p>(Québec) H4R1J9 Canada</p>
                        <p>service@ibusinessconsulting.ca</p>
                        <p> 123645987</p>
                      </div>
                      <div className='font-bold text-lg text-lightBlue-500'>Billed To</div>
                      <div className='py-4 text-lg font-semibold'>
                        <p>{this.state.fname} {this.state.lname},</p>
                        <p>{this.state.street}, {this.state.city},</p>
                        <p> {this.state.state}, {this.state.country} - {this.state.zipcode}</p>
                        <p>Mobile: {this.state.mobile}</p>
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 flex justify-end pr-2">
                      <div className='relative flex-row'>
                        <h2 className='font-bold text-lg'>Invoice Total</h2>
                        <h2 className='font-extrabold text-xl text-lightBlue-500'>$ {Math.trunc((this.state.amount_paid - this.state.discount)*100)/100}</h2>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap pb-6">
                    <div className='table w-full '>
                      <tr>
                        <th className='text-left text-lightBlue-500'>Invoice Date </th>
                        <th className='text-middle text-lightBlue-500'>Terms</th>
                        <th className='text-right text-lightBlue-500'>Due Date</th>
                      </tr>
                      <tr>
                        <th className='text-left font-semibold'>{this.state.starts}</th>
                        <th className='text-middle font-semibold'>{this.state.duration * 30} Days</th>
                        <th className='text-right font-semibold'>{this.state.expired}</th>
                      </tr>
                    </div>
                  </div>

                  <table className="table w-full  border-dashed border-t-2 border-lightBlue-500">
                    <tr className=" text-lightBlue-500 ">
                      <th className='py-3 text-left' >Description</th>
                      <th className='text-middle'>Unit Cost</th>
                      <th className='text-middle'>Qty / Hr Rate</th>
                      <th className='text-right'>Amount</th>
                    </tr>
                    <tr className='border-solid border-2 border-r-0 border-l-0'>
                      <th className='py-3 text-left' >{this.state.purchased}</th>
                      <th className='text-middle' >$ {this.state.amount}</th>
                      <th className='text-middle'>1</th>
                      <th className='text-right'>$ {this.state.amount}</th>
                    </tr>
                  </table>

                  <div className='pt-12 w-full  flex justify-end'>
                    <div className="w-24 flex justify-start text-lg font-semibold text-lightBlue-500">
                      <h2>Sub Total</h2>

                    </div>
                    <div className="w-1/4 flex justify-end">
                      <h2>$ {this.state.amount}</h2>
                    </div>
                  </div>

                  <div className='pt-4 w-full  flex justify-end'>
                    <div className="w-24 flex justify-start text-lg font-semibold text-lightBlue-500">
                      <h2>Coupon Discount</h2>
                    </div>
                    <div className="w-1/4 flex justify-end">
                      <h2>-$ {this.state.discount}</h2>
                    </div>
                  </div>

                  <div className='pt-4 w-full  flex justify-end'>
                    <div className="w-24 flex justify-start text-lg font-semibold text-lightBlue-500">
                      <h2>Previous Membership Amount Deduction </h2>
                    </div>
                    <div className="w-1/4 flex justify-end">
                      <h2>-$ {Math.trunc((this.state.amount - this.state.amount_paid)*100)/100}</h2>
                    </div>
                  </div>

                  <div className='pt-4 w-full  flex justify-end'>
                    <div className="w-24 flex justify-start text-lg font-semibold text-lightBlue-500">
                      <h2>Tax</h2>
                    </div>
                    <div className="w-1/4 flex justify-end">
                      <h2>+$ 0.00</h2>
                    </div>
                  </div>

                  <div className='mt-2 py-5 w-full  text-white bg-lightBlue-500 flex justify-end'>
                    <div className="w-24 text-lg font-bold flex justify-start">
                      <h2>Total</h2>
                    </div>
                    <div className="w-1/4 text-xl font-bold flex justify-end">
                      <h2>$ {Math.trunc((this.state.amount_paid - this.state.discount)*100)/100}</h2>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

        </div>
      </>
    );
  }

  handleClick = () => {
    createPdfFromHtml(this.printContent);
  };
}