import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from 'semantic-ui-react';
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import axios from "axios";

export default function Itserv() {

    //const [stepzero, setStepzero] = useState(true);
    const [stepfir, setStepfir] = useState(true);
    const [stepsec, setStepsec] = useState(false);
    const [stepthi, setStepthi] = useState(false);
    const [stepfou, setStepfou] = useState(false);
    const [stepfiv, setStepfiv] = useState(false);
    const [stepsix, setStepsix] = useState(false);
    const [stepsev, setStepsev] = useState(false);
    const [isCoperation, setIsCoperation] = useState(false);
    const [isHR, setIsHR] = useState(false);
    const [isIT, setIsIT] = useState(false);
    const [isIPR, setIsIPR] = useState(false);
    const [consultingValue, setConsultingValue] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [suc, setSuc] = useState(false);
    const [err, setErr] = useState(false);
    const [isActive, setActive] = useState(false);
    const [isActivecp, setActivecp] = useState(false);
    const [serviceType, setServiceType] = useState('');
    const [haveWebsite, setHaveWebsite] = useState('');
    const [domainName, setDomainName] = useState('');
    const [city, setCity] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [existingUserError, setExistingUserError] = useState(false);
    const [passwordMismatch, setPasswordMismatch] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();
    var consultations = [];

    const onSubmitOne = async data => {
        //alert(JSON.stringify(data));
        if (serviceType.length == 0) {
            setErr(true)
            setSuc(false)
        }
        else {
            setStepfir(false);
            setStepsec(true);
            setStepthi(false);
            setStepfou(false);
            setStepfiv(false);
            setStepsix(false);
            setStepsev(false);
        }
    };
    function validateMedium() {
        var mediumRadio = document.getElementsByName("consulting");
        var okay = false;

        for (var i = 0, len = mediumRadio.length; i < len; i++) {
            if (mediumRadio[i].checked) {
                okay = true;
                break;
            }
        }

        if (okay) {
            setSuc(true);
            setErr(false);
            //setStepzero(false);
            //setStepzero(false);
            //setStepfir(false);
            //setStepsec(true);
            //setStepthi(false);
            //setStepfou(false);
            //setStepfiv(false);
            //setStepsix(false);
            //setStepsev(false);
        } else {
            setSuc(false);
            setErr(true);
        }
    }

    // function validateSub() {
    //   var mediumCheckboxes = document.getElementsByName("sub_consulting");
    //   var okay = false;

    //   for (var i = 0, len = mediumCheckboxes.length; i < len; i++) {
    //     if (mediumCheckboxes[i].checked) {
    //       okay = true;
    //       break;
    //     }
    //   }

    //   if (okay) {
    //     setSuc(true);
    //     setErr(false);
    //     //setStepzero(false);
    //     //setStepzero(false);
    //     //setStepfir(false);
    //     //setStepsec(true);
    //     //setStepthi(false);
    //     //setStepfou(false);
    //     //setStepfiv(false);
    //     //setStepsix(false);
    //     //setStepsev(false);
    //   } else {
    //     setSuc(false);
    //     setErr(true);
    //   }
    // }


    const onSubmitTwo = async data => {
        //alert(JSON.stringify(data));
        //setStepzero(false);
        setStepfir(false);
        setStepsec(false);
        setStepthi(true);
        setStepfou(false);
        setStepfiv(false);
        setStepsix(false);
        setStepsev(false);
    };

    const onSubmitThree = async data => {
        //alert(JSON.stringify(data));
        //setStepzero(false);
        setStepfir(false);
        setStepsec(false);
        setStepthi(false);
        setStepfou(true);
        setStepfiv(false);
        setStepsix(false);
        setStepsev(false);
    };

    const onSubmitFour = async data => {
        //alert(JSON.stringify(data));
        //setStepzero(false);
        if(password == confirmPassword)
        {
            const formData = { first_name: firstName, last_name: lastName, email: emailAddress, password: password, service_type: serviceType, city: city, website_option: haveWebsite, domain_name: domainName };
            axios.post("https://customer.ibusinessconsulting.ae/admin/public/index.php/api/addItServices", formData)
            .then((response) => {
                if (response.data.status) {
                        setStepfir(false);
                        setStepsec(false);
                        setStepthi(false);
                        setStepfou(false);
                        setStepfiv(true);
                        setStepsix(false);
                        setStepsev(false);
                        setSuccess(true);
                        setError(false);
                        setPasswordMismatch(false);
                        setTimeout(() => {
                            window.location = "https://customer.ibusinessconsulting.ae/";
                        },5000);
                    }
                    else {
                        setSuccess(false);
                        setError(false);
                        setExistingUserError(true);
                    }
                    console.log(response.data.message);
                });
        }
        else
        {
            setPasswordMismatch(true);

        }
    };

    const onSubmitFive = async data => {
        //alert(JSON.stringify(data));
        //setStepzero(false);
        setStepfir(false);
        setStepsec(false);
        setStepthi(false);
        setStepfou(false);
        setStepfiv(false);
        setStepsix(true);
        setStepsev(false);
    };

    const onSubmitSix = async data => {
        //alert(JSON.stringify(data));
        //setStepzero(false);
        setStepfir(false);
        setStepsec(false);
        setStepthi(false);
        setStepfou(false);
        setStepfiv(false);
        setStepsix(false);
        setStepsev(true);
    };

    const onSubmitSeven = async data => {
        //  console.log(JSON.stringify(data));
        //setStepzero(false);
        setStepfir(false);
        setStepsec(false);
        setStepthi(false);
        setStepfou(false);
        setStepfiv(false);
        setStepsix(false);
        setStepsev(false);
        let result = await fetch("http://localhost:8000/admin/public/index.php/admin/Questionreact", {
            method: 'POST',
            body: JSON.stringify(
                {
                    consulting: JSON.stringify(data.consulting),
                    business: data.business,
                    running: data.running,
                    revenue: data.revenue,
                    industry: data.industry,
                    start: data.start,
                    email: data.email
                }
            ),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json()
        //  console.warn("Result", result)
        if (result.status === 201) {
            setSuccess(true);
            setError(false);
        }
        else {
            setSuccess(false);
            setError(true);
        }
    };
    function myFunction() {
        var x = document.getElementById("pass");
        if (isActive) {
            setActive(false);
            x.type = "password";
        }
        else {
            setActive(true);
            x.type = "text";
        }
    }

    function myFunctiontwo() {
        var x = document.getElementById("cPass");
        if (isActivecp) {
            setActivecp(false);
            x.type = "password";
        }
        else {
            setActivecp(true);
            x.type = "text";
        }
    }

    function handleServiceType(event) {
        setServiceType(event.target.value);
    }

    function handleWebsiteChange(event) {
        setHaveWebsite(event.target.value);
    }

    function handleCityChange(event) {
        setCity(event.target.value);
    }

    return (
        <>

            <IndexNavbar />
            <section className="lg:h-800-px">
                <div className="container mx-auto lg:pt-2 md:pt-32 pt-24">
                    <div className="w-full lg:w-12/12 md:w-12/12 lg:pt-16 pt-12 lg:px-12">
                        <div className="lg:flex flex-wrap lg:px-12 ">
                            <div className="lg:w-2/12">
                            </div>
                            <div className="lg:w-8/12 items-center lg:pt-6">
                                <div className="">
                                    {" "}
                                    {stepfir ?
                                        <>
                                            <h1 className="ttt text-4xl text-black text-center lg:pt-32 lg:px-0 px-4 pb-12 pt-12">
                                                What type of service do you required?
                                            </h1>
                                            {err ? (
                                                        <span
                                                            role="alert"
                                                            className="text-red-500 pt-4"
                                                        >
                                                            Please select your service
                                                        </span>
                                                    ) : null}
                                            <form onSubmit={handleSubmit(onSubmitOne)}>
                                                <div className="aligncenters w-full">
                                                    <div className="flex flex-wrap">
                                                        <div className="lg:w-3/12 md:w-6/12 w-12/12 lg:px-2 px-6">
                                                            <label className="card">
                                                                <div className="hover:shadow-lg shadow-sm bg-lightred border-lightred border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4  ">
                                                                    <img
                                                                        className=" w-18 pb-2"
                                                                        src={require("assets/img/SERVICE/websitedesign.png").default}
                                                                        alt="..."
                                                                    />
                                                                    <label className="ttt text-black lg:text-lg text-2xl pt-4 md:text-xl">
                                                                        Website<br></br> Design
                                                                    </label>
                                                                    <br />
                                                                    <input
                                                                        {...register("service_type", {
                                                                            required: false,
                                                                        })}
                                                                        type="radio"
                                                                        value="Website Design"
                                                                        className="mt-4"
                                                                        checked={serviceType == "Website Design"}
                                                                        onChange={handleServiceType}
                                                                    />
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className="lg:w-3/12 md:w-6/12 w-12/12 lg:pt-0 pt-4 lg:px-2 px-6">
                                                            <label className="card">
                                                                <div className="hover:shadow-lg shadow-sm bg-lightblue border-lightblue border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4  ">
                                                                    <img
                                                                        className=" w-18 pb-2"
                                                                        src={require("assets/img/SERVICE/webapp.png").default}
                                                                        alt="..."
                                                                    />
                                                                    <label className=" ttt text-black  lg:text-lg  text-2xl pt-4 md:text-xl">
                                                                        Web App<br></br> Development
                                                                    </label>
                                                                    <br />
                                                                    <input
                                                                        {...register("service_type", {
                                                                            required: false,
                                                                        })}
                                                                        type="radio"
                                                                        value="Web App Development"
                                                                        className="mt-4"
                                                                        checked={serviceType == "Web App Development"}
                                                                        onChange={handleServiceType}
                                                                    />
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className="lg:w-3/12 md:w-6/12 w-12/12 lg:pt-0 pt-4 lg:px-2 px-6">
                                                            <label className="card">
                                                                <div className="hover:shadow-lg shadow-sm bg-lightyellow border-lightyellow border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4  ">
                                                                    <img
                                                                        className=" w-18 pb-2"
                                                                        src={require("assets/img/SERVICE/mobileapp.png").default}
                                                                        alt="..."
                                                                    />
                                                                    <label className=" ttt text-black  lg:text-lg  text-2xl pt-4 md:text-xl">
                                                                        Mobile App<br></br> Development
                                                                    </label>
                                                                    <br />
                                                                    <input
                                                                        {...register("service_type", {
                                                                            required: false,
                                                                        })}
                                                                        type="radio"
                                                                        value="Mobile App Development"
                                                                        className="mt-4"
                                                                        checked={serviceType == "Mobile App Development"}
                                                                        onChange={handleServiceType}
                                                                    />
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className="lg:w-3/12 md:w-6/12 w-12/12 lg:pt-0 pt-4 lg:px-2 px-6">
                                                            <label className="card">
                                                                <div className="hover:shadow-lg shadow-sm bg-lightgreen border-lightgreen border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4  ">
                                                                    <img
                                                                        className=" w-18 pb-2"
                                                                        src={require("assets/img/SERVICE/marketingg.png").default}
                                                                        alt="..."
                                                                    />
                                                                    <label className="ttt text-black lg:text-lg text-2xl pt-4 md:text-xl">
                                                                        Digital <br></br>Marketing
                                                                    </label>
                                                                    <br />
                                                                    <input
                                                                        {...register("service_type", {
                                                                            required: false,
                                                                        })}
                                                                        type="radio"
                                                                        value="Digital Marketing"
                                                                        className="mt-4"
                                                                        checked={serviceType == "Digital Marketing"}
                                                                        onChange={handleServiceType}
                                                                    />
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="w-full lg:flex flex lg:pt-12 pt-4 lg:pb-0 pb-12 md:pt-3">
                                                    <div className="pb-2 mt-2 textalign lg:w-6/12 w-6/12">
                                                        <a
                                                            className="ttt mt-4 bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-sm font-bold uppercase px-10 py-3 rounded-full justify-center"
                                                            style={{ padding: "2" }} href="/wizard"
                                                        >
                                                            Back
                                                        </a>
                                                    </div>
                                                    <div className="lg:px-2 px-2 pb-2 lg:w-4/12 w-4/12">
                                                        <button
                                                            type="submit"
                                                            className="ttt bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-sm font-bold uppercase px-8 py-3 rounded-full justify-center"
                                                            style={{ padding: "2" }}
                                                        >
                                                            Continue
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                        : null}



                                    {stepsec ?
                                        <>
                                            <h1 className="ttt text-4xl text-black text-center lg:pt-32 pt-12 pb-12">
                                                Do you have a website?
                                            </h1>
                                            {errors.website?.type === "required" && (
                                                <div className="w-full text-center pt-4 pb-6">
                                                    <span
                                                        role="alert"
                                                        className="text-red-500 pt-4 text-xl"
                                                    >
                                                        Please select your option
                                                    </span>
                                                </div>
                                            )}
                                            <form onSubmit={handleSubmit(onSubmitTwo)}>
                                                <div className="flex flex-wrap aligncenters lg:px-0 px-6">
                                                    <div className="lg:w-2/12 md:w-12/12">
                                                    </div>
                                                    <div className="lg:w-7/12 md:w-12/12 w-12/12 lg:pl-12 lg:px-0 md:px-12">
                                                        <label className="card">
                                                            <div className=" items-center justify-between hover:shadow-lg shadow-sm bg-white border pt-4 pb-4 rounded-lg hover:border-lightBlue-600 px-4 ">
                                                                <div className="flex">
                                                                    <img
                                                                        className=" w-12 pb-2 text-right"
                                                                        src={require("assets/img/SERVICE/yes.png").default}
                                                                        alt="..."
                                                                    />
                                                                    <div className="w-11/12 text-left pt-2 pl-3 group-hover:text-white ttt text-black lg:text-xl text-2xl md:text-xl ">
                                                                        Yes, have a website
                                                                    </div>
                                                                    <br />
                                                                    <input
                                                                        {...register("website", {
                                                                            required: true,
                                                                        })}
                                                                        type="radio"
                                                                        value="Yes"
                                                                        className=" radio text-right mb-2"
                                                                        checked={haveWebsite == "Yes"}
                                                                        onChange={handleWebsiteChange}
                                                                    />
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className=" border px-3 py-3 mb-4 mt-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                                    placeholder="domain name"
                                                                    value={domainName}
                                                                    onChange={(e) => setDomainName(e.target.value)}
                                                                />
                                                            </div>
                                                        </label>

                                                        <label className="card mt-4">
                                                            <div className="mt-4 flex items-center justify-between hover:shadow-lg shadow-sm bg-white border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4 ">
                                                                <img
                                                                    className=" w-12 pb-2 text-right"
                                                                    src={require("assets/img/SERVICE/no.png").default}
                                                                    alt="..."
                                                                />
                                                                <div className="w-11/12 text-left pl-3 pb-2 group-hover:text-white ttt text-black lg:text-xl text-2xl md:text-xl ">
                                                                    No, I don't have a website
                                                                </div>
                                                                <br />
                                                                <input
                                                                    {...register("website", {
                                                                        required: true,
                                                                    })}
                                                                    type="radio"
                                                                    value="No"
                                                                    className=" radio text-right mb-2"
                                                                    checked={haveWebsite == "No"}
                                                                    onChange={handleWebsiteChange}
                                                                />
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>

                                                <br />
                                                <div className="w-full lg:flex flex lg:pt-6 lg:pb-0 pb-12 md:pt-3">
                                                    <div className="pb-2 textalign lg:w-6/12 w-6/12">
                                                        <button
                                                            className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-sm ttt font-bold uppercase px-10 py-3 rounded-full justify-center"
                                                            style={{ padding: "2" }}
                                                            onClick={() => {
                                                                setStepfir(true);
                                                                setStepsec(false);
                                                            }}
                                                        >
                                                            Back
                                                        </button>
                                                    </div>
                                                    <div className="lg:px-2 px-2 pb-2 lg:w-4/12 w-4/12">
                                                        <button
                                                            type="submit"
                                                            className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-sm ttt font-bold uppercase px-8 py-3 rounded-full justify-center"
                                                            style={{ padding: "2" }}
                                                        >
                                                            Continue
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                        : null}

                                    {stepthi ?
                                        <>
                                            <h1 className="ttt text-4xl text-black text-center lg:pt-32 pb-12">
                                                Where is Your Company Located?
                                            </h1>
                                            {errors.city?.type === "required" && (
                                                    <div className="text-center w-full pt-6">
                                                        <span
                                                            role="alert"
                                                            className="text-red-500 pt-4 text-xl"
                                                        >
                                                            Please select your preferred city
                                                        </span>
                                                    </div>
                                                )}
                                            <form onSubmit={handleSubmit(onSubmitThree)}>
                                                <div className="flex flex-wrap aligncenters ">
                                                    <div className="lg:w-3/12 md:w-6/12 w-12/12 lg:px-2 px-6">
                                                        <label className="card">
                                                            <div className="hover:shadow-lg shadow-sm bg-lightred border-lightred border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4  ">
                                                                <img
                                                                    className=" w-18 pb-2"
                                                                    src={require("assets/img/SERVICE/DUBA.png").default}
                                                                    alt="..."
                                                                />
                                                                <label className="ttt text-black  lg:text-lg text-2xl pt-4 md:text-xl">
                                                                    Dubai
                                                                </label>
                                                                <br />
                                                                <input
                                                                    {...register("city", {
                                                                        required: true,
                                                                    })}
                                                                    type="radio"
                                                                    value="Dubai"
                                                                    className="mt-4"
                                                                    checked={city == "Dubai"}
                                                                    onChange={handleCityChange}
                                                                />
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="lg:w-3/12 md:w-6/12 w-12/12 lg:px-2 px-6 lg:pt-0 md:pt-0 pt-4">
                                                        <label className="card">
                                                            <div className="hover:shadow-lg shadow-sm bg-lightgreen border-lightgreen border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4  ">
                                                                <img
                                                                    className=" w-18 pb-2"
                                                                    src={require("assets/img/SERVICE/ABUD.png").default}
                                                                    alt="..."
                                                                />
                                                                <label className="ttt text-black lg:text-lg text-2xl pt-4 md:text-xl">
                                                                    AbuDhabi
                                                                </label>
                                                                <br />
                                                                <input
                                                                    {...register("city", {
                                                                        required: true,
                                                                    })}
                                                                    type="radio"
                                                                    value="AbuDhabi"
                                                                    className="mt-4"
                                                                    checked={city == "AbuDhabi"}
                                                                    onChange={handleCityChange}
                                                                />
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="lg:w-3/12 md:w-6/12 w-12/12 lg:px-2 px-6 lg:pt-0 pt-4">
                                                        <label className="card">
                                                            <div className="hover:shadow-lg shadow-sm bg-lightblue border-lightblue border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4  ">
                                                                <img
                                                                    className=" w-18 pb-2"
                                                                    src={require("assets/img/SERVICE/SHA.png").default}
                                                                    alt="..."
                                                                />
                                                                <label className="ttt text-black lg:text-lg text-2xl pt-4 md:text-xl">
                                                                    Sharjah
                                                                </label>
                                                                <br />
                                                                <input
                                                                    {...register("city", {
                                                                        required: true,
                                                                    })}
                                                                    type="radio"
                                                                    value="Sharjah"
                                                                    className="mt-4"
                                                                    checked={city == "Sharjah"}
                                                                    onChange={handleCityChange}
                                                                />
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="lg:w-3/12 md:w-6/12 w-12/12 lg:px-2 px-6 lg:pt-0 pt-4">
                                                        <label className="card">
                                                            <div className="hover:shadow-lg shadow-sm bg-lightorange border-lightorange border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4  ">
                                                                <img
                                                                    className=" w-18 pb-2"
                                                                    src={require("assets/img/SERVICE/RAS.png").default}
                                                                    alt="..."
                                                                />
                                                                <label className="ttt text-black lg:text-lg text-2xl pt-4 md:text-xl">
                                                                    Ras Al Khaimah
                                                                </label>
                                                                <br />
                                                                <input
                                                                    {...register("city", {
                                                                        required: true,
                                                                    })}
                                                                    type="radio"
                                                                    value="Ras Al Khaimah"
                                                                    className="mt-4"
                                                                    checked={city == "Ras Al Khaimah"}
                                                                    onChange={handleCityChange}
                                                                />
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap aligncenters lg:pt-4 md:pt-0 pt-4 pb-12">
                                                    <div className="lg:w-3/12 md:w-6/12 w-12/12 lg:px-2 md:px-6 px-5 lg:pt-0 md:pt-4">
                                                        <label className="card">
                                                            <div className="hover:shadow-lg shadow-sm bg-lightyellow border-lightyellow  border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4 ">
                                                                <img
                                                                    className=" w-18 pb-2"
                                                                    src={require("assets/img/SERVICE/FUJJ.png").default}
                                                                    alt="..."
                                                                />
                                                                <label className="ttt text-black  lg:text-lg text-2xl pt-4 md:text-xl">
                                                                    Fujairah
                                                                </label>
                                                                <br />
                                                                <input
                                                                    {...register("city", {
                                                                        required: true,
                                                                    })}
                                                                    type="radio"
                                                                    value="Fujairah"
                                                                    className="mt-4"
                                                                    checked={city == "Fujairah"}
                                                                    onChange={handleCityChange}
                                                                />
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="lg:w-3/12 md:w-6/12 w-12/12 lg:px-2 px-6 lg:pt-0 pt-4">
                                                        <label className="card">
                                                            <div className="hover:shadow-lg shadow-sm bg-lightpurple border-lightpurple border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4  ">
                                                                <img
                                                                    className=" w-18 pb-2"
                                                                    src={require("assets/img/SERVICE/AJMA.png").default}
                                                                    alt="..."
                                                                />
                                                                <label className="ttt text-black lg:text-lg text-2xl pt-4 md:text-xl">
                                                                    Ajman
                                                                </label>
                                                                <br />
                                                                <input
                                                                    {...register("city", {
                                                                        required: true,
                                                                    })}
                                                                    type="radio"
                                                                    value="Ajman"
                                                                    className="mt-4"
                                                                    checked={city == "Ajman"}
                                                                    onChange={handleCityChange}
                                                                />
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="lg:w-3/12 md:w-6/12 w-12/12 lg:px-2 px-6 lg:pt-0 pt-4">
                                                        <label className="card">
                                                            <div className="hover:shadow-lg shadow-sm bg-white border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4  ">
                                                                <img
                                                                    className=" w-18 pb-2"
                                                                    src={require("assets/img/SERVICE/UMMM.png").default}
                                                                    alt="..."
                                                                />
                                                                <label className=" ttt text-black lg:text-lg text-2xl pt-4 md:text-xl">
                                                                    Umm Al Qumain
                                                                </label>
                                                                <br />
                                                                <input
                                                                    {...register("city", {
                                                                        required: true,
                                                                    })}
                                                                    type="radio"
                                                                    value="Umm Al Qumain"
                                                                    className="mt-4"
                                                                    checked={city == "Umm Al Qumain"}
                                                                    onChange={handleCityChange}
                                                                />
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="lg:w-3/12 px-2 group ">

                                                    </div>
                                                </div>
                                                <br />
                                                <div className="w-full flex lg:pt-0 lg:pb-0 pb-12 md:pt-3">
                                                    <div className="pb-2 textalign lg:w-6/12 w-6/12">
                                                        <button
                                                            className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-sm ttt font-bold uppercase px-10 py-3 rounded-full justify-center"
                                                            style={{ padding: "2" }}
                                                            onClick={() => {
                                                                setStepsec(true);
                                                                setStepthi(false);
                                                            }}
                                                        >
                                                            Back
                                                        </button>
                                                    </div>
                                                    <div className="lg:px-2 px-2 pb-2 lg:w-4/12 w-4/12">
                                                        <button
                                                            type="submit"
                                                            className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-sm ttt font-bold uppercase px-8 py-3 rounded-full justify-center"
                                                            style={{ padding: "2" }}
                                                        >
                                                            Continue
                                                        </button>
                                                    </div>
                                                </div>

                                            </form>
                                        </>
                                        : null}


                                    {stepfou ?
                                        <>
                                            <div className="flex flex-wrap ">
                                                <div className="lg:w-2/12"></div>
                                                <div className="lg:w-8/12 w-12/12 ">
                                                    <form onSubmit={handleSubmit(onSubmitFour)}>
                                                        <div className="lg:w-12/12 w-12/12 px-2 pb-6 pt-12">
                                                            <div className=" bg-white rounded-lg lg:px-16 md:px-24 pt-12 pb-12">
                                                                <h1 className="ttt text-2xl font-semibold text-black text-center pb-12 ">
                                                                    Create your account
                                                                </h1>
                                                                <div className="flex flex-wrap">
                                                                    <div className="lg:w-6/12 w-12/12 lg:px-0 px-4 lg:pr-4">
                                                                        <Form.Field>
                                                                            <div className="relative w-full mb-3">
                                                                                <label
                                                                                    className="text-left block  text-black ttt text-sm font-bold mb-2"
                                                                                    htmlFor="grid-password"
                                                                                >
                                                                                    First Name*
                                                                                </label>
                                                                                <input name="fname" style={{ fontSize: '18px' }}
                                                                                    type="text" {...register('fname',{
                                                                                        required: {
                                                                                            value: "required",
                                                                                            message: "Please enter your first name",
                                                                                        },
                                                                                    })}
                                                                                    className="border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                                                    placeholder=""
                                                                                    value={firstName}
                                                                                    onChange={(e) => setFirstName(e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </Form.Field>
                                                                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.fname?.message}</div>
                                                                    </div>

                                                                    <div className="lg:w-6/12 w-12/12 lg:px-2 px-4">
                                                                        <Form.Field>
                                                                            <div className="relative w-full mb-3">
                                                                                <label
                                                                                    className="text-left block text-black ttt text-sm font-bold mb-2"
                                                                                    htmlFor="grid-password"
                                                                                >
                                                                                    Last Name*
                                                                                </label>
                                                                                <input name="lname" style={{ fontSize: '18px' }}
                                                                                    type="text" {...register('lname',{
                                                                                        required: {
                                                                                            value: "required",
                                                                                            message: "Please enter your last name",
                                                                                        },
                                                                                    })}
                                                                                    className="border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                                                    placeholder=""
                                                                                    value={lastName}
                                                                                    onChange={(e) => setLastName(e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </Form.Field>
                                                                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.lname?.message}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-wrap">
                                                                    <div className="w-full w-12/12 lg:px-0 px-4 pt-4 lg:pr-4">
                                                                        <Form.Field>
                                                                            <div className="relative w-full ">
                                                                                <label
                                                                                    className="text-left block text-black ttt text-sm font-bold mb-2"
                                                                                    htmlFor="grid-password"
                                                                                >
                                                                                    Email*
                                                                                </label>
                                                                                <input
                                                                                    type="text" style={{ fontSize: '18px' }}
                                                                                    {...register("start", {
                                                                                        required: {
                                                                                            value: "required",
                                                                                            message: "Please enter your email id",
                                                                                        },
                                                                                        pattern: {
                                                                                            value:
                                                                                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                                            message:
                                                                                                "Please enter valid email id",
                                                                                        },
                                                                                    })}
                                                                                    className="border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                                                    placeholder="Email"
                                                                                    value={emailAddress}
                                                                                    onChange={(e) => setEmailAddress(e.target.value)}
                                                                                />

                                                                                {errors.start && (
                                                                                    <span
                                                                                        role="alert"
                                                                                        className="invalid-feedback font-bold text-red-500 mb-3"
                                                                                    >
                                                                                        {errors.start.message}
                                                                                    </span>
                                                                                )}
                                                                                <br />
                                                                            </div>
                                                                        </Form.Field>
                                                                    </div>


                                                                    <div className="lg:w-6/12 w-12/12 lg:px-0 px-4 lg:pr-4 lg:pt-4 pt-6">
                                                                        <Form.Field>
                                                                            <div className="relative w-full mb-3">
                                                                                <label
                                                                                    className="text-left block  text-black ttt text-sm font-bold lg:pt-4 mb-2"
                                                                                    htmlFor="grid-password"
                                                                                >
                                                                                    Password*
                                                                                </label>
                                                                                <div className="absolute inset-y-px right-0 pr-4 pt-10 flex items-center">
                                                                                    <a class="btn" onClick={myFunction} role="button"><i className={isActive ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></a>
                                                                                </div>
                                                                                <input id="pass" name="password" style={{ fontSize: '18px' }}
                                                                                    type="password" {...register('password',{
                                                                                        required: {
                                                                                            value: "required",
                                                                                            message: "Please enter your password",
                                                                                        },
                                                                                        pattern: {
                                                                                            value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
                                                                                            message: "Password must be at least 8 characters long and include one special character and one number",
                                                                                        },
                                                                                    })}
                                                                                    className="border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                                                    placeholder=""
                                                                                    value={password}
                                                                                    onChange={(e) => setPassword(e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </Form.Field>
                                                                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.password?.message}</div>
                                                                    </div>
                                                                    <div className="lg:w-6/12 w-12/12 lg:px-2 px-4 pt-4">
                                                                        <Form.Field>
                                                                            <div className="relative w-full mb-3">
                                                                                <label
                                                                                    className="text-left block  text-black ttt text-sm font-bold mb-2 lg:pt-4"
                                                                                    htmlFor="grid-password"
                                                                                >
                                                                                    Confirm Password*
                                                                                </label>
                                                                                <div className="absolute inset-y-px right-0 pr-4 pt-10 flex items-center">
                                                                                    <a class="btn" onClick={myFunctiontwo} role="button"><i className={isActivecp ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></a>
                                                                                </div>
                                                                                <input id="cPass" name="cPassword" style={{ fontSize: '18px' }}
                                                                                    type="password" {...register('cPassword',{
                                                                                        required: {
                                                                                            value: "required",
                                                                                            message: "Please confirm your password",
                                                                                        },
                                                                                    })}
                                                                                    className="border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                                                    placeholder=""
                                                                                    value={confirmPassword}
                                                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </Form.Field>
                                                                        <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.cPassword?.message}</div>
                                                                        {passwordMismatch && (<div className="invalid-feedback font-bold text-red-500 mb-3">Passwords mismatch</div>)}
                                                                    </div>
                                                                </div>
                                                                {existingUserError && (
                                                                    <p className="font-bold text-red-500 mb-3">User Already registered or something went wrong</p>
                                                                )}
                                                                <div className="w-full lg:flex flex lg:pt-12  pt-12 md:pt-3">
                                                                    <div className="pb-2 textalign lg:w-6/12 w-6/12">
                                                                        <button
                                                                            className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-sm ttt font-bold uppercase px-20 py-3 rounded-full justify-center"
                                                                            style={{ padding: "2" }}
                                                                            onClick={() => {
                                                                                setStepthi(true);
                                                                                setStepfou(false);
                                                                            }}
                                                                        >
                                                                            Back
                                                                        </button>
                                                                    </div>
                                                                    <div className="lg:px-2 px-2 pb-2 lg:w-6/12 w-6/12">
                                                                        <button
                                                                            type="submit"
                                                                            className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-sm ttt font-bold uppercase px-16 py-3 rounded-full justify-center"
                                                                            style={{ padding: "2" }}
                                                                        >
                                                                            SignUp
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="w-full w-12/12 lg:pt-6 pt-6">
                                                                    <div className="lg:flex lg:px-0 px-6">
                                                                        <div className="lg:w-6/12 w-12/12 px-4 items-center flex shadow-md rounded-lg ">
                                                                            <img
                                                                                className=" w-12 pt-2 text-right"
                                                                                src={require("assets/img/SERVICE/google.svg").default}
                                                                                alt="..."
                                                                            /> <div className="w-full text-black text-base ttt font-semibold">
                                                                                Sign Up with Google
                                                                            </div>
                                                                        </div>
                                                                        <div className="lg:mx-2 lg:w-6/12 w-12/12 items-center flex lg:mt-0 mt-6 px-4 shadow-md rounded-lg ">
                                                                            <img
                                                                                className=" w-12 pt-2 text-right"
                                                                                src={require("assets/img/SERVICE/linkedin.svg").default}
                                                                                alt="..."
                                                                            /> <div className="w-full text-black text-base ttt font-semibold">
                                                                                Sign Up with LinkedIn
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div> */}

                                                                <h1 className="text-center lg:text-lg lg:pt-6 pt-12">
                                                                    Already have an account? <span className="text-lightBlue-600 lg:text-xl"><a href="https://customer.ibusinessconsulting.ae">Sign In</a></span>
                                                                </h1>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </>
                                        : null}

                                    {
                                      success ? (
                                        <div className="a-container">
                                          <p className="text-center text-green-600 px-4 md:px-16 lg:pt-12" style={{fontSize:"20px",marginTop:15}}>
                                            <p className="lg:text-4xl text-base">
                                              <i class="fas fa-check-circle text-4xl"></i>
                                            </p>
                                            We appreciate you contacting iBusiness Consulting. One of our colleagues will get back in touch with you soon!<br />
                                            Have a great day!
                                          </p>
                                        </div>
                                      ) : null
                                    }
                                    {
                                        error ?
                                            <p className="text-center text-red-500 px-20 shadow-md bg-white rounded-lg border py-12 mb-32">
                                                <p className="text-4xl "><i class="fa fa-times-circle" aria-hidden="true"></i></p>
                                                Oops! Something went wrong. Please try again.
                                            </p>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />


        </>


    );
}


