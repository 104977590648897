import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form } from 'semantic-ui-react';
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Password() {

  const history = useHistory();
  const [err, setErr] = useState("");
  const [suc, setSuc] = useState("");
  let user = JSON.parse(localStorage.getItem('user-info'));

  const { register, resetField, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    // console.log(data);
     fetch(`https://ibusinessconsulting.ae/admin/customerreact/forgot_password`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    }).then((resp)=>{
    resp.json().then((result)=>{
      //  console.warn("Result",result)
        if (result.status === 400) {
            setErr("Unable to send mail");
          }
          else if (result.status === 200) {
            resetField("email");
            setSuc("Reset password link sent to your registered email. Please verify with in 30 mins");
          }
          else {
            setErr("Oops! Something went wrong. Please try again.");
          }
    })
  })
  }

  useEffect(() => {
    if (localStorage.getItem('user-info')) {
      history.push(`/profile/dashboard/${user.id}`)
    }
  }, []);

  return (
    <>
      <IndexNavbar fixed />
      <div className="container py-20 mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6  rounded-lg bg-white border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-2xl font-bold">
                    Reset password
                  </h6>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>

            <p className="font-bold text-green-600 text-center">{suc}</p>
            <p className="font-bold text-red-500 text-center">{err}</p>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Field>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email
                      </label>
                      <input
                        type="email" {...register("email",
                          {
                            required: {
                              value: "required",
                              message: "Email id is required"
                            },
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Please enter a valid email id"
                            }
                          })}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                      />
                    </div>
                  </Form.Field>
                  {errors.email && <p className="font-bold text-red-500">{errors.email.message}</p>}

                  <div className="text-center mt-6">
                    <button className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type='submit'>Submit</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}