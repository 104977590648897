import React, { useRef } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Dropdown() {
    const ServicesRef = useRef(null);
    const gotoServices = () =>
        window.scrollTo({
            top: ServicesRef.current.offsetTop,
            behavior: "smooth",
            // You can also assign value "auto"
            // to the behavior parameter.
        });
    return (
        <>
            <IndexNavbar fixed />


            <section className="flex-justify-center lg:py-20 lg:px-32 bg-neutral-100 lg:pt-48 sm:pt-98">
                <div class="text-center  ">
                    <h2 className="font-bold text-4xl text-blueGray-600">
                   
                        OUR SERVICES
                      
                    </h2>
                </div>
            </section>

            {/*first service*/}

            <section className="lg:py-24" id="strategic">

                <div className="container mx-auto">
                    <div className="justify-center flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4  ">
                            <div className="flex flex-wrap">

                                {/* Strategic Planning*/}

                                <div className="w-full lg:w-6/12 px-4 sm:py-84">
                                    <img
                                        className="pt-2 max-h-860px  "
                                        src={require("assets/img/strat.jpg").default}
                                        alt="..."
                                    />


                                </div>

                                <div className="w-full lg:w-6/12 px-4 sm:pb-98">
                                    <h2 className="font-bold text-4xl pb-4 lg:pl-11 pt-6 text-blueGray-600">
                                        Strategic Planning
                                    </h2>
                                    <div className="text-left">

                                        <p className="mt-4 text-xl lg:pl-11   font-normal text-blueGray-600">
                                            Strategic planning is a must for every business, it’s a process to figure out where
                                            your company is going through and how to get there but it’s also so much more which cannot be as easy in the present fast-moving
                                            world of business the current growing digital and Ecommerce markets a perfect plan to lead the company or organization is a must.
                                        </p><br></br>
                                        <p className="mt-2 text-xl lg:pl-11   font-normal text-blueGray-600">
                                            A strategic plan defines who you are as a business and lists and concentrate on the concrete
                                            actions to achieve your goals. When the unexpected occurs, a well-planned
                                            or an organized strategic plan helps your business survive and find new opportunities while staying true to your values and mission.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Second Service*/}

            <section className="bg-neutral-100" id="international">

                <div className="container lg:py-20 sm:py-20 mx-auto">
                    <div className="justify-center flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4  ">
                            <div className="flex lg:flex-wrap sm:flex-wrap-reverse">

                                {/* International Expansion*/}

                                <div className="w-full lg:w-6/12 px-4 ">
                                    <h2 className="font-bold text-4xl pb-4 pt-6  text-blueGray-600">
                                        International Expansion
                                    </h2>
                                    <div className="text-left">

                                        <p className="mt-4 text-xl  font-normal text-blueGray-600">
                                            International expansion strategies are formal, multi-level strategic plans that your organization need in
                                            terms to enter the world of current market, to help in establishing a growing presence, and reach
                                            a profitable phrase, Through the international markets and foreign expansion strategies which leads growth in the organization.
                                        </p><br></br>
                                        <p className="mt-2 text-xl font-normal text-blueGray-600">
                                            It also helps in structured and sustainable ways for Exporting into international culture,
                                            Importing as well into the shipping industries, The International markets are so advanced with the latest
                                            modern and civilized criteria’s we need to put our company into the present world of business management in the expansions we lead.
                                        </p>
                                    </div>

                                </div>

                                <div className="w-full lg:w-6/12  lg:pl-10 ">
                                    <img
                                        className="pt-2 max-h-860px  "
                                        src={require("assets/img/Expansion.png").default}
                                        alt="..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Third Service*/}

            <section className="lg:py-24" id="hr">

                <div className="container mx-auto">
                    <div className="justify-center flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="flex flex-wrap">

                                {/* HR Managements*/}

                                <div className="w-full lg:w-6/12 px-4 sm:py-84">
                                    <img
                                        className="pt-2 max-h-860px  "
                                        src={require("assets/img/HR.png").default}
                                        alt="..."
                                    />

                                </div>

                                <div className="w-full lg:w-6/12 px-4 sm:py-84">
                                    <h2 className="font-bold text-4xl pb-4 lg:pl-11   text-blueGray-600">
                                        HR Management
                                    </h2>
                                    <div className="text-left  ">

                                        <p className="mt-4 text-xl lg:pl-11  font-normal text-blueGray-600">
                                            The three major roles in human resources here in are administrative, change management, and people management.
                                            Administrative tasks include hiring and monitoring of employees screening and giving them the basic training in
                                            necessary aspects of  knowledge about the requirements of the companies, we also help in  managing payroll
                                            and other benefits, and development policies and guideline which is about the terms and conditions of the company and its wellbeing,
                                        </p><br></br>
                                        <p className="mt-2 text-xl lg:pl-11  font-normal text-blueGray-600">
                                            the human resources is very important as it’s the face of the company when entering into the
                                            world of business, if you do not hire a perfect employee for the given job, the company
                                            will be the loss bearer, its not only hiring the employees but also does a background verification in giving a gem to the company
                                            which leads the growth and unity with sophisticated working ethics in behavior will be the key ways we provide to the organizations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*Fourth Service*/}

            <section className="bg-neutral-100" id="technology">

                <div className="container lg:py-20 sm:py-20 mx-auto">
                    <div className="justify-center flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="flex lg:flex-wrap sm:flex-wrap-reverse">

                                {/*  Technology Selection*/}

                                <div className="w-full lg:w-6/12 px-4 ">
                                    <h2 className="font-bold text-4xl  pb-4 pt-6  text-blueGray-600">
                                        Technology Selection
                                    </h2>
                                    <div className="text-left  ">

                                        <p className="mt-4 text-xl   font-normal text-blueGray-600">
                                            Technology selection is a major criteria decision-making challenge. It is essential for decision-makers to consider
                                            various characteristics of criteria such as potential profit, risk, and expenses to identify the most appropriate technologies.
                                        </p><br></br>
                                        <p className="mt-2 text-xl  font-normal text-blueGray-600">
                                            Wherein technology of the particular reach is provided and taken care off in the business world of modern requirement.
                                        </p>
                                    </div>

                                </div>

                                <div className="w-full lg:w-6/12 lg:pl-10 px-4">
                                    <img
                                        className="pt-2 max-h-860px  "
                                        src={require("assets/img/Technology.png").default}
                                        alt="..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Fifth Service*/}

            <section className="lg:py-24" ref={ServicesRef} id="marketing">

                <div className="container mx-auto">
                    <div className="justify-center flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="flex flex-wrap">

                                {/*  Marketing*/}

                                <div className="w-full lg:w-6/12 px-4 sm:py-84">
                                    <img
                                        className="pt-2 max-h-860px  "
                                        src={require("assets/img/Marketing.png").default}
                                        alt="..."
                                    />

                                </div>

                                <div className="w-full lg:w-6/12 px-4 sm:pb-98">
                                    <h2 className="font-bold text-4xl pb-4 lg:pl-11 pt-6  text-blueGray-600">
                                        Marketing
                                    </h2>
                                    <div className="text-left">

                                        <p className="mt-4 text-xl lg:pl-11   font-normal text-blueGray-600">
                                            Marketing is the best way to reach the new era through digital world of business, it also
                                            sets us in educating institutions, and exploring people for creating a strategy
                                            it helps in communicating. marketing shows us where we can reach through the years of our journey in the current world of business.
                                        </p><br></br>
                                        <p className="mt-2 text-xl lg:pl-11 font-normal text-blueGray-600">
                                            It helps us in delivering the expectations of the clients, and gives us immense knowledge
                                            that give value for customers, clients, partners, and society at a larger area of reaching the target of knowing the company’s reach.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Sixth Service*/}

            <section className="bg-neutral-100" id="operational">

                <div className="container lg:py-20 mx-auto">
                    <div className="justify-center flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="flex lg:flex-wrap sm:flex-wrap-reverse">

                                {/* Operational Efficiency*/}

                                <div className="w-full lg:w-6/12 px-4 sm:pb-98">
                                    <h2 className="font-bold text-4xl pb-4 pt-6  text-blueGray-600">
                                        Operational Efficiency
                                    </h2>
                                    <div className="text-left  ">

                                        <p className="mt-4 text-xl font-normal text-blueGray-600">
                                            In a business context, operational efficiency is a measurement of resource
                                            allocation and can be defined as the ratio between an output gained from the business and an input to run a business operation. .
                                        </p><br></br>
                                        <p className="mt-2 text-xl  font-normal text-blueGray-600">
                                            When improving operational efficiency, the output to input ratio improves which is the a growth oriented.
                                        </p>
                                    </div>

                                </div>

                                <div className="w-full lg:w-6/12 lg:pl-10 px-4 sm:py-84">
                                    <img
                                        className="pt-2 max-h-860px  "
                                        src={require("assets/img/Operational.png").default}
                                        alt="..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Seventh Service*/}

            <section className="lg:py-24" id="certification">

                <div className="container mx-auto">
                    <div className="justify-center flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="flex flex-wrap">

                                {/* Certification support (ISO, HACCP)*/}

                                <div className="w-full lg:w-6/12 px-4 sm:py-84">
                                    <img
                                        className="pt-2 max-h-860px  "
                                        src={require("assets/img/strat.jpg").default}
                                        alt="..."
                                    />

                                </div>

                                <div className="w-full lg:w-6/12 px-4 sm:py-84">
                                    <h2 className="font-bold text-4xl pb-4 lg:pl-11   text-blueGray-600">
                                        Certification support (ISO, HACCP)
                                    </h2>
                                    <div className="text-left  ">

                                        <p className="mt-4 text-xl lg:pl-11 font-normal text-blueGray-600">
                                            ISO 9001 certification helps organizations to develop and improve performance, it is the trusted worldwide as well as
                                            demonstrate high levels of service quality when bidding for contracts. Certification follows successful completion of
                                            an audit against the ISO 9001 standard and enables organizations to: Operate more efficiently in terms
                                            of trust issues is concerned.
                                        </p><br></br>
                                        <p className="mt-2 text-xl lg:pl-11 font-normal text-blueGray-600">
                                            Hazard analysis and critical control points, or HACCP, is a systematic preventive approach to food safety from biological,
                                            chemical, and physical hazards in production processes that can cause the finished product to be unsafe and designs measures
                                            to reduce these risks to a safe level.
                                            One can accept blindly when its ISO certified. There can be more of clear contract no hidden agreements will be considered.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*8th Service*/}

            <section className="bg-neutral-100" id="trademark">

                <div className="container lg:py-20 mx-auto">
                    <div className="justify-center flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="flex lg:flex-wrap sm:flex-wrap-reverse">

                                {/*Trademark*/}

                                <div className="w-full lg:w-6/12 px-4 ">
                                    <h2 className="font-bold text-4xl pb-4 pt-6  text-blueGray-600">
                                        Trademark
                                    </h2>
                                    <div className="text-left  ">

                                        <p className="mt-4 text-xl  font-normal text-blueGray-600">
                                            A trademark is a type of intellectual property consisting of a recognizable sign, The trademark may be
                                            in terms of logo or in terms of the name in the industry or the reputation, design or expression that
                                            identifies products or services from a particular source and distinguishes them from others.
                                        </p><br></br>
                                        <p className="mt-2 text-xl font-normal sm:pb-98 text-blueGray-600">
                                            It indicates the genuine product or its making, like branding a company or product in the business world,
                                            The trademark owner can be an individual, business organization, or any legal entity.
                                        </p>
                                    </div>

                                </div>

                                <div className="w-full lg:w-6/12 lg:pl-10 px-4 sm:py-84">
                                    <img
                                        className="pt-2 max-h-860px  "
                                        src={require("assets/img/Trademark.png").default}
                                        alt="..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*9th Service*/}

            <section className="lg:py-24" id="financial">

                <div className="container mx-auto">
                    <div className="justify-center flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4 ">
                            <div className="flex flex-wrap">

                                {/*Financial Management*/}

                                <div className="w-full lg:w-6/12 px-4 sm:py-84">
                                    <img
                                        className="pt-2 max-h-860px  "
                                        src={require("assets/img/Financial.png").default}
                                        alt="..."
                                    />

                                </div>

                                <div className="w-full lg:w-6/12 px-4">
                                    <h2 className="font-bold text-4xl lg:pl-11 pt-6 pb-4 text-blueGray-600">
                                        Financial Management
                                    </h2>
                                    <div className="text-left  ">

                                        <p className="mt-2 text-xl lg:pl-11 font-normal text-blueGray-600">
                                            Financial management may be defined as the area or function in an organization which is concerned
                                            with profitability, expenses, cash and credit, so that the "organization may have the means to carry a clear
                                            and authorized financial guideline which is necessarily followed.
                                        </p><br></br>
                                        <p className="mt-2 text-xl lg:pl-11 sm:pb-98 font-normal text-blueGray-600">
                                            We help in showing you the right methods of understanding the profit and loss measurement.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*10th Service*/}

            <section className=" bg-neutral-100" id="ecommerce">

                <div className="container  lg:py-20 mx-auto">
                    <div className="justify-center flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="flex lg:flex-wrap sm:flex-wrap-reverse">

                                {/* E-commerce*/}

                                <div className="w-full lg:w-6/12 px-4 sm:pb-98">
                                    <h2 className="font-bold text-4xl pb-4  pt-6  text-blueGray-600">
                                        E-commerce
                                    </h2>
                                    <div className="text-left  ">

                                        <p className="mt-2 text-xl  font-normal text-blueGray-600">
                                            Electronic commerce is the buying and selling of goods and services, or the transmitting of funds or data,
                                            over an electronic network, primarily the Internet. These business transactions occur as business-to-business (B2B),
                                            business-to-consumer (B2C), consumer-to-consumer or consumer-to-business.
                                        </p><br></br>
                                        <p className="mt-2 text-xl   font-normal text-blueGray-600">
                                            Like amazon and flipkart which is now best in the digital world of online business market. Which helps in processing transactions in seconds on ecommerce websites.
                                        </p>
                                    </div>

                                </div>

                                <div className="w-full lg:w-6/12 lg:pl-10 px-4 sm:py-84">
                                    <img
                                        className="pt-2 max-h-860px  "
                                        src={require("assets/img/E-commerce.png").default}
                                        alt="..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    );
}