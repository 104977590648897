import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Spinner from "react-spinkit";

// components

export default function CardProfile() {

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [company, setCompany] = useState("");
  const [mobile, setMobile] = useState("");
  const [profile_pic, setProfile_pic] = useState("");
  const { id } = useParams();
  const [DataisLoaded, setDataisLoaded] = useState(false)
  let user = JSON.parse(localStorage.getItem('user-info'));

  useEffect(() => {
    getUserById();
  }, []);

  const getUserById = async () => {
    await fetch(`https://ibusinessconsulting.ca/admin/customerreact/single/${id}`)
      .then(res => res.json())
      .then(
        (result) => {
          //   console.log("Result",result)
          setFname(result.fname)
          setLname(result.lname)
          setEmail(result.email)
          setStreet(result.street)
          setCity(result.city)
          setCountry(result.country)
          setState(result.state)
          setZipcode(result.zipcode)
          setCompany(result.company)
          setMobile(result.mobile)
          setProfile_pic(result.profile_pic)
          setDataisLoaded(true)
        })
  }

  if (!DataisLoaded) return <div class="flex h-screen justify-center items-center">
    <div class="text-center bg-blue-400">
      <Spinner name="chasing-dots" />
    </div>
  </div>;
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-6">
        <div className="px-6">
          <div className="flex flex-wrap">
            <div className="flex-1 w-64 lg:justify-center">
              <div className="w-full lg:px-4 flex lg:justify-center">
                <div className="relative">
                  {profile_pic != "" ?
                    <img
                      alt="..."
                      src={profile_pic}
                      className="shadow-xl rounded-full h-auto  border-none absolute mt-10 lg:-ml-16  max-w-150-px"
                    />
                    :
                    <img
                      alt="..."
                      src={require("assets/img/img_avatar_male.png").default}
                      className="shadow-xl rounded-full h-auto  border-none absolute mt-10  lg:-ml-16 max-w-150-px"
                    />}

                </div>
              </div>
              <div className="w-full px-4 text-center mt-20">
                <div className="flex lg:justify-center py-4 lg:pt-4 pt-8">
                </div>
              </div>
            </div>

            <div className="flex-1 w-64 text-left mt-12">
            <h3 className="text-xl font-bold leading-normal  text-blueGray-700 mb-2">
                {fname} {lname}
              </h3>
              <div className="text-base leading-normal mt-0 mb-2 text-blueGray-400 font-semibold uppercase">
                <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>{" "}
                {street} {city}, {state}, {country}- {zipcode}.
              </div>
              <div className="mb-2 text-blueGray-600 mt-6">
                <i className="fas fa-university mr-2 text-base text-blueGray-400"></i>
                {company}
              </div>
              <div className="mb-2 text-blueGray-600">
                <i className="fas fa-envelope mr-2 text-base text-blueGray-400"></i>
                {email}
              </div>
              <div className="mb-2 text-blueGray-600">
                <i className="fas fa-phone-alt mr-2 text-base text-blueGray-400"></i>
                {mobile}
              </div>
            </div>
          </div>
          <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <Link to={`/profile/edit/${user.id}`}
                  href="#pablo"
                  className="font-normal text-lightBlue-500"
                >
                  Edit Profile
                </Link>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <Link to={`/profile/picture/${user.id}`}
                  href="#pablo"
                  className="font-normal text-lightBlue-500"
                >
                  Change Profile Picture
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
