import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Button } from 'semantic-ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Spinner from "react-spinkit";

// components

export default function CardPicture() {

    const [profile_pic, setProfile_pic] = useState("");
    const { id } = useParams();
    const [err, setErr] = useState("")
    const [suc, setSuc] = useState("")
    const [DataisLoaded, setDataisLoaded] = useState(false)

  useEffect(() => {
    getUserById();
  }, []);

  const getUserById = async () => {
    await fetch(`https://ibusinessconsulting.ca/admin/customerreact/single/${id}`)
      .then(res => res.json())
      .then(
        (result) => {
        //     console.log("Result",result)
          setProfile_pic(result.profile_pic)
          setDataisLoaded(true)
        })
  }

  const formSchema = Yup.object().shape({
    profile_pic: Yup.mixed()
    .required("Please upload Image")
    .test("type", "Only .jpeg, .jpg and .png formats are accepted", (value) => {
        return value && (
            value[0].type === "image/jpeg" ||
            value[0].type === "image/jpg" ||
            value[0].type === "image/png"
        );
    })
    .test("fileSize", "Image is too large", (value) => {
       // console.log(value)
        return value && value[0].size <= 1000000;  //1MB
    }),
});

  const formOptions = { resolver: yupResolver(formSchema) }
  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors } = formState

  const onSubmit = (data) => {
    const items = data.profile_pic;
    const formData = new FormData();
    formData.append('profile_pic', items[0]);
  fetch(`https://ibusinessconsulting.ca/admin/customerreact/picture/${id}`, {
    method: 'POST',
    body: formData,
  }).then((resp)=>{
    resp.json().then((result)=>{
     //   console.warn("Result",result)
        if (result.status === 400) {
            setErr(result.messages.profile_pic);
          }
          else {
            setSuc("Updated successfully");
          }
    })
  })
  }

  if (!DataisLoaded) return <div class="flex h-screen justify-center items-center">
  <div class="text-center bg-blue-400">
      <Spinner name="chasing-dots" />
  </div>
</div>;
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">My account</h6>
          </div>
          <hr className="mt-2 border-b-1 border-blueGray-300" />
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <p className="font-bold text-green-600 text-center">{suc}</p>
            <p className="font-bold text-red-500 text-center">{err}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Change Profile Picture
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <Form.Field>
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Profile Picture*
                    </label>
                    <input name="profile_pic"
                      type="file" {...register('profile_pic')}
                      className="border-0 px-3 py-3 border-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.profile_pic?.message}</div>
                </div>
              </div>
              </div>
            <br />
      {/*      <div className="container mx-auto">
              <div className="flex flex-wrap justify-center -mt-20 py-16 px-12">
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type='submit'
                >
                  Update
                </button>
              </div>
            </div> */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="text-center mt-6">
                  <Button className="ui secondary button bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type='submit'>Update</Button>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="text-center mt-6">
                  <Link
                    to={`/profile/settings/${JSON.parse(localStorage.getItem('user-info')).id}`}
                    className="ui secondary button bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  >Back</Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
