import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory, useParams } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import EditProfile from "views/admin/EditProfile.js";
import Picture from "views/admin/Picture.js";
import Spinner from "react-spinkit";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Upgrade from "views/admin/Upgrade.js";
import Renew from "views/admin/Renew.js";

export default function Admin() {

  const history = useHistory();
  const { id } = useParams();
  const [DataisLoaded, setDataisLoaded] = useState(false);
  let user = JSON.parse(localStorage.getItem('user-info'));

  useEffect(() => {
    if (!localStorage.getItem('user-info')) {
      history.push("/auth/login")
    }
  }, [])

  useEffect(() => {
    getUserById();
  }, []);

  const getUserById = async () => {
    await fetch(`https://ibusinessconsulting.ae/admin/customerreact/single/${user.id}`)
      .then(res => res.json())
      .then(
        (result) => {
          //   console.log("Result",result)
          setDataisLoaded(true)
        })
  }

  if (!DataisLoaded) return <div class="flex h-screen justify-center items-center">
    <div class="text-center bg-blue-400">
      <Spinner name="chasing-dots" />
    </div>
  </div>;
  return (
    <>
      <IndexNavbar fixed />
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className=" lg:px-10 sm:pb-100 sm:px-2 mx-auto w-full m-20 pt-6">
          <Switch>
            <Route path="/profile/dashboard/:id" exact component={Dashboard} />
            <Route path="/profile/password/:id" exact component={Maps} />
            <Route path="/profile/settings/:id" exact component={Settings} />
            <Route path="/profile/edit/:id" exact component={EditProfile} />
            <Route path="/profile/membership/:id" exact component={Tables} />
            <Route path="/profile/picture/:id" exact component={Picture} />
            <Route path="/profile/upgrade/:id" exact component={Upgrade} />
            <Route path="/profile/renew/:id" exact component={Renew} />
            <Redirect from="/profile/:id" to="/profile/dashboard/:id" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
