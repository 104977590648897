import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Spinner from "react-spinkit";

// components

export default function CardSocialTraffic() {

  const [purchased, setPurchased] = useState("");
  const [expired, setExpired] = useState("");
  const { id } = useParams();
  const [DataisLoaded, setDataisLoaded] = useState(false)

  let year = new Date().getFullYear();
  let month = ((new Date().getMonth() + 1) < 10) ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`;
  let date = ((new Date().getDate()) < 10) ? `0${new Date().getDate()}` : `${new Date().getDate()}`;
  let date1 = new Date(year + '-' + month + '-' + date);
  let date2 = new Date(expired);
  /*
    useEffect(() => {
      getUserById();
    }, []);
  
    const getUserById = async () => {
      await fetch(`https://ibusinessconsulting.ca/admin/customerreact/single/${id}`)
        .then(res => res.json())
        .then(
          (result) => {
            //     console.log("Result",result)
          })
    }
  */
  useEffect(() => {
    fetch(`https://ibusinessconsulting.ca/admin/upgradereact/customers_upgrade/${id}`)
      .then(res => res.json())
      .then(
        (results) => {
          setPurchased(results[0].purchased)
          setExpired(results[0].expired)
          setDataisLoaded(true)
        })
  }, [])

  if (!DataisLoaded) return <div class="flex h-screen justify-center items-center">
    <div class="text-center bg-blue-400">
      <Spinner name="chasing-dots" />
    </div>
  </div>;
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-2 max-w-full flex-grow flex-1">
              <h3 className="font-semibold   text-lg text-blueGray-700">
                Plan Upgradation / Plan Renewal
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead className="thead-light">
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-base uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Plan Details
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="border-t-0 font-semibold px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                  Your {expired >= (year + '-' + month + '-' + date) ? "current plan is" : "last plan was"} {purchased}
                </th>
              </tr>
              <tr>
                <th className="border-t-0 px-6  font-semibold align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-2 text-left">
                  Your plan status is {expired >= (year + '-' + month + '-' + date) ? "Active" : "Inactive"}
                </th>
              </tr>
              <tr>
                <th className="border-t-0  px-6 font-semibold align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">

                  {expired >= (year + '-' + month + '-' + date) ?
                    "Your plan expires in " + `${expired >= (year + '-' + month + '-' + date) ? Math.ceil((Math.abs(date2 - date1)) / (1000 * 60 * 60 * 24)) : "0"}` + " days"
                    :
                    "Your plan expired"
                  }
                </th>
              </tr>
              <tr>
                <th className="border-t-0  px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  {(expired >= (year + '-' + month + '-' + date)) && ((Math.abs(date2 - date1) / (1000 * 60 * 60 * 24)) >= 15) ?
                    <Link
                      to={{
                        pathname: `/profile/upgrade/${JSON.parse(localStorage.getItem('user-info')).id}`,
                        state: {
                          plan_name: { purchased }
                        }
                      }}
                      className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:text-white hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    >
                      To Upgrade
                    </Link>
                    :
                    <>
                      <Link
                        to={{
                          pathname: `/profile/upgrade/${JSON.parse(localStorage.getItem('user-info')).id}`,
                          state: {
                            plan_name: { purchased }
                          }
                        }}
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:text-white hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      >
                        To Upgrade
                      </Link>
                      <Link
                        to={{
                          pathname: `/profile/renew/${JSON.parse(localStorage.getItem('user-info')).id}`,
                          state: {
                            plan_name: { purchased }
                          }
                        }}
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:text-white hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      >
                        To Renew
                      </Link>
                    </>
                  }
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
