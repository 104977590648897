import React, { useState, useEffect } from 'react';

export default function Footer() {
  const [selectedCountry, setSelectedCountry] = useState('uae'); // Set 'uae' as the default
  const [time, setTime] = useState('');

  const handleCountryChange = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);

    if (country === 'uae') {
      const uaeTime = new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Dubai',
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      setTime('' + uaeTime);
    } else if (country === 'usa') {
      const usaTime = new Date().toLocaleString('en-US', {
        timeZone: 'America/New_York',
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      setTime('' + usaTime);
    } else if (country === 'canada') {
      const canadaTime = new Date().toLocaleString('en-US', {
        timeZone: 'America/Toronto',
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      setTime('' + canadaTime);
    } else if (country === 'tunisia') {
      const tunisiaTime = new Date().toLocaleString('en-US', {
        timeZone: 'Africa/Tunis',
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      setTime('' + tunisiaTime);
    } else {
      setTime('');
    }
  };

  // Call handleCountryChange with 'uae' as default when the component mounts
  useEffect(() => {
    handleCountryChange({ target: { value: 'uae' } });
  }, []);


  return (
    <>
      <footer className="relative bg-blueWhale-500 pt-8 md:hidden block">

        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-left lg:text-left md:text-left">
            <div className="w-full lg:w-6/12">
              <h4 className="text-3xl font-semibold text-white text-left">Contact US</h4>
              <p className="text-white textfont1 font-normal pt-2  lg:text-lg md:text-base">
                Block C VL01-173
                <br></br>Sharjah Research Technology and Innovation Park Free Zone
                <br></br>Sharjah,UAE
              </p>
              <p className="flex items-center text-white textfont1 font-normal lg:pt-2 md:pt-3 lg:text-xl md:text-base font-bold">
                <p className='font-normal text-2xl'>☏</p> <span className='pl-2'>971 55 626 9728</span>
              </p>
              <div className='w-full align-left justify-left text-left'>
                <div className='lg:pt-6 font-bold lg:text-4xl text-2xl pt-2 text-white pb-4'>{time}</div>
                <select value={selectedCountry} onChange={handleCountryChange} className='w-6/12 rounded-lg'>
                  <option value="uae">UAE</option>
                  <option value="usa">USA</option>
                  <option value="canada">Canada</option>
                  <option value="tunisia">Tunisia</option>
                </select>
              </div>
            </div>

            <div className="w-full lg:w-6/12 pt-4 px-4">
              <div className="flex flex-wrap items-top mb-2">
                <div className="w-full lg:w-4/12 text-left">
                  <span className="block uppercase text-white text-sm font-semibold mb-2">

                  </span>


                </div>
                <div className="w-full lg:w-4/12 text-left">
                  <span className="block uppercase text-white text-base font-bold mb-2">
                    <a href="/services">
                      Services
                    </a>
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                        href="/startup-services"
                      >
                        Corporate Services
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                        href="/hr-management"
                      >
                        Hr Services
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                        href="/web-design"
                      >
                        IT services
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                        href="/ip-services"
                      >
                        Intellectual Property Services
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="text-base text-white textfont1 font-semibold py-1 flex lg:pt-6 md:pt-6 lg:pl-4">
            <li className="flex items-center ">
              <a
                className="hover:text-blueGray-500 text-blueGray-700 pr-2 py-4 lg:py-2 md:py-2 flex items-center text-xs uppercase font-bold"
                href="/"
                target="_blank"
              >
                <div className="border px-3 py-2 rounded-full">
                  <i className="  text-white fab fa-facebook text-base leading-lg " />
                </div>
              </a>
            </li>
            <li className="flex items-center ">
              <a
                className="hover:text-blueGray-500 text-blueGray-700  py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="/"
                target="_blank"
              >
                <div className="border px-3 py-2 rounded-full">
                  <i className="  text-white fab fa-twitter text-base leading-lg " />
                </div>
              </a>
            </li>
            <li className="flex items-center ">
              <a
                className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="/"
                target="_blank"
              >
                <div className="border px-3 py-2 rounded-full">
                  <i className="  text-white fab fa-instagram text-base leading-lg " />
                </div>
              </a>
            </li>
            {/*<li className="flex items-center ">
                        <a
                          className="hover:text-blueGray-500 text-blueGray-700 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                          href="" target="_blank"
                        >
                          <div className="border px-3 py-2 rounded-full">
                            <i className=" text-white fab fa-youtube text-base leading-lg " />
                          </div>
                        </a>
                      </li>*/}
            <li className="flex items-center ">
              <a
                className="hover:text-blueGray-500 text-blueGray-700 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="/" target="_blank"
              >
                <div className="border px-3 py-2 rounded-full">
                  <i className="  text-white fab fa-linkedin text-base leading-lg " />
                </div>
              </a>
            </li>
          </div>
          {/*<hr className="my-6 border-blueGray-300" />*/}
        </div>
        <div className="flex flex-wrap py-4  items-left md:justify-between  bg-blueWhale-500">
          <div className="w-full md:w-4/12 px-4 mx-auto text-left">
            <div className="text-base text-white font-semibold py-1">
              @ 2023 iBusinessconsulting. All rights reserved.
            </div>
          </div>
        </div>
      </footer>


      <footer className="relative  md:block hidden">
        <section className=" bg-cover bg-blueWhale-500 ">
          <div className="container mx-auto items-center">
            <div className="w-full lg:px-12  lg:w-12/12 md:w-12/12">
              <div className="flex flex-wrap lg:px-12 lg:pt-6 md:px-12 md:pt-6">
                <div className="lg:w-6/12 md:w-5/12 lg:pt-8 md:pt-12 ">
                  <h1 className="text-white textfont1 font-bold lg:text-4xl  md:text-2xl">
                    Address
                  </h1>

                  <p className="text-white textfont1 font-normal lg:pt-2 md:pt-3 lg:text-lg md:text-base">
                    Block C VL01-173
                    <br></br>Sharjah Research Technology and<br></br> Innovation Park Free Zone
                    <br></br>Sharjah,UAE
                  </p>
                  <p className="flex text-white textfont1 font-normal lg:pt-2 md:pt-3 lg:text-xl md:text-base font-bold">
                    <p className='font-normal text-2xl'>☏</p> <span className='pl-2'>971 55 626 9728</span>
                  </p>

                  <div>
                    <div className='lg:pt-6 font-bold lg:text-4xl text-white pb-4'>{time}</div>
                    <select value={selectedCountry} onChange={handleCountryChange} className='lg:w-4/12 md:w-6/12 rounded-lg'>
                      <option value="uae">UAE</option>
                      <option value="usa">USA</option>
                      <option value="canada">Canada</option>
                      <option value="tunisia">Tunisia</option>
                    </select>
                  </div>
                </div>
                <div className="lg:w-6/12  md:w-7/12 md:pl-6 lg:pl-6">
                  <div className="justify-center items-center md:pt-12 lg:pt-12">
                    <div className="flex flex-wrap">
                      <div className="lg:w-6/12 md:w-6/12">
                        <h1 className="text-white lg:text-2xl  textfont1 px-2">
                          Quick Menu
                        </h1>
                        <p className="lg:pt-2 md:pt-2 text-white textfont1 px-2 text-sm lg:text-lg">
                          <a href="/about">
                            About Us
                          </a>
                        </p>

                        <p className="lg:pt-1 md:pt-1 text-white textfont1 px-2 text-sm lg:text-lg">
                          <a href="/services">
                            Services
                          </a>
                        </p>
                        <p className="lg:pt-1 md:pt-1 text-white textfont1 px-2 text-sm lg:text-lg">
                          <a href="/contact">
                            Contact Us
                          </a>
                        </p>
                      </div>
                      <div className="lg:w-6/12 md:w-6/12">
                        <h1 className="text-white lg:text-2xl  textfont1 px-4">
                          Services
                        </h1>
                        <p className="lg:pt-2 md:pt-2 text-white textfont1 px-4 text-sm lg:text-lg">
                          <a href="/startup">
                            Corporate Services
                          </a>
                        </p>
                        <p className="lg:pt-1 md:pt-1 text-white textfont1 px-4 text-sm lg:text-lg">
                          <a href="/hrmanage">
                            HR Services
                          </a>
                        </p>
                        <p className="lg:pt-1 md:pt-1 text-white textfont1 px-4 text-sm lg:text-lg">
                          <a href="/webdesign">
                            IT Services
                          </a>
                        </p>
                        <p className="lg:pt-1 md:pt-1 text-white  px-4 text-sm lg:text-lg">
                          <a href="/intellect">
                            Intellectual Property Services
                          </a>
                        </p>

                      </div>
                    </div>
                    <div className="text-base text-white textfont1 font-semibold py-1 flex lg:pt-6 md:pt-6 lg:pl-4">
                      <li className="flex items-center ">
                        <a
                          className="hover:text-blueGray-500 text-blueGray-700 pr-2 py-4 lg:py-2 md:py-2 flex items-center text-xs uppercase font-bold"
                          href="/"
                          target="_blank"
                        >
                          <div className="border px-3 py-2 rounded-full">
                            <i className="  text-white fab fa-facebook text-base leading-lg " />
                          </div>
                        </a>
                      </li>
                      <li className="flex items-center ">
                        <a
                          className="hover:text-blueGray-500 text-blueGray-700  py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                          href="/"
                          target="_blank"
                        >
                          <div className="border px-3 py-2 rounded-full">
                            <i className="  text-white fab fa-twitter text-base leading-lg " />
                          </div>
                        </a>
                      </li>
                      <li className="flex items-center ">
                        <a
                          className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                          href="/"
                          target="_blank"
                        >
                          <div className="border px-3 py-2 rounded-full">
                            <i className="  text-white fab fa-instagram text-base leading-lg " />
                          </div>
                        </a>
                      </li>
                      {/*<li className="flex items-center ">
                        <a
                          className="hover:text-blueGray-500 text-blueGray-700 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                          href="" target="_blank"
                        >
                          <div className="border px-3 py-2 rounded-full">
                            <i className=" text-white fab fa-youtube text-base leading-lg " />
                          </div>
                        </a>
                      </li>*/}
                      <li className="flex items-center ">
                        <a
                          className="hover:text-blueGray-500 text-blueGray-700 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                          href="/" target="_blank"
                        >
                          <div className="border px-3 py-2 rounded-full">
                            <i className="  text-white fab fa-linkedin text-base leading-lg " />
                          </div>
                        </a>
                      </li>
                    </div>
                    <div className="flex lg:pl-6">
                      <h1 className="text-white pr-4 items-center textfont1 py-2 lg:text-base">
                        <a href="/terms">
                          Terms of use
                        </a>
                      </h1>
                      <h1 className="text-white  items-center textfont1 py-2">
                        <div className="border-l px-4  border-r">
                          <a href="/privacy">
                            Privacy Policy</a>
                        </div>
                      </h1>
                      <h1 className="text-white px-4 items-center textfont1 py-2 lg:text-base lg:pb-6">
                        <a href="/privacy">
                          Cancellation Policy
                        </a>
                      </h1>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap py-2 lg:mt-4 items-center md:justify-between justify-center bg-blueWhale-500 ">
            <div className="w-full md:w-5/12 px-4  text-center">
            </div>
            <div className="w-full md:w-12/12 px-4  text-center">
              <div className="text-base text-white textfont1 ">

                <div className="text-base text-white textfont1 py-1">
                  Copyright © 2023 iBusiness Consulting Team.
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
}
