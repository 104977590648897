import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import servicebnr1 from "assets/img/servicebnr1.jpg";


export default function services() {

  return (
    <>
      <IndexNavbar fixed />
      <section className="header lg:h-530-px h-300-px lg:pt-24 md:pt-32 md:block hidden bg-no-repeat bg-contain servicebanner1" >
        <div>
          <p className="text-center md:text-4xl text-2xl font-extrabold ttt lg:pt-32 md:pt-2 text-blueWhale pt-36">Our Services</p>
        </div>
        <div className="pt-4 text-xl w-full  text-black  tracking-normal text-center">
          <p className=" font-bold pb-2">iBusiness Consultant - To help you succeed.</p>
        </div>
      </section>
      <section className="md:pb-0 pb-32 md:block hidden lg:pt-0 md:pt-12" >
        <div className="container mx-auto ">
          <div className="md:px-0 relative ">
            {/* First Col */}
            <div class=" w-full flex relative flex-wrap items-start lg:px-8 md:px-0  px-4 lg:-mt-24">

              <div class="hover:-mt-4 mb-6 w-full h-full md:px-4 md:w-6/12 lg:w-3/12  transition ease-out duration-150" style={{
                padding: "1%"
              }}>
                <a
                  href={'/startup'}>
                  <div className="rounded-lg bg-white border shadow p-8 hover:bg-lightBlue-200 hover:shadow-lg">
                    <img
                      alt="..."
                      className="max-w-full w-18 p-2 "
                      src={require("assets/img/iplanning02.png").default}
                    />
                    <h2 className="text-xl ttt text-blueWhale mt-4 font-bold">
                      Corporation Services
                    </h2>
                    <p className="mt-2 text-blueGray-500 text-lg break-normal ttt">Incorporating a business in a foreign jurisdiction is never easy ,
                    </p>
                    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-3 pb-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                  </div>
                </a>
              </div>

              <div class="hover:-mt-4 mb-6 w-full h-full md:px-2 md:w-6/12 lg:w-3/12 transition ease-out duration-150" style={{
                padding: "1%"
              }}>
                <a
                  href={'/hrmanage'}>
                  <div className="rounded-lg bg-white border shadow p-8 hover:bg-lightBlue-200 hover:shadow-lg">
                    <img
                      alt="..."
                      className="max-w-full w-18 p-2"
                      src={require("assets/img/iHR02.png").default}
                    />
                    <h2 className="text-xl text-blueWhale mt-4 font-bold ttt">
                      HR Services
                    </h2>
                    <p className="mt-2 text-blueGray-500 text-lg break-normal ttt">
                      Our HR department responsible for finding, selecting, recruiting, and
                    </p>
                    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-3 pb-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                  </div>
                </a>
              </div>

              <div class="hover:-mt-4 mb-6 w-full h-full md:px-2 md:w-6/12 lg:w-3/12 transition ease-out duration-150" style={{
                padding: "1%"
              }}>
                <a
                  href={'/webdesign'}>
                  <div className="rounded-lg bg-white border shadow p-8 hover:bg-lightBlue-200 hover:shadow-lg">
                    <img
                      alt="..."
                      className="max-w-full w-18 p-2"
                      src={require("assets/img/Extension02.png").default}
                    />
                    <h2 className="text-xl text-blueWhale mt-4 font-bold ttt">
                      IT Services
                    </h2>
                    <p className="mt-2 text-blueGray-500 text-lg break-normal  ttt">
                      Our strong consulting and web design services focus on providing </p>
                    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-3 pb-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                  </div>
                </a>
              </div>
              <div class="hover:-mt-4 mb-6 w-full h-full md:px-2 md:w-6/12 lg:w-3/12 transition ease-out duration-150" style={{
                padding: "1%"
              }}>
                <a
                  href={'/intellect'}>
                  <div className="rounded-lg bg-white border shadow p-8 hover:bg-lightBlue-200 hover:shadow-lg">
                    <img
                      alt="..."
                      className="max-w-full w-18 p-2"
                      src={require("assets/img/itech02.png").default}
                    />
                    <h2 className="text-xl text-blueWhale mt-4 font-bold ttt">
                      IP Services
                    </h2>
                    <p className="mt-2 text-blueGray-500 text-lg break-normal  ttt">Intellectual property (IP) refers to intellectual creations such as inventions </p>
                    <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-3 pb-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                  </div>
                </a>
              </div>
            </div>
            <h1 className="font-bold ttt text-lg text-blue md:pt-12 lg:px-40 md:px-12 lg:pb-16 md:pb-12">
              Our Consulting Services focus on client issues, opportunities including startegy,
              marketing, organization, operations, technology, digital, advanced analytics and cross industry.
            </h1>
          </div>
        </div>

      </section>



      {/* Mobile View */}

      <section className="bg-no-repeat h-300-px lg:pt-24 md:pt-32 md:hidden block " style={{ backgroundImage: `url(${servicebnr1})` }}>
        <div>
          <p className="text-center md:text-4xl text-2xl font-extrabold ttt md:pt-32 text-black pt-36">Our Services</p>
        </div>
        <div className="pt-2 text-xl w-full  text-black  tracking-normal text-center ttt">
          <p className=" pb-2">iBusiness Consultant - To help <br></br>you succeed.</p>
        </div>
      </section>
      <div className="container mx-auto -mt-12 md:hidden block ">
        <div className="px-2 md:px-0 relative">
          {/* First Col */}
          <div class="w-full flex relative flex-wrap items-start md:px-28 px-4">

            <div class="hover:-mt-4 mb-6 w-full h-full md:px-4 md:w-3/12 lg:w-3/12  transition ease-out duration-150" style={{
              padding: "1%"
            }}>
              <a
                href={'/startup'}>
                <div className="bg-neutral-100 shadow-lg p-8 hover:bg-lightBlue-200 hover:shadow-lg">
                  <img
                    alt="..."
                    className="max-w-full w-18 p-2 "
                    src={require("assets/img/iplanning02.png").default}
                  />
                  <h2 className="text-xl ttt text-blueWhale mt-2 font-bold">
                    Corporation Services
                  </h2>
                  <p className="ttt pt-2 text-blueGray-500 text-base break-normal font-semibold">Incorporating a business in a foreign jurisdiction is never
                  </p>
                  <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-3 pb-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </div>
              </a>
            </div>

            <div class="hover:-mt-4 mb-6 w-full h-full md:px-4 md:w-3/12 lg:w-3/12 transition ease-out duration-150" style={{
              padding: "1%"
            }}>
              <a
                href={'/hrmanage'}>
                <div className="bg-neutral-100 shadow-lg p-8 hover:bg-lightBlue-200 hover:shadow-lg">
                  <img
                    alt="..."
                    className="max-w-full w-18 p-2"
                    src={require("assets/img/Extension02.png").default}
                  />
                  <h2 className="text-xl text-blueWhale mt-2 font-bold ttt">
                    HR Services
                  </h2>
                  <p className="ttt pt-2 text-blueGray-500 text-base  break-normal font-semibold">
                    Our HR department responsible for finding, selecting, recruiting,
                  </p>
                  <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-3 pb-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </div>
              </a>
            </div>

            <div class="hover:-mt-4 mb-6 w-full h-full md:px-4 md:w-3/12 lg:w-3/12 transition ease-out duration-150" style={{
              padding: "1%"
            }}>
              <a
                href={'/webdesign'}>
                <div className=" bg-neutral-100 shadow-lg p-8 hover:bg-lightBlue-200 hover:shadow-lg">
                  <img
                    alt="..."
                    className="max-w-full w-18 p-2"
                    src={require("assets/img/iHR02.png").default}
                  />
                  <h2 className="text-xl text-blueWhale mt-2 font-bold ttt">
                    IT Services
                  </h2>
                  <p className="ttt pt-2 text-blueGray-500 text-base break-normal font-semibold">There are many IT services that can benefit your business </p>
                  <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-3 pb-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </div>
              </a>
            </div>
            <div class="hover:-mt-4 mb-6 w-full h-full md:px-4 md:w-3/12 lg:w-3/12 transition ease-out duration-150" style={{
              padding: "1%"
            }}>
              <a
                href={'/intellect'}>
                <div className="bg-neutral-100 shadow-lg p-8 hover:bg-lightBlue-200 hover:shadow-lg">
                  <img
                    alt="..."
                    className="max-w-full w-18 p-2"
                    src={require("assets/img/itech02.png").default}
                  />
                  <h2 className="text-xl text-blueWhale mt-2 font-bold ttt">
                    Intellectual Services
                  </h2>
                  <p className="ttt pt-2 text-blueGray-500 text-base break-normal font-semibold">Intellectual property (IP) refers to intellectual creations such as  </p>
                  <button className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xl px-3 pb-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </div>
              </a>
            </div>
          </div>
        </div>
        <h1 className="ttt text-lg text-center text-blue pb-6 px-4">
          Our Consulting Services focus on client issues, opportunities including startegy,
          marketing, organization, operations, technology, digital, advanced analytics and cross industry.
        </h1>
      </div>
      <Footer />
    </>
  );
}