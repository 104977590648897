/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";


export default function Accordion() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [dropOpen, setdropOpen] = React.useState(false);
  const [dropdownOpen, setdropdownOpen] = React.useState(false);
  const [downOpen, setdownOpen] = React.useState(false);
  const [downsOpen, setdownsOpen] = React.useState(false);

  return (
    <>
      <nav className=" sm:lg:container mx-auto  md:w-full   md:pb-4 xl:pr-0 md:block hidden">

        <div className="">
          <h1 className="font-bold text-xl lg:pl-20 md:pl-10">
            OUR SERVICES
          </h1>
        </div>
        <div className="lines md:hidden block">
        </div>
        <div id="menuToggle4">
          <input type="checkbox" />
          <span></span>
          <span></span>
          <span></span>
          <ul id="menu4">
            <div className="border-b">
              <li className="flex items-center relative ">
                <Link
                  to="/services"
                  className=" px-2 lg:py-2 flex items-center text-base uppercase font-bold">
                  Services
                </Link>
              </li>
            </div>
            <div className="border-b">
              <li className=" items-center relative ">
                <button className="flex cursor-pointer lg:static lg:text-lg lg:pr-2 lg:pl-0 lg:py-1 text-3xl text-white absolute  lg:border-0 rounded outline-none focus:outline-none"
                  type="button"
                  onClick={() => setdropdownOpen(!dropdownOpen)}>
                  <div className="lg:py-2 flex items-center text-base uppercase font-bold">
                    Coporation Services
                  </div>
                  <i class="fas fa-caret-down lg:pt-3 px-2" ></i>
                </button>
                <div
                  className={
                    " flex lg:w-auto  " +
                    (dropdownOpen ? " block " : " hidden")
                  }
                >
                  <button className="fixed inset-0 cursor-default"
                    onClick={(e) => {
                      setdropdownOpen(false); setNavbarOpen(false);
                    }}>

                  </button>

                  <ul className="absolute right-0 left-0 top-16 lg:w-80 lg:mt-0 px-8  z-50 lg:relative  lg:rounded-md flex flex-col list-none"
                    onClick={(e) => {
                      setdropdownOpen(false); setNavbarOpen(false);
                    }}>

                    <li className="flex items-center border-b py-1">
                      <HashLink smooth to={'/startup'}
                        className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                      >
                        Startup Services
                      </HashLink>
                    </li>
                    <li className="flex items-center border-b py-1">
                      <HashLink smooth to={'/advisory'}
                        className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                      >
                        Business Advisory Services
                      </HashLink>
                    </li>
                  </ul>
                </div>
              </li>
            </div>

            <div className="border-b">
              <div
                className={
                  " lg:w-auto  " +
                  (dropdownOpen ? " pt-28 " : " pt-0")
                }
              >
                <li className=" items-center relative ">
                  <button className="flex cursor-pointer lg:static lg:text-lg lg:pr-2 lg:pl-0 lg:py-1 text-3xl text-white absolute  lg:border-0 rounded outline-none focus:outline-none"
                    type="button"
                    onClick={() => setdropOpen(!dropOpen)}>
                    <div className="lg:py-2 flex items-center text-base uppercase font-bold">
                      HR Services
                    </div>
                    <i class="fas fa-caret-down lg:pt-3 px-2" ></i>
                  </button>
                  <div
                    className={
                      " flex lg:w-auto  " +
                      (dropOpen ? " block " : " hidden")
                    }
                  >
                    <button className="fixed inset-0 cursor-default"
                      onClick={(e) => {
                        setdropOpen(false); setNavbarOpen(false);
                      }}>

                    </button>

                    <ul className="absolute right-0 left-0 top-16 lg:w-80 lg:mt-0 px-8  z-50 lg:relative  lg:rounded-md flex flex-col list-none"
                      onClick={(e) => {
                        setdropOpen(false); setNavbarOpen(false);
                      }}>

                      <li className="flex items-center border-b py-1">
                        <HashLink smooth to={'/Hrmanage'}
                          className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                        >
                          HR Management
                        </HashLink>
                      </li>
                      <li className="flex items-center border-b py-1">
                        <HashLink smooth to={'/employee'}
                          className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                        >
                          Employee
                        </HashLink>
                      </li>
                    </ul>
                  </div>
                </li>
              </div>
            </div>


            <div className="border-b">
              <div
                className={
                  " lg:w-auto  " +
                  (dropOpen ? " pt-28 " : " pt-0")
                }
              >
                <li className=" items-center relative ">
                  <button className="flex cursor-pointer lg:static lg:text-lg lg:pr-2 lg:pl-0 lg:py-1 text-3xl text-white absolute  lg:border-0 rounded outline-none focus:outline-none"
                    type="button"
                    onClick={() => setdownOpen(!downOpen)}>
                    <div className=" px-2 lg:py-2 flex items-center text-base uppercase font-bold">
                      IT Services
                    </div>
                    <i class="fas fa-caret-down lg:pt-3 px-2" ></i>
                  </button>
                  <div
                    className={
                      " flex lg:w-auto  " +
                      (downOpen ? " block " : " hidden")
                    }
                  >
                    <button className="fixed inset-0 cursor-default"
                      onClick={(e) => {
                        setdownOpen(false); setNavbarOpen(false);
                      }}>

                    </button>

                    <ul className="absolute right-0 left-0 top-16 lg:w-80 lg:mt-0 px-8  z-50 lg:relative  lg:rounded-md flex flex-col list-none"
                      onClick={(e) => {
                        setdownOpen(false); setNavbarOpen(false);
                      }}>

                      <li className="flex items-center border-b py-1">
                        <HashLink smooth to={'/webdesign'}
                          className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                        >Web Design
                        </HashLink>
                      </li>
                      <li className="flex items-center border-b border-gray-100 py-1">
                        <HashLink smooth to={'/webdevelop'}
                          className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                        >
                          Web Development
                        </HashLink>
                      </li>
                      <li className="flex items-center border-b py-1">
                        <HashLink smooth to={'/mobileapp'}
                          className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                        >
                          App Development
                        </HashLink>
                      </li>
                      <li className="flex items-center border-b py-1">
                        <HashLink smooth to={'/digital'}
                          className="hover:text-white text-white lg:py-1 flex items-center text-base  font-semibold bg-transparent"
                        >
                          Digital Marketing
                        </HashLink>
                      </li>
                    </ul>
                  </div>
                </li>
              </div>
            </div>

            <div className="">
            <div
                className={
                  " flex lg:w-auto  " +
                  (downOpen ? " pt-56 " : " pt-0")
                }
              >
              <li className="flex items-center relative ">
                <Link
                  to="/intellect"
                  className=" px-2 py-4 lg:py-2 flex items-center text-base uppercase font-bold">
                  IP Services
                </Link>
              </li>
              </div>
            </div>
          </ul>
        </div>

        <div class="wrapper md:hidden block">
          <div class="half">
            <div class="tab">

              {/* Corporation Services */}

              <input id="tab-one" type="checkbox" name="tabs" />
              <label for="tab-one" className="text-base ttt">
                <div className="flex"><img
                  alt="..."
                  className="w-8"
                  src={require("assets/img/iplanning.png").default}
                />
                  <h1 className="text-base ttt px-2 items-center">
                    Corporation Services
                  </h1>
                </div>
              </label>
              <div class="tab-content">
                <p>
                  <Link to="/startup">
                    <p className="text-lg ttt">
                      <div className="flex"><img
                        alt="..."
                        className="w-8 p-2"
                        src={require("assets/img/imgs/next.png").default}
                      />
                        <h1 className="text-base ttt px-2 items-center pt-1">
                          Startup Services
                        </h1>
                      </div>
                    </p>
                  </Link>
                  <Link to="/advisory">
                    <p className="text-lg ttt">
                      <div className="flex"><img
                        alt="..."
                        className="w-8 p-2"
                        src={require("assets/img/imgs/next.png").default}
                      />
                        <h1 className="text-base ttt px-2 items-center pt-1">
                          Business Advisory Services
                        </h1>
                      </div>
                    </p>
                  </Link>
                </p>
              </div>
            </div>

            {/* HR Services */}

            <div class="tab">
              <input id="tab-two" type="checkbox" name="tabs" />
              <label for="tab-two">
                <div className="flex"><img
                  alt="..."
                  className="w-8"
                  src={require("assets/img/iHR.png").default}
                />
                  <h1 className="text-base ttt px-2 items-center pt-1">
                    HR Services
                  </h1>
                </div>
              </label>
              <div class="tab-content">
                <p>
                  <a href="/Hrmanage">
                    <p className="text-lg ttt">
                      <div className="flex"><img
                        alt="..."
                        className="w-8 p-2"
                        src={require("assets/img/imgs/next.png").default}
                      />
                        <h1 className="text-base ttt px-2 items-center pt-1">
                          HR Management
                        </h1>
                      </div>
                    </p>
                  </a>
                  <a href="/employee">
                    <p className="text-lg ttt">
                      <div className="flex"><img
                        alt="..."
                        className="w-8 p-2"
                        src={require("assets/img/imgs/next.png").default}
                      />
                        <h1 className="text-base ttt px-2 items-center pt-1">
                          Employee
                        </h1>
                      </div>
                    </p>
                  </a>
                </p>
              </div>
            </div>

            {/* IT Services */}

            <div class="tab">
              <input id="tab-three" type="checkbox" name="tabs" />
              <label for="tab-three">
                <div className="flex"><img
                  alt="..."
                  className="w-8"
                  src={require("assets/img/Extension.png").default}
                />
                  <h1 className="text-base ttt px-2 items-center pt-1">
                    IT Services
                  </h1>
                </div>
              </label>
              <div class="tab-content">
                <p>
                  <a href="/webdesign">
                    <p className="text-lg ttt">
                      <div className="flex"><img
                        alt="..."
                        className="w-8 p-2"
                        src={require("assets/img/imgs/next.png").default}
                      />
                        <h1 className="text-base ttt px-2 items-center pt-1">
                          Web Design
                        </h1>
                      </div>
                    </p>
                  </a>
                  <a href="/webdevelop">
                    <p className="text-lg ttt">
                      <div className="flex"><img
                        alt="..."
                        className="w-8 p-2"
                        src={require("assets/img/imgs/next.png").default}
                      />
                        <h1 className="text-base ttt px-2 items-center pt-1">
                          Web Development
                        </h1>
                      </div>
                    </p>
                  </a>
                  <a href="/mobileapp">
                    <p className="text-lg ttt">
                      <div className="flex"><img
                        alt="..."
                        className="w-8 p-2"
                        src={require("assets/img/imgs/next.png").default}
                      />
                        <h1 className="text-base ttt px-2 items-center pt-1">
                          App Development
                        </h1>
                      </div>
                    </p>
                  </a>
                  <a href="/digital">
                    <p className="text-lg ttt">
                      <div className="flex"><img
                        alt="..."
                        className="w-8 p-2"
                        src={require("assets/img/imgs/next.png").default}
                      />
                        <h1 className="text-base ttt px-2 items-center pt-1">
                          Digital Marketing
                        </h1>
                      </div>
                    </p>
                  </a>
                </p>
              </div>
            </div>

            {/* IP Services */}
            <div class="tab mb-12">
              <input id="tab-four" type="checkbox" name="tabs" />
              <label for="tab-four">
                <a href="/intellect">
                  <div className="flex"><img
                    alt="..."
                    className="w-8"
                    src={require("assets/img/itech.png").default}
                  />
                    <h1 className="text-base ttt px-2 items-center pt-1">
                      IP Services
                    </h1>
                  </div>
                  <ion-icon class="ion-icon-add" name="add-outline"></ion-icon>
                  <ion-icon
                    class="ion-icon-removed"
                    name="remove-outline"
                  ></ion-icon>
                </a>
              </label>
            </div>
          </div>
        </div>

      </nav>


      <div className="border bg  lg:mx-4  md:block hidden lg:my-10 rounded-2xl">
        <h1 className="text-3xl text-white  ttt md:pl-4 md:pt-6">
          iBusiness Consulting
        </h1>
        <p className="text-lg text-white  ttt md:pl-4 pt-6">
          Wherever you need to trade or invest, we are local company experts and ensure that local laws, regulations and official
        </p>
        <div className="md:mt-8 pl-4 md:pb-12">
          <a
            href="/plan"
            className="get-started whitespace-nowrap text-white font-bold px-4 py-3 rounded-full outline-none focus:outline-none mr-2 mb-2 bg-transparent border  active:bg-lightBlue-600 uppercase text-base shadow hover:shadow-lg ease-linear transition-all duration-150"
          >
            Get Your Plan
          </a>
        </div>
      </div>
    </>
  );
}