import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";


export default function Privacy() {
    return (
        <>
            <IndexNavbar fixed />
            <section className="container lg:px-32 md:pt-32 md:px-0 px-4 pt-32">
                <h1 className="font-bold ttt text-center text-4xl">
                    Privacy Policy
                </h1>
                <div className="md:px-4">
                    <p className="md:pt-12 lg:text-lg md:text-xl ttt pt-4">
                        Your privacy is important to us.
                    </p>
                    <p className="md:py-2 lg:text-lg md:text-xl ttt py-2">
                        iBC is committed to customer satisfaction and respect for customer privacy, based on the
                        principles of trust, integrity, and providing superior products and services.
                        This is accomplished through a variety of business procedures and security measures that
                        keep your personal information confidential and secure and will only be used in accordance
                        with this Privacy Policy or with your consent.
                    </p>
                    <p className="md:py-2 py-2 lg:text-lg md:text-xl ttt">
                        This is accomplished through a variety of business procedures and security measures that keep your
                        personal information confidential and secure and will only be used in accordance with this Privacy
                        Policy or with your consent.
                    </p>
                    <p className="md:py-2 py-2 lg:text-xl md:text-2xl font-bold ttt text-black">
                        Application of this policy
                    </p>
                    <p className="md:py-2 py-2 lg:text-lg md:text-xl ttt">
                        This Privacy Policy applies generally to all "Personal Information" that iBC collects,
                        uses or discloses. Subject to applicable data protection laws, “personal data” means any
                        information relating to an identifiable individual. However, this Privacy Policy does not apply
                        to the collection, use, or disclosure of:
                    </p>
                    <p className="md:py-2 lg:text-lg md:text-xl ttt">
                        1 . Publicly available information such as an individual's name, address, phone number,
                        and email address (if listed in public directories).
                    </p>

                    <p className="md:py-2 py-2 lg:text-lg md:text-xl ttt">
                        2 . Business contact information, including the name, title, company address or
                        phone number of an employee of the organization.
                    </p>
                    <p className="md:py-2 py-2 lg:text-lg md:text-xl ttt">
                        3 . Personal data that is aggregated and cannot be linked to an identifiable individual,
                        and other information that is not personal data or is expressly excluded by applicable data protection laws.
                    </p>
                    <p className="md:py-2 py-2 lg:text-xl md:text-2xl font-bold ttt text-black">
                        Collect Personal Data
                    </p>
                    <p className="md:py-2 py-2 lg:text-lg md:text-xl ttt">
                        We collect personal information about you, either directly from you or through persons or
                        organizations acting on our behalf, including:
                    </p>
                    <p className="md:py-2 py-2 lg:text-lg md:text-xl ttt">
                        1 . Personal information that you enter into forms on our website. This includes financial
                        information and e-mail his address, name, postal address and other identifying and contact
                        information. Anything you enter or provide when registering or opening an account, logging in,
                        or using our services.
                    </p>
                    <p className="md:py-2 py-2 lg:text-lg md:text-xl ttt">
                        2 . Information for billing and payment purposes, including credit or debit card, such as
                        card number and card expiration date. We collect location and/or movement data from applications,
                        forms, survey and competition information, and from global positioning systems (GPS) when location
                        services are enabled.
                    </p>
                    <p className="md:py-2 py-2 lg:text-lg md:text-xl ttt">
                        iBC uses your personal information to efficiently provide the services and products you
                        request, to verify your identity, to manage our operations, and to make our services more
                        convenient and intelligent. to collect
                    </p>
                    <p className="md:py-2 py-2 lg:text-lg md:text-xl ttt">
                        We may obtain your express consent in writing or electronically.
                        Your consent may also be implied by the actions you take. You may opt out or withdraw your
                        consent to the collection, use, or disclosure of your personal information at any time. In some cases,
                        this may mean that we are unable to provide the products or services you have requested.

                    </p>
                    <p className="md:py-2 py-2 lg:text-xl md:text-2xl font-bold ttt text-black">
                        Consent to Collection, Use, or Disclosure of Personal Information.
                    </p>
                    <p className="md:py-2 py-2 lg:text-lg md:text-xl ttt">
                        Disclosure of Personal Information we will not share your personal information with
                        third parties except as described in this Privacy Policy, with your consent, or as permitted
                        or required by law. Your personal data may be disclosed:
                    </p>
                    <p className="md:py-2 py-2 lg:text-xl md:text-2xl font-bold ttt text-black">
                        Responsibility
                    </p>
                    <p className="md:py-2 py-2 lg:text-lg md:text-xl ttt">
                        iBC is responsible for complying with this Privacy Policy and applicable data protection laws.
                        Regularly monitor security measures and procedures to ensure they are effective and properly applied.
                    </p>
                    <p className="md:py-2 py-2 lg:text-lg md:text-xl ttt">
                        Our Data Protection Officer is responsible for ensuring compliance with this Privacy Policy.
                        If you have any questions about this Privacy Policy, our practices, or any complaints or concerns, please contact
                        our Data Protection Officer.
                    </p>
                    <p className="md:py-2 py-2 lg:text-lg md:text-xl ttt">
                        iBC will promptly investigate all complaints and, if found to be justified, will take
                        appropriate action, including changing policies and practices.
                    </p>
                    <p className="md:py-2 py-2 lg:text-lg md:text-xl ttt md:pb-32 pb-6">
                        Our communication management;
                        We respect your choice regarding receiving electronic communications from us.
                        Communication settings will be changed within 10 business days free of charge.
                    </p>
                </div>
            </section>
            <Footer />
        </>
    )
}