import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form } from 'semantic-ui-react';
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const Reset_password = (props) => {

    const history = useHistory();
    const token = props.match.params.param;
    const [err, setErr] = useState("");
    const [suc, setSuc] = useState("");
    const [isActive, setActive] = useState(false);
    const [isActivecp, setActivecp] = useState(false);
    let user = JSON.parse(localStorage.getItem('user-info'));

    //   console.log(props.location);
    //   console.log(props.match);
    //   console.log(props.history);
    //   console.log(token);

    function setWithExpiry(reset, ttl) {
        const now = new Date()

        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: "Your password is changed successfully. Login Now.",
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(reset, JSON.stringify(item))
    }

    const formSchema = Yup.object().shape({
        pass: Yup.string()
            .required('Please enter New Password')
            .min(8, 'Password must contain at least 8 characters')
            .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, one number and one special case character"
            ),
        cpass: Yup.string()
            .required('Please enter Confirm Password')
            .oneOf([Yup.ref('pass')], 'Password & Confirm Password does not match')
    })
    const formOptions = { resolver: yupResolver(formSchema) }
    const { register, resetField, handleSubmit, formState } = useForm(formOptions)
    const { errors } = formState

    const onSubmit = (data) => {
        //    console.log(data);
        fetch(`https://ibusinessconsulting.ae/admin/customerreact/reset_password/${token}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        }).then((resp) => {
            resp.json().then((result) => {
                //    console.warn("Result", result)
                if (result.status === 200) {
                    //    localStorage.setItem("reset", "Your password is changed successfully. Login Now.")
                    setWithExpiry("reset_to_login", 5000);  // 5000 = 5 seconds
                    history.push("/auth/login");
                    resetField("pass");
                    resetField("cpass");
                    setSuc("Your password is changed successfully. Login Now.");
                }
                else if (result.status === 404) {
                    setErr("Your Reset Password link has expired.");
                }
                else {
                    setErr("Oops! Something went wrong. Please try again.");
                }
            })
        })
    }

    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            history.push(`/profile/dashboard/${user.id}`)
        }
    }, []);

    function myFunction() {
        var x = document.getElementById("pass");
        if (isActive) {
            setActive(false);
            x.type = "password";
        }
        else {
            setActive(true);
            x.type = "text";
        }
    }

    function myFunctiontwo() {
        var x = document.getElementById("cpass");
        if (isActivecp) {
            setActivecp(false);
            x.type = "password";
        }
        else {
            setActivecp(true);
            x.type = "text";
        }
    }

    return (
        <>
            <IndexNavbar fixed />
            <div className="container mx-auto px-4 h-full">
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-4/12 px-4">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                            <div className="rounded-t mb-0 px-6 py-6">
                                <div className="text-center mb-3">
                                    <h6 className="text-blueGray-500 text-sm font-bold">
                                        Reset password
                                    </h6>
                                </div>
                                <hr className="mt-6 border-b-1 border-blueGray-300" />
                            </div>

                            <p className="font-bold text-green-600 text-center">{suc}</p>
                            <p className="font-bold text-red-500 text-center">{err}</p>
                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Field>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                New Password*
                                            </label>
                                            <div className="absolute inset-y-px right-0 pr-4 pt-5 flex items-center">
                                                <a class="btn" onClick={myFunction} role="button"><i className={isActive ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></a>
                                            </div>
                                            <input id="pass"
                                                type="password" {...register('pass')}
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="New Password"
                                            />
                                        </div>
                                    </Form.Field>
                                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.pass?.message}</div>

                                    <Form.Field>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Confirm Password*
                                            </label>
                                            <div className="absolute inset-y-px right-0 pr-4 pt-5 flex items-center">
                                                <a class="btn" onClick={myFunctiontwo} role="button"><i className={isActivecp ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></a>
                                            </div>
                                            <input id="cpass"
                                                type="password" {...register('cpass')}
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Confirm Password"
                                            />
                                        </div>
                                    </Form.Field>
                                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.cpass?.message}</div>

                                    <div className="text-center mt-6">
                                        <button className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type='submit'>Submit</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Reset_password;