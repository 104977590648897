import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Spinner from "react-spinkit";
import Moment from 'moment';

// components

import CardStats from "components/Cards/CardStats.js";

export default function HeaderStats() {

  const [purchased, setPurchased] = useState("");
  const [starts, setStarts] = useState("");
  const [expired, setExpired] = useState("");
  const [duration, setDuration] = useState("");
  const { id } = useParams();
  const [DataisLoaded, setDataisLoaded] = useState(false);
  let user = JSON.parse(localStorage.getItem('user-info'));

  let year = new Date().getFullYear();
  let month = ((new Date().getMonth() + 1) < 10) ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`;
  let date = ((new Date().getDate()) < 10) ? `0${new Date().getDate()}` : `${new Date().getDate()}`;
  let date1 = new Date(year + '-' + month + '-' + date); 
  let date2 = new Date(expired);

  useEffect(() => {
    getUserById();
  }, []);

  const getUserById = async () => {
    await fetch(`https://ibusinessconsulting.ca/admin/upgradereact/customers_upgrade/${user.id}`)
      .then(res => res.json())
      .then(
        (result) => {
             console.log("Result",result)
          setPurchased(result[0].purchased)
          setDuration(result[0].duration)
          setStarts(result[0].starts)
          setExpired(result[0].expired)
          setDataisLoaded(true)
        })
  }

  if (!DataisLoaded) return <div class="flex h-screen justify-center items-center">
    <div class="text-center bg-blue-400">
        <Spinner name="chasing-dots" />
    </div>
  </div>;
  return (
    <>
      {/* Header */}
      <div className="relative  md:pt-32 pb-28 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-3/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="PLAN"
                  statTitle={purchased}
                  statArrow=""
                  statPercent=""
                  statPercentColor=""
                  statDescripiron=""
                  statIconName="fas fa-tasks"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-3/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="STARTS FROM"
                  statTitle={Moment(starts).format('DD/MM/YYYY')}
                  statArrow=""
                  statPercent=""
                  statPercentColor=""
                  statDescripiron=""
                  statIconName="fas fa-clock"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-3/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle={expired >= (year + '-' + month + '-' + date) ? "Expires On" : "Expired On"}
                  statTitle={Moment(expired).format('DD/MM/YYYY')}
                  statArrow=""
                  statPercent=""
                  statPercentColor=""
                  statDescripiron=""
                  statIconName="fas fa-calendar"
                  statIconColor="bg-pink-500"
                />
              </div>
              <div className="w-full lg:w-3/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="STATUS"
                  statTitle={expired >= (year + '-' + month + '-' + date) ? "Active" : "Inactive"}
                  statArrow=""
                  statPercent=""
                  statPercentColor=""
                  statDescripiron=""
                  statIconName="fas fa-toggle-on"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}