import React, { useState } from "react";
import { useForm } from "react-hook-form";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Accordion from "components/Dropdowns/Accordion.js";
import Footer from "components/Footers/Footer.js";
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";
import HRmobile from "assets/img/SERVICE/HRmobile.png";
import Popup from "reactjs-popup";
import Menus from "components/Menus.js";

const contentStyle = {
    maxWidth: "600px",
    width: "90%"
};
export default function Employee() {

    //const [stepzero, setStepzero] = useState(true);
    const [stepfir, setStepfir] = useState(true);
    const [stepsec_1, setStepsec_1] = useState(false);
    const [stepsec, setStepsec] = useState(false);
    const [stepthi, setStepthi] = useState(false);
    const [stepfou, setStepfou] = useState(false);
    const [stepfiv, setStepfiv] = useState(false);
    const [stepsix, setStepsix] = useState(false);
    const [stepsev, setStepsev] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isCoperation, setIsCoperation] = useState(false);
    const [isHR, setIsHR] = useState(false);
    const [isIT, setIsIT] = useState(false);
    const [isIPR, setIsIPR] = useState(false);
    const [consultingValue, setConsultingValue] = useState('');
    const [error, setError] = useState(false);
    const [suc, setSuc] = useState(false);
    const [err, setErr] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    var consultations = [];

    const onSubmitOne = async data => {
        //alert(JSON.stringify(data));
        if (suc) {
            // setStepzero(false);
            var markedChecks = document.getElementsByName("consulting");
            for (var checkbox of markedChecks) {
                if (checkbox.checked) {
                    consultations.push(checkbox.value);
                }
            }
            console.log(consultations);
            console.log(typeof consultations);
            if (consultations.includes("Corporation Services")) {
                setIsCoperation(true);
            }
            if (consultations.includes("HR Services")) {
                setIsHR(true);
            }
            if (consultations.includes("IT Services")) {
                setIsIT(true);
            }
            if (consultations.includes("Intellectual Property Services")) {
                setIsIPR(true);
            }
            setStepfir(false);
            setStepsec_1(true);
            setStepsec(false);
            setStepthi(false);
            setStepfou(false);
            setStepfiv(false);
            setStepsix(false);
            setStepsev(false);

            // consultations = Array.from(document.querySelectorAll("input[type=checkbox][name=consulting]:checked"), e => e.value);
            // console.log(consultations);
        }
    };
    function validateMedium() {
        var mediumCheckboxes = document.getElementsByName("consulting");
        var okay = false;

        for (var i = 0, len = mediumCheckboxes.length; i < len; i++) {
            if (mediumCheckboxes[i].checked) {
                okay = true;
                break;
            }
        }

        if (okay) {
            setSuc(true);
            setErr(false);
            //setStepzero(false);
            //setStepzero(false);
            //setStepfir(false);
            //setStepsec(true);
            //setStepthi(false);
            //setStepfou(false);
            //setStepfiv(false);
            //setStepsix(false);
            //setStepsev(false);
        } else {
            setSuc(false);
            setErr(true);
        }
    }

    // function validateSub() {
    //   var mediumCheckboxes = document.getElementsByName("sub_consulting");
    //   var okay = false;

    //   for (var i = 0, len = mediumCheckboxes.length; i < len; i++) {
    //     if (mediumCheckboxes[i].checked) {
    //       okay = true;
    //       break;
    //     }
    //   }

    //   if (okay) {
    //     setSuc(true);
    //     setErr(false);
    //     //setStepzero(false);
    //     //setStepzero(false);
    //     //setStepfir(false);
    //     //setStepsec(true);
    //     //setStepthi(false);
    //     //setStepfou(false);
    //     //setStepfiv(false);
    //     //setStepsix(false);
    //     //setStepsev(false);
    //   } else {
    //     setSuc(false);
    //     setErr(true);
    //   }
    // }

    const onSubmitTwo_1 = async data => {
        setStepfir(false);
        setStepsec_1(false);
        setStepsec(true);
        setStepthi(false);
        setStepfou(false);
        setStepfiv(false);
        setStepsix(false);
        setStepsev(false);
    }

    const onSubmitTwo = async data => {
        //alert(JSON.stringify(data));
        //setStepzero(false);
        setStepfir(false);
        setStepsec_1(false);
        setStepsec(false);
        setStepthi(true);
        setStepfou(false);
        setStepfiv(false);
        setStepsix(false);
        setStepsev(false);
    };

    const onSubmitThree = async data => {
        //alert(JSON.stringify(data));
        //setStepzero(false);
        setStepfir(false);
        setStepsec_1(false);
        setStepsec(false);
        setStepthi(false);
        setStepfou(true);
        setStepfiv(false);
        setStepsix(false);
        setStepsev(false);
    };

    const onSubmitFour = async data => {
        //alert(JSON.stringify(data));
        //setStepzero(false);
        setStepfir(false);
        setStepsec_1(false);
        setStepsec(false);
        setStepthi(false);
        setStepfou(false);
        setStepfiv(true);
        setStepsix(false);
        setStepsev(false);
    };

    const onSubmitFive = async data => {
        //alert(JSON.stringify(data));
        //setStepzero(false);
        setStepfir(false);
        setStepsec_1(false);
        setStepsec(false);
        setStepthi(false);
        setStepfou(false);
        setStepfiv(false);
        setStepsix(true);
        setStepsev(false);
    };

    const onSubmitSix = async data => {
        //alert(JSON.stringify(data));
        //setStepzero(false);
        setStepfir(false);
        setStepsec_1(false);
        setStepsec(false);
        setStepthi(false);
        setStepfou(false);
        setStepfiv(false);
        setStepsix(false);
        setStepsev(true);
    };

    const onSubmitSeven = async data => {
        //  console.log(JSON.stringify(data));
        //setStepzero(false);
        setStepfir(false);
        setStepsec_1(false);
        setStepsec(false);
        setStepthi(false);
        setStepfou(false);
        setStepfiv(false);
        setStepsix(false);
        setStepsev(false);
        let result = await fetch("https://localhost/admin/Questionreact", {
            method: 'POST',
            body: JSON.stringify(
                {
                    consulting: JSON.stringify(data.consulting),
                    sub_consulting: JSON.stringify(data.sub_consulting),
                    business: data.business,
                    running: data.running,
                    revenue: data.revenue,
                    industry: data.industry,
                    start: data.start,
                    email: data.email
                }
            ),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json()
        //  console.warn("Result", result)
        if (result.status === 201) {
            setSuccess(true);
            setError(false);
        }
        else {
            setSuccess(false);
            setError(true);
        }
    };

    return (
        <>
            <IndexNavbar fixed />
            <section className="bg-no-repeat HRservice md:block hidden">
                <div className="container mx-auto w-full pb-8  lg:h-300-px md:h-300-px h-250-px" >
                    <div className="w-full lg:w-12/12 md:w-12/12 z-50 items-center">
                        <div className="flex flex-wrap">
                            <div className="lg:w-1/12 md:w-0/12 w-0/12">
                            </div>
                            <div className="lg:w-4/12 md:w-4/12 w-4/12 bg-brown lg:pt-32 md:pt-16 pt-24 lg:pb-8 md:pb-8 pb-6">
                                <div className="flex">
                                    <Menus />
                                    <h1 className="text-white text-xl text-left lg:pt-8 md:pt-24 pt-12 lg:px-14 px-14 md:px-16 ttt ">
                                        Menu
                                    </h1>
                                </div>

                                <h1 className="text-white pt-4 lg:pt-4 lg:pb-18 md:pb-22 pb-12 lg:text-3xl md:text-xl text-xl text-left lg:px-10 md:px-10 px-10 ttt">
                                    Employee Services
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-no-repeat md:hidden block bg-cover w-full" style={{ backgroundImage: `url(${HRmobile})` }}>
                <div className="container mx-auto w-full pb-8  lg:h-300-px md:h-300-px h-250-px" >
                    <div className="w-full lg:w-12/12 md:w-12/12 z-50 items-center">
                        <div className="flex flex-wrap">
                            <div className="lg:w-1/12 md:w-0/12 w-0/12">
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <div className="lg:w-4/12 md:w-4/12 w-12/12 bg-brown md:hidden block">
                <div className="flex">
                    <Menus />
                    <h1 className="text-white text-xl text-left px-14 ttt ">
                    </h1>
                </div>
                <h1 className="text-white pt-2 lg:pb-14 md:pb-16 pb-6 lg:text-3xl md:text-2xl text-2xl text-left lg:px-10 md:px-10 px-16 ttt">
                    Employee Services
                </h1>
            </div>


            <div className="container mx-auto">
                <div className="w-full lg:w-12/12 md:w-12/12">
                    <div className="flex flex-wrap">
                        <div className="lg:w-1/12 md:w-0/12">
                        </div>
                        <div className="lg:w-5/12 md:w-12/12 lg:pt-24 pt-12">
                            <h1 className="ttt text-left lg:text-4xl md:text-3xl text-2xl md:px-0 px-4">
                                Employers have <span className="font-bold"> different resource</span> needs
                            </h1>
                            <div className="flex flex-wrap">
                                <div className="lg:w-12/12 md:w-12/12">
                                    <p className="md:text-lg text-lg  pt-4 ttt text-left text-justify md:px-0 px-4">
                                        <span className="font-bold text-xl"> Training and development: </span>Employees should have
                                        access to training and development opportunities to help them improve their skills and
                                        knowledge. This can include on-the-job training, workshops and classes.
                                    </p>
                                    <p className="md:text-lg text-lg  pt-3 ttt text-left text-justify md:px-0 px-4">
                                        <span className="font-bold text-xl"> Equipment and tools: </span>Employees may need access to
                                        equipment and tools to perform their jobs effectively. This may include specialized
                                        software and equipment for specific roles.
                                    </p>
                                    <p className="md:text-lg text-lg  pt-3 ttt text-left text-justify md:px-0 px-4">
                                        <span className="font-bold text-xl">Support and Guidance: </span>Employers may need
                                        support and guidance to help employees navigate the work environment and perform their
                                        jobs effectively.
                                    </p>
                                    <p className="md:text-lg text-lg py-2 ttt text-left text-justify md:px-0 px-4">
                                        It is important that employers understand the different resource needs of
                                        their employees and provide support and resources accordingly
                                    </p>
                                </div>
                                <div className="w-full lg:pb-20 md:pb-2 mt-6 md:px-0 px-4">
                                <a href="/hrserv">
                                    <button className="get-started whitespace-nowrap text-white font-bold px-8 py-3  rounded-full outline-none focus:outline-none mr-2 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-base shadow hover:shadow-lg ease-linear transition-all duration-150">
                                                Get Quote
                                            </button>
                                    </a>
                                    {/*<Popup
                                        trigger={
                                            <button className="get-started whitespace-nowrap text-white font-bold px-8 py-3  rounded-full outline-none focus:outline-none mr-2 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-base shadow hover:shadow-lg ease-linear transition-all duration-150">
                                                Get Quote
                                            </button>
                                        }
                                        modal
                                        contentStyle={contentStyle}
                                    >
                                        {(close) => (
                                            <div className="w-full p-12 border bg-white">
                                                <div className="text-lg">
                                                    <a
                                                        className="cursor-pointer rounded right-6 top-0  pb-2  absolute text-5xl "
                                                        onClick={close}
                                                    >
                                                        &times;
                                                    </a>
                                                    {/*<div className="w-full border-b p-4 text-xl text-center">Get Quote</div>
                                                    <div className="p-4">
                                                        {" "}
                                                        {/*} {stepzero ?
<>
Answer the following questions to get your quote
</>
:
null
}
                                                        {stepfir ? (
                                                            <>
                                                                <form onSubmit={handleSubmit(onSubmitOne)}>
                                                                    <div className="relative w-full text-xl ">
                                                                        <label
                                                                            className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                                                            htmlFor="grid-password"
                                                                        >
                                                                            Which type(s) of consulting are you
                                                                            interested in?
                                                                        </label>
                                                                        <input
                                                                            {...register("consulting", {
                                                                                required: false,
                                                                                validate: validateMedium,
                                                                            })}
                                                                            type="checkbox"
                                                                            value="Corporation Services"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2 ">
                                                                            Corporation Services
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("consulting", {
                                                                                required: false,
                                                                                validate: validateMedium,
                                                                            })}
                                                                            type="checkbox"
                                                                            value="HR Services"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2 ">
                                                                            HR Services
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("consulting", {
                                                                                required: false,
                                                                                validate: validateMedium,
                                                                            })}
                                                                            type="checkbox"
                                                                            value="IT Services"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2 ">
                                                                            IT Services
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("consulting", {
                                                                                required: false,
                                                                                validate: validateMedium,
                                                                            })}
                                                                            type="checkbox"
                                                                            value="Intellectual Property Services"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2 ">
                                                                            Intellectual Property Services
                                                                        </label>
                                                                        <br />

                                                                        {/* <input {...register("consulting", { required: false, validate: validateMedium })} type="checkbox" value="Certification Support" />
                                          <label
                                            className="text-blueGray-600 pl-2 "
                                        >
                                            Certification support
                                        </label>
                                        <br />

                                        <input {...register("consulting", { required: false, validate: validateMedium })} type="checkbox" value="Trademark" />
                                        <label
                                            className="text-blueGray-600 pl-2 "
                                        >
                                            Trademark
                                        </label>
                                        <br />

                                        <input {...register("consulting", { required: false, validate: validateMedium })} type="checkbox" value="E-Commerce" />
                                        <label
                                            className="text-blueGray-600 pl-2 "
                                        >
                                            E-Commerce
                                        </label>
                                        <br />

                                        <input {...register("consulting", { required: false, validate: validateMedium })} type="checkbox" value="Marketing" />
                                        <label
                                            className="text-blueGray-600 pl-2 "
                                        >
                                            Marketing
                                        </label>
                                                                        <br />
                                                                        <br />
                                                                        {err ? (
                                                                            <span
                                                                                role="alert"
                                                                                className="text-red-500 pt-4"
                                                                            >
                                                                                Please select your option
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                    <br />
                                                                    <div className="lg:px-4 pb-2">
                                                                        <button
                                                                            type="submit"
                                                                            className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center"
                                                                            style={{ padding: "2" }}
                                                                        >
                                                                            Continue
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </>
                                                        ) : null}
                                                        {stepsec_1 ? (
                                                            <>
                                                                <form onSubmit={handleSubmit(onSubmitTwo_1)}>
                                                                    <div className="relative w-full text-xl ">
                                                                        <label
                                                                            className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                                                            htmlFor="grid-password"
                                                                        >
                                                                            Which type of services?
                                                                        </label>
                                                                        {isCoperation && (
                                                                            <>
                                                                                <input
                                                                                    {...register("sub_consulting", {
                                                                                        required: false,
                                                                                    })}
                                                                                    type="checkbox"
                                                                                    value="Startup Services"
                                                                                />
                                                                                <label className="text-blueGray-600 pl-2 ">
                                                                                    Startup Services
                                                                                </label>
                                                                                <br />
                                                                                <input
                                                                                    {...register("sub_consulting", {
                                                                                        required: false,
                                                                                    })}
                                                                                    type="checkbox"
                                                                                    value="Business Advisory Services"
                                                                                />
                                                                                <label className="text-blueGray-600 pl-2 ">
                                                                                    Business Advisory Services
                                                                                </label>
                                                                                <br />
                                                                            </>
                                                                        )}

                                                                        {isHR && (
                                                                            <>
                                                                                <input
                                                                                    {...register("sub_consulting", {
                                                                                        required: false,
                                                                                    })}
                                                                                    type="checkbox"
                                                                                    value="HR Management"
                                                                                />
                                                                                <label className="text-blueGray-600 pl-2 ">
                                                                                    HR Management
                                                                                </label>
                                                                                <br />
                                                                                <input
                                                                                    {...register("sub_consulting", {
                                                                                        required: false,
                                                                                    })}
                                                                                    type="checkbox"
                                                                                    value="Employee"
                                                                                />
                                                                                <label className="text-blueGray-600 pl-2 ">
                                                                                    Employee
                                                                                </label>
                                                                                <br />
                                                                            </>
                                                                        )}

                                                                        {isIT && (
                                                                            <>
                                                                                <input
                                                                                    {...register("sub_consulting", {
                                                                                        required: false,
                                                                                    })}
                                                                                    type="checkbox"
                                                                                    value="Web Design"
                                                                                />
                                                                                <label className="text-blueGray-600 pl-2 ">
                                                                                    Web Design
                                                                                </label>
                                                                                <br />
                                                                                <input
                                                                                    {...register("sub_consulting", {
                                                                                        required: false,
                                                                                    })}
                                                                                    type="checkbox"
                                                                                    value="Web Development"
                                                                                />
                                                                                <label className="text-blueGray-600 pl-2 ">
                                                                                    Web Development
                                                                                </label>
                                                                                <br />
                                                                                <input
                                                                                    {...register("sub_consulting", {
                                                                                        required: false,
                                                                                    })}
                                                                                    type="checkbox"
                                                                                    value="Mobile Application"
                                                                                />
                                                                                <label className="text-blueGray-600 pl-2 ">
                                                                                    Mobile Application
                                                                                </label>
                                                                                <br />
                                                                                <input
                                                                                    {...register("sub_consulting", {
                                                                                        required: false,
                                                                                    })}
                                                                                    type="checkbox"
                                                                                    value="Digital Marketing"
                                                                                />
                                                                                <label className="text-blueGray-600 pl-2 ">
                                                                                    Digital Marketing
                                                                                </label>
                                                                                <br />
                                                                            </>
                                                                        )}

                                                                        {isIPR && (
                                                                            <>
                                                                                <input
                                                                                    {...register("sub_consulting", {
                                                                                        required: false,
                                                                                    })}
                                                                                    type="checkbox"
                                                                                    value="Intellectual Property Services"
                                                                                />
                                                                                <label className="text-blueGray-600 pl-2 ">
                                                                                    Intellectual Property Services
                                                                                </label>
                                                                                <br />
                                                                            </>
                                                                        )}
                                                                        <br />
                                                                        {errors.sub_consulting?.type ===
                                                                            "required" && (
                                                                                <span
                                                                                    role="alert"
                                                                                    className="text-red-500 pt-4"
                                                                                >
                                                                                    Please select your option
                                                                                </span>
                                                                            )}
                                                                    </div>
                                                                    <br />
                                                                    <div className="w-full flex flex-col md:flex-row px-4 text-center">
                                                                        <div className="lg:px-4 pb-2">
                                                                            <button
                                                                                className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center"
                                                                                style={{ padding: "2" }}
                                                                                onClick={() => {
                                                                                    setStepfir(true);
                                                                                    setStepsec_1(false);
                                                                                }}
                                                                            >
                                                                                Back
                                                                            </button>
                                                                        </div>
                                                                        <div className="lg:px-4 pb-2">
                                                                            <button
                                                                                type="submit"
                                                                                className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center"
                                                                                style={{ padding: "2" }}
                                                                            >
                                                                                Continue
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </>
                                                        ) : null}
                                                        {stepsec ? (
                                                            <>
                                                                <form onSubmit={handleSubmit(onSubmitTwo)}>
                                                                    <div className="relative w-full text-xl ">
                                                                        <label
                                                                            className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                                                            htmlFor="grid-password"
                                                                        >
                                                                            How large is the business?
                                                                        </label>
                                                                        <input
                                                                            {...register("business", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="Small (fewer than 10 employees)"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2 ">
                                                                            Small (fewer than 10 employees)
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("business", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="Medium (11-50 employees)"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            Medium (11-50 employees)
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("business", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="Large (more than 50 employees)"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            Large (more than 50 employees)
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("business", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="Not Sure"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            Not sure
                                                                        </label>
                                                                        <br />
                                                                        <br />
                                                                        {errors.business?.type === "required" && (
                                                                            <span
                                                                                role="alert"
                                                                                className="text-red-500 pt-4"
                                                                            >
                                                                                Please select your option
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <br />
                                                                    <div className="w-full flex flex-col md:flex-row px-4 text-center">
                                                                        <div className="lg:px-4 pb-2">
                                                                            <button
                                                                                className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center"
                                                                                style={{ padding: "2" }}
                                                                                onClick={() => {
                                                                                    setStepsec_1(true);
                                                                                    setStepsec(false);
                                                                                }}
                                                                            >
                                                                                Back
                                                                            </button>
                                                                        </div>
                                                                        <div className="lg:px-4 pb-2">
                                                                            <button
                                                                                type="submit"
                                                                                className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center"
                                                                                style={{ padding: "2" }}
                                                                            >
                                                                                Continue
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </>
                                                        ) : null}
                                                        {stepthi ? (
                                                            <>
                                                                <form onSubmit={handleSubmit(onSubmitThree)}>
                                                                    <div className="relative w-full text-xl ">
                                                                        <label
                                                                            className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                                                            htmlFor="grid-password"
                                                                        >
                                                                            How long has the business been running?
                                                                        </label>
                                                                        <input
                                                                            {...register("running", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="Less than 1 year"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            Less than 1 year
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("running", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="2-3 years"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            2-3 years
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("running", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="4-5 years"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            4-5 years
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("running", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="6 years or more"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            6 years or more
                                                                        </label>
                                                                        <br />
                                                                        <br />
                                                                        {errors.running?.type === "required" && (
                                                                            <span
                                                                                role="alert"
                                                                                className="text-red-500 pt-4"
                                                                            >
                                                                                Please select your option
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <br />
                                                                    <div className="w-full flex flex-col md:flex-row px-4 text-center">
                                                                        <div className="lg:px-4 pb-2">
                                                                            <button
                                                                                className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center"
                                                                                style={{ padding: "2" }}
                                                                                onClick={() => {
                                                                                    setStepsec(true);
                                                                                    setStepthi(false);
                                                                                }}
                                                                            >
                                                                                Back
                                                                            </button>
                                                                        </div>
                                                                        <div className="lg:px-4 pb-2">
                                                                            <button
                                                                                type="submit"
                                                                                className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center"
                                                                                style={{ padding: "2" }}
                                                                            >
                                                                                Continue
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </>
                                                        ) : null}
                                                        {stepfou ? (
                                                            <>
                                                                <form onSubmit={handleSubmit(onSubmitFour)}>
                                                                    <div className="relative w-full text-xl ">
                                                                        <label
                                                                            className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                                                            htmlFor="grid-password"
                                                                        >
                                                                            What is the business' current annual
                                                                            revenue?
                                                                        </label>
                                                                        <input
                                                                            {...register("revenue", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="Less than C$50k"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            Less than C$50k
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("revenue", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="C$50k - C$100k"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            C$50k - C$100k
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("revenue", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="C$100k - C$250k"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            C$100k - C$250k
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("revenue", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="C$250k - C$500k"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            C$250k - C$500k
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("revenue", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="C$500k - C$1m"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            C$500k - C$1m
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("revenue", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="C$1m - C$5m"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            C$1m - C$5m
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("revenue", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="More than C$5m"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            More than C$5m
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("revenue", {
                                                                                required: true,
                                                                            })}
                                                                            type="radio"
                                                                            value="I'd prefer not to say"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            I'd prefer not to say
                                                                        </label>
                                                                        <br />
                                                                        <br />
                                                                        {errors.revenue?.type === "required" && (
                                                                            <span
                                                                                role="alert"
                                                                                className="text-red-500 pt-4"
                                                                            >
                                                                                Please select your option
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <br />
                                                                    <div className="w-full flex flex-col md:flex-row px-4 text-center">
                                                                        <div className="lg:px-4 pb-2">
                                                                            <button
                                                                                className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center"
                                                                                style={{ padding: "2" }}
                                                                                onClick={() => {
                                                                                    setStepthi(true);
                                                                                    setStepfou(false);
                                                                                }}
                                                                            >
                                                                                Back
                                                                            </button>
                                                                        </div>
                                                                        <div className="lg:px-4 pb-2">
                                                                            <button
                                                                                type="submit"
                                                                                className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center"
                                                                                style={{ padding: "2" }}
                                                                            >
                                                                                Continue
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </>
                                                        ) : null}
                                                        {stepfiv ? (
                                                            <>
                                                                <form onSubmit={handleSubmit(onSubmitFive)}>
                                                                    <div className="relative w-full text-xl ">
                                                                        <label
                                                                            className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                                                            htmlFor="grid-password"
                                                                        >
                                                                            What industry is your business in?
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            {...register("industry", {
                                                                                required: {
                                                                                    value: "required",
                                                                                    message: "Please enter your industry",
                                                                                },
                                                                            })}
                                                                            className="border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                                        />
                                                                        <br />
                                                                        <br />
                                                                        {errors.industry && (
                                                                            <span
                                                                                role="alert"
                                                                                className="text-red-500 pt-4"
                                                                            >
                                                                                {errors.industry.message}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <br />
                                                                    <div className="w-full flex flex-col md:flex-row px-4 text-center">
                                                                        <div className="lg:px-4 pb-2">
                                                                            <button
                                                                                className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center"
                                                                                style={{ padding: "2" }}
                                                                                onClick={() => {
                                                                                    setStepfou(true);
                                                                                    setStepfiv(false);
                                                                                }}
                                                                            >
                                                                                Back
                                                                            </button>
                                                                        </div>
                                                                        <div className="lg:px-4 pb-2">
                                                                            <button
                                                                                type="submit"
                                                                                className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center"
                                                                                style={{ padding: "2" }}
                                                                            >
                                                                                Continue
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </>
                                                        ) : null}
                                                        {stepsix ? (
                                                            <>
                                                                <form onSubmit={handleSubmit(onSubmitSix)}>
                                                                    <div className="relative w-full text-xl ">
                                                                        <label
                                                                            className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                                                            htmlFor="grid-password"
                                                                        >
                                                                            When would you like the consultant to
                                                                            start?
                                                                        </label>
                                                                        <input
                                                                            {...register("start", { required: true })}
                                                                            type="radio"
                                                                            value="As soon as possible"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            As soon as possible
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("start", { required: true })}
                                                                            type="radio"
                                                                            value="Within the next month"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            Within the next month
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("start", { required: true })}
                                                                            type="radio"
                                                                            value="Within the next 3 months"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            Within the next 3 months
                                                                        </label>
                                                                        <br />

                                                                        <input
                                                                            {...register("start", { required: true })}
                                                                            type="radio"
                                                                            value="Within the next 6 months"
                                                                        />
                                                                        <label className="text-blueGray-600 pl-2">
                                                                            Within the next 6 months
                                                                        </label>
                                                                        <br />
                                                                        <br />
                                                                        {errors.start?.type === "required" && (
                                                                            <span
                                                                                role="alert"
                                                                                className="text-red-500 pt-4"
                                                                            >
                                                                                Please select your option
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <br />
                                                                    <div className="w-full flex flex-col md:flex-row px-4 text-center">
                                                                        <div className="lg:px-4 pb-2">
                                                                            <button
                                                                                className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center"
                                                                                style={{ padding: "2" }}
                                                                                onClick={() => {
                                                                                    setStepfiv(true);
                                                                                    setStepsix(false);
                                                                                }}
                                                                            >
                                                                                Back
                                                                            </button>
                                                                        </div>
                                                                        <div className="lg:px-4 pb-2">
                                                                            <button
                                                                                type="submit"
                                                                                className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center"
                                                                                style={{ padding: "2" }}
                                                                            >
                                                                                Continue
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </>
                                                        ) : null}
                                                        {stepsev ? (
                                                            <>
                                                                <form onSubmit={handleSubmit(onSubmitSeven)}>
                                                                    <div className="relative w-full text-xl ">
                                                                        <label
                                                                            className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                                                            htmlFor="grid-password"
                                                                        >
                                                                            What email address would you like quotes
                                                                            sent to?
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            {...register("email", {
                                                                                required: {
                                                                                    value: "required",
                                                                                    message: "Please enter your email id",
                                                                                },
                                                                                pattern: {
                                                                                    value:
                                                                                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                                    message:
                                                                                        "Please enter valid email id",
                                                                                },
                                                                            })}
                                                                            className="border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                                            placeholder="Email"
                                                                        />
                                                                        <br />
                                                                        <br />
                                                                        {errors.email && (
                                                                            <span
                                                                                role="alert"
                                                                                className="text-red-500 pt-4"
                                                                            >
                                                                                {errors.email.message}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <br />
                                                                    <div className="w-full flex flex-col md:flex-row px-4 text-center">
                                                                        <div className="lg:px-4 pb-2">
                                                                            <button
                                                                                className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center"
                                                                                style={{ padding: "2" }}
                                                                                onClick={() => {
                                                                                    setStepsix(true);
                                                                                    setStepsev(false);
                                                                                }}
                                                                            >
                                                                                Back
                                                                            </button>
                                                                        </div>
                                                                        <div className="lg:px-4 pb-2">
                                                                            <button
                                                                                type="submit"
                                                                                className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center"
                                                                                style={{ padding: "2" }}
                                                                            >
                                                                                Get Quote
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </>
                                                        ) : null}
                                                        {success ? (
                                                            <p className="text-center text-green-600 px-4 md:px-16">
                                                                <p className="text-4xl">
                                                                    <i class="fas fa-check-circle"></i>
                                                                </p>
                                                                We appreciate you contacting iBusiness
                                                                Consulting.
                                                                <br />
                                                                One of our colleagues will get back in touch
                                                                with you soon!
                                                                <br />
                                                                Have a great day!
                                                            </p>
                                                        ) : null}
                                                        {error ? (
                                                            <p className="text-center text-red-500 px-20">
                                                                <p className="text-4xl">
                                                                    <i
                                                                        class="fa fa-times-circle"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </p>
                                                                Oops! Something went wrong. Please try again.
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                                        </Popup>*/}
                                    <a
                                        href="/contact"
                                        className="get-started whitespace-nowrap text-black font-bold px-8 py-3 rounded-full outline-none focus:outline-none mr-2 mb-2 bg-transparent border  active:bg-lightBlue-600 uppercase text-base shadow hover:shadow-lg ease-linear transition-all duration-150"
                                    >
                                        Contact Us
                                    </a>
                                </div>

                            </div>

                        </div>
                        <div className="lg:w-5/12 md:w-12/12 lg:pt-12 md:pt-0 pt-2 lg:pl-24">
                            <img
                                alt="..."
                                className="w-full"
                                src={require("assets/img/imgs/employeeimg.png").default}
                            />
                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </>
    );
}