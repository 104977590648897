import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";


export default function Terms() {
    return (
        <>
            <IndexNavbar fixed />
            <section className="container lg:px-32 md:pt-52 pt-24">
                <h1 className="font-bold ttt text-center lg:text-4xl md:text-3xl text-2xl">
                    Terms Of Use
                </h1>
                <div className="md:px-4 px-2 lg:pl-0 md:pl-10">
                    <p className="md:py-2 py-2 lg:pt-12 lg:text-xl md:text-2xl text-xl font-bold ttt text-black">
                        Overview
                    </p>
                    <p className="md:py-2 py-2 md:text-lg ttt">
                        The following are the terms of an agreement between you and IBC. By accessing, or using this
                        Web site, you acknowledge that you have read, understand, and agree to be bound by these terms
                        and to comply with all applicable laws and regulations, including export and re-export control
                        laws and regulations. If you do not agree to these terms, please do not use this Web site.
                    </p>
                    <p className="md:py-2 py-2 md:text-lg ttt">
                        IBC may, without notice to you, at any time, revise these Terms of Use and any other
                        information contained in this Web site. IBC may also make improvements or changes in the
                        products, services, or programs described in this site at any time without notice.
                    </p>
                    <p className="md:py-2 py-2 lg:text-xl md:text-2xl text-xl font-bold ttt text-black">
                        General
                    </p>
                    <p className="md:py-2 py-2 md:text-lg ttt">
                        This Web site contains proprietary notices and copyright information, the terms of
                        which must be observed and followed. Please see the tab entitled “Copyright and
                        trademark information” for related information.
                    </p>
                    <p className="md:py-2 py-2 md:text-lg ttt">
                        IBC grants you a non-exclusive, non-transferable, limited permission to access and
                        display the Web pages within this site as a customer or potential customer of IBC provided
                        you comply with these Terms of Use, and all copyright, trademark, and other proprietary notices
                        remain intact. You may only use a crawler to crawl this Web site as permitted by this Web site’s
                        robots.txt protocol, and IBC may block any crawlers in its sole discretion. The use authorized under
                        this agreement is non-commercial in nature (e.g., you may not sell the content you access on or through
                        this Web site.) All other use of this site is prohibited.
                    </p>

                    <p className="md:py-2 py-2 md:text-lg ttt">
                        Except for the limited permission in the preceding paragraph, IBC does not grant
                        you any express or implied rights or licenses under any patents, trademarks, copyrights,
                        or other proprietary or intellectual property rights. You may not mirror any of the content
                        from this site on another Web site or in any other media. Any software and other materials that
                        are made available for downloading, access, or other use from this site with their own license terms
                        will be governed by such terms, conditions, and notices. Your failure to comply with such terms or any
                        of the terms on this site will result in automatic termination of any rights granted to you, without prior
                        notice, and you must immediately destroy all copies of downloaded materials in your possession, custody or
                        control.
                    </p>
                    <p className="md:py-2 py-2 lg:text-xl md:text-2xl text-xl font-bold ttt text-black">
                        Disclaimer
                    </p>
                    <p className="md:py-2 py-2 md:text-lg ttt">
                        From time to time, this Web site may contain technical inaccuracies or typographical
                        errors, and we do not warrant the accuracy of any posted information. Please confirm you
                        are using the most up-to-date pages on this Web site, and confirm the accuracy and completeness
                        of information before using it to make decisions relating to services, products, or other matters
                        described in this Web site.
                    </p>
                    <p className="md:py-2 py-2 md:text-lg ttt md:pb-32 pb-12">
                        If any term in this Terms of Use is found by competent judicial authority to be unenforceable
                        in any respect, the validity of the remainder of this Terms of Use will be unaffected, provided
                        that such unenforceability does not materially affect the parties’ rights under this Terms of Use.
                    </p>
                </div>
            </section>
            <Footer />
        </>
    )
}