import React, { useState, useEffect } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";

export default function Test() {

}
/*
export default function Test() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async data => {
    console.log("First", data);
  //  console.warn(data.email, data.password);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div class="flex">
          <div class="flex-1 w-64 py-40">
            <h6 className="text-blueGray-500 block uppercase  text-center pb-4 text-xl font-bold">
              Sign up with credentials
            </h6>
            <h6 className="text-blueGray-500 text-center text-sm font-bold">
              Account information (fields marked with * are required)
            </h6>

            <div className="px-32">
              <hr className="mt-2 border-b-1  border-blueGray-300" />
            </div>
            <label htmlFor="email">email</label>
            <input
              id="email"
              {...register("email", {
                required: "required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format"
                }
              })}
              type="email"
            />
            {errors.email && <span role="alert">{errors.email.message}</span>}
          </div>
          <div class="flex-1 w-32 px-12 pt-12 bg-neutral-100">
            <label htmlFor="password">password</label>
            <input
              id="password"
              {...register("password", {
                required: {
                  value: "required",
                  message: "Password is required"
                },
                minLength: {
                  value: 5,
                  message: "min length is 5"
                }
              })}
              type="password"
            />
            {errors.password && <span role="alert">{errors.password.message}</span>}
          </div>
        </div>
        <Button type="submit">Submit</Button>
      </Form>
    </>
  );
}
*/
/*
export default function Test() {
  const { register, resetField, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    resetField("firstName");
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
          <label>First Name</label>
          <input
            placeholder='First Name'
            type="text"
            {...register("firstName")}
          />
        </Form.Field>
        {errors.firstName && <p>Please check the First Name</p>}
        <Form.Field>
          <label>Last Name</label>
          <input
            placeholder='Last Name'
            type="text"
            {...register("lastName")}
          />
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <input
            placeholder='Email'
            type="email"
            {...register("email")}
          />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <input
            placeholder='Password'
            type="password"
            {...register("password")}
          />
        </Form.Field>
        <Button type='submit'>Submit</Button>
      </Form>
    </div>
  )
}
*/
/*
export default function Test() {
  const {
    register,
    resetField,
    formState: { isDirty, isValid }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: ""
    }
  });
  const handleClick = () => resetField("firstName");

  return (
    <form>
      <input type="text" {...register("firstName", { required: true })} />

      <p>{isDirty && "dirty"}</p>
      <p>{isValid && "valid"}</p>

      <button type="button" onClick={handleClick}>
        Reset
      </button>
    </form>
  );
}
*/

/*
export default function Test() {
    const { register, handleSubmit, reset, formState: { errors  } } = useForm();
    const onSubmit = (data) => {
      console.log(data);
    }
    // user state for form
    const [user, setUser] = React.useState(null);

    // effect runs on component mount
    React.useEffect(() => {
        // simulate async api call with set timeout
        setTimeout(() => setUser({ email: '', firstName: '', lastName: '' }), 1000);
    }, []);

    // effect runs when user state is updated
    React.useEffect(() => {
        // reset form with user data
        reset(user);
    }, [user]);
    
    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Field>
                    <label>First Name</label>
                    <input
                        placeholder='First Name'
                        type="text"
                        {...register("firstName")}
                    />
                </Form.Field>
                {errors.firstName && <p>Please check the First Name</p>}
                <Form.Field>
                    <label>Last Name</label>
                    <input
                        placeholder='Last Name'
                        type="text"
                        {...register("lastName")}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Email</label>
                    <input
                        placeholder='Email'
                        type="email"
                        {...register("email")}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Password</label>
                    <input
                        placeholder='Password'
                        type="password"
                        {...register("password")}
                    />
                </Form.Field>
                <Button type='submit'>Submit</Button>
                <button type="button" onClick={() => reset()} className="btn btn-secondary">Reset</button>
            </Form>
        </div>
    )
}
*/

/*
export default function Test() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {
      console.log(data);
    }

    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Field>
                    <label>First Name</label>
                    <input
                        placeholder='First Name'
                        type="text"
                        {...register("firstName")}
                    />
                </Form.Field>
                {errors.firstName && <p>Please check the First Name</p>}
                <Form.Field>
                    <label>Last Name</label>
                    <input
                        placeholder='Last Name'
                        type="text"
                        {...register("lastName")}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Email</label>
                    <input
                        placeholder='Email'
                        type="email"
                        {...register("email")}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Password</label>
                    <input
                        placeholder='Password'
                        type="password"
                        {...register("password")}
                    />
                </Form.Field>
                <Button type='submit'>Submit</Button>
            </Form>
        </div>
    )
}
*/

/*
export default function Test() {
  const { register, handleSubmit, handleSubmit: handleSubmitriz, formState: { errors, error }, reset } = useForm();
  const onSubmit = async data => {
    console.log("First",data);
    console.warn(data.email, data.password);
  };

  const onSub = data => console.log("Second",data,data.name);

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="email">email</label>
      <input
        id="email"
        {...register("email", {
          required: "required",
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format"
          }
        })}
        type="email"
      />
      {errors.email && <span role="alert">{errors.email.message}</span>}
      <label htmlFor="password">password</label>
      <input
        id="password"
        {...register("password", {
          required: {
            value: "required",
            message: "Password is required R"
          },
          minLength: {
            value: 5,
            message: "min length is 5"
          }
        })}
        type="password"
      />
      {errors.password && <span role="alert">{errors.password.message}</span>}
      <button type="submit">SUBMIT</button>
    </form>
    <br /><hr /><br />
    <form onSubmit={handleSubmitriz(onSub)}>
      <label htmlFor="name">Name</label>
      <input type="text" id="name" {...register('name', { required: true, maxLength: 30 })} />
  {/*    {errors.name && errors.name.type === "required" && <span>Name is required</span>}
      {errors.name && errors.name.type === "maxLength" && <span>Max length exceeded</span> } */
/*    <button type="submit">SUBMIT</button>
  </form>
  </>
);
}
*/
/*
export default function Test() {
  const { register, handleSubmit, reset, formState: { isSubmitSuccessful } } = useForm({ defaultValues: { something: "anything" } });

  const onSubmit = (data) => {
    console.log(data);
    // with 7.22.0 you can reset here without useEffect
    // reset({ ...data })
  };

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ something: '' });
    }
  }, [formState, submittedData, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input {...register("something")} />
      <input type="submit" />
    </form>
  );
}
*/
/*
<select name="country"
                              {...register('country')}
                              className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Country"
                              >
                              <option value="" disabled selected hidden>Select country</option>
                              <option value="Afganistan">Afghanistan</option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="American Samoa">American Samoa</option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Aruba">Aruba</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bonaire">Bonaire</option>
                              <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                              <option value="Botswana">Botswana</option>
                              <option value="Brazil">Brazil</option>
                              <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                              <option value="Brunei">Brunei</option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Canary Islands">Canary Islands</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">Cayman Islands</option>
                              <option value="Central African Republic">Central African Republic</option>
                              <option value="Chad">Chad</option>
                              <option value="Channel Islands">Channel Islands</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Christmas Island">Christmas Island</option>
                              <option value="Cocos Island">Cocos Island</option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo">Congo</option>
                              <option value="Cook Islands">Cook Islands</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Cote DIvoire">Cote DIvoire</option>
                              <option value="Croatia">Croatia</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Curaco">Curacao</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">Czech Republic</option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">Dominican Republic</option>
                              <option value="East Timor">East Timor</option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">Equatorial Guinea</option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Falkland Islands">Falkland Islands</option>
                              <option value="Faroe Islands">Faroe Islands</option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="French Guiana">French Guiana</option>
                              <option value="French Polynesia">French Polynesia</option>
                              <option value="French Southern Ter">French Southern Ter</option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Gibraltar">Gibraltar</option>
                              <option value="Great Britain">Great Britain</option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Hawaii">Hawaii</option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="India">India</option>
                              <option value="Iran">Iran</option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Isle of Man">Isle of Man</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Korea North">Korea North</option>
                              <option value="Korea Sout">Korea South</option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Laos">Laos</option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libya">Libya</option>
                              <option value="Liechtenstein">Liechtenstein</option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macau">Macau</option>
                              <option value="Macedonia">Macedonia</option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">Marshall Islands</option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mayotte">Mayotte</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Midway Islands">Midway Islands</option>
                              <option value="Moldova">Moldova</option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Nambia">Nambia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherland Antilles">Netherland Antilles</option>
                              <option value="Netherlands">Netherlands (Holland, Europe)</option>
                              <option value="Nevis">Nevis</option>
                              <option value="New Caledonia">New Caledonia</option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Niue">Niue</option>
                              <option value="Norfolk Island">Norfolk Island</option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau Island">Palau Island</option>
                              <option value="Palestine">Palestine</option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">Papua New Guinea</option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Phillipines">Philippines</option>
                              <option value="Pitcairn Island">Pitcairn Island</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Republic of Montenegro">Republic of Montenegro</option>
                              <option value="Republic of Serbia">Republic of Serbia</option>
                              <option value="Reunion">Reunion</option>
                              <option value="Romania">Romania</option>
                              <option value="Russia">Russia</option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="St Barthelemy">St Barthelemy</option>
                              <option value="St Eustatius">St Eustatius</option>
                              <option value="St Helena">St Helena</option>
                              <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                              <option value="St Lucia">St Lucia</option>
                              <option value="St Maarten">St Maarten</option>
                              <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                              <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                              <option value="Saipan">Saipan</option>
                              <option value="Samoa">Samoa</option>
                              <option value="Samoa American">Samoa American</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Slovakia">Slovakia</option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">Solomon Islands</option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syria">Syria</option>
                              <option value="Tahiti">Tahiti</option>
                              <option value="Taiwan">Taiwan</option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania">Tanzania</option>
                              <option value="Thailand">Thailand</option>
                              <option value="Togo">Togo</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="United Kingdom">United Kingdom</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Erimates">United Arab Emirates</option>
                              <option value="United States of America">United States of America</option>
                              <option value="Uraguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Vatican City State">Vatican City State</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Vietnam">Vietnam</option>
                              <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                              <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                              <option value="Wake Island">Wake Island</option>
                              <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                              <option value="Yemen">Yemen</option>
                              <option value="Zaire">Zaire</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                            </select>

<Form onSubmit={handleSubmit(onSubmit)}>
    <div class="flex">
        <div class="flex-1 w-64 py-40">
            <h6 className="text-blueGray-500 block uppercase  text-center pb-4 text-xl font-bold">
                Sign up with credentials
            </h6>
            <h6 className="text-blueGray-500 text-center text-sm font-bold">
                Account information (fields marked with * are required)
            </h6>

            <div className="px-32">
                <hr className="mt-2 border-b-1  border-blueGray-300" />
            </div>
            <p className="font-bold text-red-500 text-center">{err}</p>
            <div className="container mx-auto px-4 h-full">
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-12/12 px-4">
                        <div className="flex-auto px-4 lg:px-10  pt-0">
                            <Form.Field>
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Email*
                                    </label>
                                    <input
                                        type="email" {...register("email")}
                                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Email"
                                    />
                                </div>
                            </Form.Field>
                            <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.email?.message}</div>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-6/12 pr-2">
                                    <Form.Field>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Password*
                                            </label>
                                            <input
                                                type="password" {...register('password')}
                                                className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Password"
                                            />
                                        </div>
                                    </Form.Field>
                                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.password?.message}</div>
                                </div>
                                <div className="w-full lg:w-6/12 pl-3">
                                    <Form.Field>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Confirm Password*
                                            </label>
                                            <input name="confpassword"
                                                type="password" {...register('confpassword')}
                                                className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Confirm Password"
                                            />
                                        </div>
                                    </Form.Field>
                                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.confpassword?.message}</div>
                                </div>
                            </div>
                            <div className="text-left mt-3 mb-6">
                                <h6 className="text-blueGray-500 text-sm font-bold">
                                    Client Information (fields marked with * are required)
                                </h6>
                            </div>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-6/12 pr-2 ">
                                    <Form.Field>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                First Name*
                                            </label>
                                            <input name="fname"
                                                type="text" {...register('fname')}
                                                className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="First Name"
                                            />
                                        </div>
                                    </Form.Field>
                                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.fname?.message}</div>
                                </div>
                                <div className="w-full lg:w-6/12 pl-3">
                                    <Form.Field>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Last Name*
                                            </label>
                                            <input name="lname"
                                                type="text" {...register('lname')}
                                                className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Last Name"
                                            />
                                        </div>
                                    </Form.Field>
                                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.lname?.message}</div>
                                </div>
                            </div>
                            <Form.Field>
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Street Address*
                                    </label>
                                    <input name="street"
                                        type="text" {...register('street')}
                                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Street Address"
                                    />
                                </div>
                            </Form.Field>
                            <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.street?.message}</div>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-6/12 pr-2">
                                    <Form.Field>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                City*
                                            </label>
                                            <input name="city"
                                                type="text" {...register('city')}
                                                className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="City"
                                            />
                                        </div>
                                    </Form.Field>
                                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.city?.message}</div>
                                </div>
                                <div className="w-full lg:w-6/12 pl-3">
                                    <Form.Field>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                ZIP Code*
                                            </label>
                                            <input name="zipcode"
                                                type="text" {...register('zipcode')}
                                                className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="ZIP Code"
                                            />
                                        </div>
                                    </Form.Field>
                                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.zipcode?.message}</div>
                                </div>
                            </div>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-6/12 pr-2">
                                    <Form.Field>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Country*
                                            </label>
                                            <input name="country"
                                                type="text" {...register('country')}
                                                className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Country"
                                            />
                                        </div>
                                    </Form.Field>
                                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.country?.message}</div>
                                </div>
                                <div className="w-full lg:w-6/12 pl-3">
                                    <Form.Field>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                State*
                                            </label>
                                            <input name="state"
                                                type="text" {...register('state')}
                                                className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="State"
                                            />
                                        </div>
                                    </Form.Field>
                                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.state?.message}</div>
                                </div></div>
                            <Form.Field>
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Company
                                    </label>
                                    <input name="company"
                                        type="text" {...register('company')}
                                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Company"
                                    />
                                </div>
                            </Form.Field>
                            <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.company?.message}</div>

                            <Form.Field>
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Mobile number*
                                    </label>
                                    <input name="mobile"
                                        type="text" {...register('mobile')}
                                        className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Mobile number"
                                    />
                                </div>
                            </Form.Field>
                            <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.mobile?.message}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-1 w-32 px-12 pt-12 bg-neutral-100">
            <div className="container mx-auto  py-40 px-4 h-full ">
                <div className="w-full lg:w-12/12 px-4">

                    <div className="text-left mb-3">
                        <h6 className="text-blueGray-500 text-sm font-bold">
                            Purchase Information
                        </h6>
                    </div>
                    <Form.Field>
                        <div className="relative w-full mt-3  mb-6">
                            <ul>
                                <div className="  flex break-words transition ease-out duration-150" style={{
                                    padding: "1%"
                                }}>
                                    {items.map(item => (
                                        <li key={item.id}>
                                            <div className=" flex  border bg-white transition ease-out duration-150 hover:bg-lightBlue-200 rounded-lg">
                                                <label>
                                                    <div className="block uppercase text-blueGray-600 text-sm font-bold mb-2 px-4 py-6">
                                                        <input type="radio" value={item.month} name="duration"  {...register('duration')}
                                                            className={`form-control ${errors.duration ? 'is-invalid' : ''}`}
                                                            onClick={() => { setAmount(Number(price) * Number(item.month)); setMon(item.month); }}
                                                            onChange={changeTitle}
                                                        />&nbsp;
                                                        Choose {item.name} membership
                                                    </div>
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </div>
                            </ul>
                        </div>
                    </Form.Field>
                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.duration?.message}</div>

                    <Form.Field>
                        <div className="relative w-full mb-3">
                            {amt ?
                                <input name="amount" Value={amount}
                                    type="text" {...register('amount')}
                                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Amount"
                                />
                                :
                                <input name="amount" Value="0"
                                    type="text" {...register('amount')}
                                    className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Amount"
                                />
                            }
                        </div>
                    </Form.Field>
                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.amount?.message}</div>
                    <Form.Field>
                        <div className="relative w-full mb-3">
                            <input name="purchased" value={plan_name}
                                type="hidden" {...register('purchased')}
                                className="border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Purchased"
                            />
                        </div>
                    </Form.Field>
                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.purchased?.message}</div>


                    <div className="text-left mb-6">
                        <h6 className="text-blueGray-500 text-sm font-bold">
                            Payment Information
                        </h6>

                        CHOOSE YOUR PAYMENT METHOD
                    </div>


                    <Form.Field>
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-6/12 pl-3">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-sm font-bold mb-2">
                                        <input type="radio" value="credit" name="payment" {...register('payment')}
                                            className={`form-control ${errors.payment ? 'is-invalid' : ''}`} />&nbsp;
                                        Credit Card&nbsp;&nbsp;
                                    </label>
                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 pl-3">
                                <div className="relative w-full mb-3">

                                    <label
                                        className="block uppercase text-blueGray-600 text-sm font-bold mb-2">
                                        <input type="radio" value="bank" name="payment" {...register('payment')}
                                            className={`form-control ${errors.payment ? 'is-invalid' : ''}`} />&nbsp;
                                        Bank Payment&nbsp;&nbsp;
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Form.Field>
                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.payment?.message}</div>

                    <div className="text-left mt-3 mb-3">
                        <h6 className="text-blueGray-500 text-sm font-bold">
                            Coupon Code
                        </h6>
                    </div>

                    <Form.Field>
                        <div className="relative w-full mb-3">
                            <label
                                className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                Coupon Code*
                            </label>

                            <div className="text-center flex justify-between">
                                <input name="coupon_name"
                                    type="text" {...register('coupon_name')}
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Coupon Code"
                                />
                                <button
                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                    type="button" onClick={discount}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                    </Form.Field>
                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.coupon_name?.message}</div>

                    <div className="text-left pt-4  mb-6">
                        <h6 className="text-blueGray-500 text-sm font-bold">
                            Review Order Details
                        </h6>

                    </div>

                    <div className="relative w-full border-2 mb-3">
                        <table class="border-collapse w-full">
                            <tr>
                                <th className="text-left  py-2 px-6 border border-slate-400">Plan</th>
                                <td className=" border border-slate-400 text-right px-6">{plan_name}</td>
                            </tr>
                            <tr>
                                <th className="text-left py-2 px-6 border border-slate-400">Duration</th>
                                <td className="border border-slate-400 text-right px-6">{mon}</td>
                            </tr>
                            <tr>
                                <th className="text-left py-2 px-6 border border-slate-400">SUBTOTAL</th>
                                <td className="border border-slate-400 font-bold text-xl text-right px-6 ">{amount}</td>
                            </tr>
                            <tr>
                                <th className="text-left py-2 px-6 border border-slate-400">TOTAL DUE TODAY</th>
                                <td className="text-right border font-bold text-xl border-slate-400 px-6">{amount}</td>
                            </tr>
                        </table>
                    </div>

                    <Form.Field>
                        <div>
                            <label className="inline-flex items-center cursor-pointer">
                                <input
                                    id="customCheckLogin" name="terms" {...register('terms')}
                                    type="checkbox"
                                    className="form-checkbox border-2 mt-4 shadow-lg rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                />
                                <span className="ml-2 mt-4 text-sm font-semibold text-blueGray-600">
                                    I agree with the{" "}
                                    <a
                                        href="#pablo"
                                        className="text-lightBlue-500"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Privacy Policy
                                    </a>
                                </span>
                            </label>
                        </div>
                    </Form.Field>
                    <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.terms?.message}</div>

                    <div className="text-center mt-6">
                        <Button className="ui secondary button bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type='submit'>Submit</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</Form>
*/