import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import Aboutbg3 from "assets/img/imgs/Aboutbg3.png";
import Aboutbg1 from "assets/img/imgs/Aboutbg1.png";
import { HashLink } from "react-router-hash-link";
import Popup from "reactjs-popup";


export default function About() {
  function showPopup() {
    document.getElementById("popup_container").style.display = "block";
  }
  function hidePopup() {
    document.getElementById("popup_container").style.display = "none";
  }
  return (
    <>
      <IndexNavbar fixed />
      <section className="bgabout ">
        <div className="container mx-auto relative w-full lg:w-12/12 md:w-12/12 md:pt-32 pt-32 lg:px-12 pb-6 md:block hidden">
          <div className=" md:pb-32 bordr1 justify-center bgabout2" >
            <div className="lg:w-12/12 md:w-12/12 md:pr-4 md:pt-6 pt-6 lg:px-52  px-4">
              <h1 className="font-bold  md:text-4xl text-4xl text-left md:pt-6 ttt ">
                About Us
              </h1>
              <h2 className="md:text-lg text-lg text-left ttt pt-4 text-justify">
                iBusiness Consulting L.L.C –FZ directly linked to our Canadian branch has many years of experience and
                international markets, working with a wide variety of countries and organizations to align entrepreneurs
                and help them achieve their goals in a fast-paced digital world.

                Whether you are an established organization or a start-up, we help you on your successful
                business journey with our excellent support and strong commitment. We help you make business
                decisions on all fronts.
              </h2>
              <h2 className="md:text-lg text-lg text-left ttt pt-4 text-justify">
                Our main goal is to give you all the tools and guides to conquer your
                passion for business. This can be a challenge, especially when it comes to maxing out the power
                boost.

                The iBC team of subject matter experts provides consulting services across UAE and
                internationally at every stage of your requirements, whether in the early stages or on the road
                from success to development.

                Helping organizations and working on the solutions they need expertly designed Partnering with
                law firms and accountants in our journey to manage their business with the confidence and
                energy to reach their goals We provide the knowledge, expertise, and <span id="company">tools for in the modern
                  business world.</span>
              </h2>
            </div>
          </div>
        </div>
      </section>


      {/* Mobile View */}

      <section className="w-full lg:w-12/12 md:w-12/12 md:pt-32 pt-24    pb-6 md:hidden block">
        <div className=" md:pb-32 bordr1 justify-center bg-contain bg-no-repeat" style={{ backgroundImage: `url(${Aboutbg1})` }}>
          <div className="lg:w-12/12 md:w-12/12 md:pr-4 md:pt-6 pt-6 lg:px-52  ">
            <h1 className="font-bold  md:text-4xl text-4xl text-left md:pt-6 ttt px-6">
              About Us
            </h1>
            <h2 className="md:text-lg text-lg text-left ttt pt-4 px-4 pb-4 text-justify">
              iBusiness Consulting L.L.C –FZ directly linked to our Canadian branch has many years of experience and
              international markets, working with a wide variety of countries and organizations to align entrepreneurs
              and help them achieve their goals in a fast-paced digital world.

              Whether you are an established organization or a start-up, we help you on your successful
              business journey with our excellent support and strong commitment. We help you make business
              decisions on all fronts.
            </h2>
            <div className="pt-12 bgabout pb-6">

              <h2 className="md:text-lg text-lg text-left ttt px-4 pb-4 text-justify">

                Our main goal is to give you all the tools and guides to conquer your
                passion for business.

                This can be a challenge, especially when it comes to maxing out the power
                boost.

                The iBC team of subject matter experts provides consulting services across UAE and
                internationally at every stage of your requirements, whether in the early stages or on the road
                from success to development.
                <div className="pt-4">
                  Helping organizations and working on the solutions they need expertly designed Partnering with
                  law firms and accountants in our journey to manage their business.

                  With the confidence and
                  energy to reach their goals We provide the knowledge, expertise, and <span id="company">tools for in the modern
                    business world.</span>
                </div>
              </h2>
            </div>
          </div>
        </div>
      </section>




      {/* Desktop View*/}
      <section className="container mx-auto lg:px-32 md:-mt-28 mt-4 md:block hidden">
        <div className="flex items-center justify-center text-center lg:px-32 ">

          <div id="vision" className="lg:w-2/12 md:w-3/12  md:py-14">
            <a href="#popup1">
              <div className="text-center items-center justify-center bordr1 border bg-blueWhale-500 py-8 rounded-tl-2xl rounded-bl-2xl">
                <img
                  alt="..."
                  className=" p-2 w-18"
                  src={require("assets/img/imgs/vision.png").default}
                />
                <h1 className="text-white text-center font-base ttt pt-1">
                  Our Vision
                </h1>
              </div>
            </a>
            <div id="popup1" class="overlay ">
              <div class="popup">
                <a class="close" href="#vision">×</a>
                <div class="content ">
                  <h1 className="text-black text-left  font-bold text-2xl ttt pt-1">
                    Our Vision
                  </h1>
                  <p className="text-lg pt-2 pb-2 ttt text-justify text-black">
                    Our professionalism and core values, our skills and what are best for our customer’s iBC vision
                    is to always be there for our customers,to grow and to realize
                    <span class="text-justify text-black">
                      innovative solutions for our future customers. It's about planning a creative company that is passionate about what you do. Designed and delivered by
                      the digital world.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-2/12 md:w-3/12 ">
            <div id="mision" className="text-center items-center justify-center bordr1 border bg-blueWhale-500 py-8">
              <a href="#popup2">
                <img
                  alt="..."
                  className=" p-2 w-18"
                  src={require("assets/img/imgs/mission.png").default}
                />
                <h1 className="text-white text-center font-base ttt pt-1">
                  Our Mission
                </h1>
              </a>
            </div>
          </div>
          <div id="popup2" class="overlay">
            <div class="popup">
              <a class="close" href="#mision">×</a>
              <div class="content text-black">
                <h1 className="text-black text-left  font-bold text-2xl ttt pt-1">
                  Our Mision
                </h1>
                <p className="text-lg pt-2 pb-2 ttt text-justify ">
                  We are strives to serve its clients by providing oriented and practical development services in the following areas:
                  <ul className="list-disc px-6">
                    <li>
                      Corporate Services
                    </li>
                    <li >
                      HR Services
                    </li>
                    <li>
                      IT Services
                    </li>
                    <li >
                      Intellectual property services
                    </li>
                  </ul>
                  We always think long-term for the benefit of our customers.
                </p>
              </div>
            </div>
          </div>
          <div id="value" className="lg:w-2/12 md:w-3/12 " >
            <a href="#popup3">
              <div className="text-center items-center justify-center bordr1 border bg-blueWhale-500 py-8 rounded-tr-2xl rounded-br-2xl">
                <img
                  alt="..."
                  className=" p-2 w-18"
                  src={require("assets/img/imgs/values.png").default}
                />
                <h1 className="text-white text-center font-base ttt pt-1">
                  Our Values
                </h1>
              </div>
            </a>
          </div>
        </div>
      </section>



      {/* Mobile View */}

      <section className="container mx-auto md:px-32 md:-mt-28 mt-4 md:hidden block w-full">
        <div className="flex items-center justify-center text-center md:px-32 px-4">

          <div className="w-6/12  md:py-14">
            <Popup
              trigger={
                <div className="text-center p-16 items-center justify-center bordr1 border bg-blueWhale-500 py-5 rounded-2xl rounded-br-2xl">
                  <img
                    alt="..."
                    className=" p-2 w-18"
                    src={require("assets/img/imgs/vision.png").default}
                  />
                  <a href="#popup1">
                    <h1 className="text-white text-center font-base ttt  pb-1">
                      Vision
                    </h1>
                  </a> </div>}
              modal
              nested
            >
              {close => (
                <div className=" w-full">
                  <div className="modal rounded-2xl bg-white shadow ">
                    <div className="close" onClick={close}>
                      &times;
                    </div>
                    <div className="header text-sm text-blue-800"> OUR VISION</div>
                    <div className="content">
                      {' '}
                      <p className="text-lg pt-2 pb-2 ttt text-justify text-black">
                        Our professionalism and core values, our skills and what are best for our customer’s iBC vision
                        is to always be there for our customers,to grow and to realize
                        <span class="text-justify text-black">
                          innovative solutions for our future customers. It's about planning a creative company that is passionate about what you do. Designed and delivered by
                          the digital world.
                        </span>
                      </p>
                    </div>

                  </div>
                </div>
              )}
            </Popup>
          </div>
        </div>
      </section>

      <div className="flex px-4 md:hidden block">
        <div className="w-6/12 ">
          <Popup
            trigger={
              <div className="text-center p-16 items-center justify-center bordr1 border bg-blueWhale-500 py-5 rounded-tl-2xl rounded-bl-2xl">
                <img
                  alt="..."
                  className=" p-2 w-18"
                  src={require("assets/img/imgs/mission.png").default}
                />
                <a href="#popup1">
                  <h1 className="text-white text-center font-base ttt  pb-1">
                    Mision
                  </h1>
                </a> </div>}
            modal
            nested
          >
            {close => (
              <div className=" w-full">
                <div className="modal rounded-2xl rounded-bl-2xl bg-white shadow ">
                  <div className="close" onClick={close}>
                    &times;
                  </div>
                  <div className="header text-sm text-blue-800"> OUR MISION</div>
                  <div className="content">
                    {' '}
                    <div class="content text-black">

                      <p className="text-lg pt-2 pb-2 ttt text-justify ">
                        We are strives to serve its clients by providing oriented and practical development services in the following areas:
                        <ul className="list-disc px-6">
                          <li>
                            Corporate Services
                          </li>
                          <li >
                            HR Services
                          </li>
                          <li>
                            IT Services
                          </li>
                          <li >
                            Intellectual property services
                          </li>
                        </ul>
                        We always think long-term for the benefit of our customers.
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            )}
          </Popup>
        </div>


        <div id="value" className="w-6/12 ">
          <Popup
            trigger={
              <div className="text-center p-16 items-center justify-center bordr1 border bg-blueWhale-500 py-5 rounded-tr-2xl rounded-br-2xl">
                <img
                  alt="..."
                  className=" p-2 w-18"
                  src={require("assets/img/imgs/values.png").default}
                />
                <a href="#popup1">
                  <h1 className="text-white text-center font-base ttt  pb-1">
                    Values
                  </h1>
                </a> </div>}
            modal
            nested
          >
            {close => (
              <div className=" w-full">
                <div className="modal rounded-2xl rounded-bl-2xl bg-white shadow ">
                  <div className="close" onClick={close}>
                    &times;
                  </div>
                  <div className="header text-sm text-blue-800"> OUR VALUES</div>
                  <div className="content">
                    {' '}
                    <div class="content text-black">
                      <p className="text-lg pt-2 pb-2 ttt text-justify ">
                        Our success is a direct result of our employees. The work of all employees is guided by the following
                        values: We hold our clients accountable to each otherand operate in a truly service-focused manner based
                        on mutual respect and trust. We put the long-term well being of our customers above our own interests and
                        conduct our business ethically with humility, empathy and fairness.We exceed our customers' expectations,
                        do what we say and do it exceptionally well. Each of us will be a steward of her CIB reputation to maintain
                        and improve it. We maintain a stimulating, diverse and inclusive work environment focused on client success
                        that encourages camaraderie, creativity, accountability and overall cohesion. We believe in professionalism.
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            )}
          </Popup>
        </div>
      </div>


      {/* OUR COMPANY */}
      {/*   <section className="">
        <div className="container mx-auto md:px-12 md:pb-24 pb-6">
          <div className="w-full lg:w-12/12 md:w-12/12 md:px-6">
            <h1 className="font-extrabold md:text-4xl text-4xl text-center md:px-24 px-4 md:pb-2 md:pt-6">
              Our Company
            </h1>
            <div className="flex flex-wrap md:pt-12 pt-6 md:px-24 ttt">
              <div className="lg:w-6/12 md:w-6/12 bg md:px-4 ">
                <h1 className="font-bold md:text-2xl text-xl text-left text-white md:block hidden md:pt-12 pl-4">
                  How did we become experts?
                </h1>
                <h1 className="font-bold md:text-2xl text-xl text-left text-blue md:hidden block pl-4 pt-6 ">
                  How did we become experts?
                </h1>
                <div className="px-4">
                  <p className="text-xl text-left pt-4 text-white">
                    The iBusiness consulting firm as a specialized business consultant that provides management consulting
                    to improve the performance and efficiency of organizations.
                  </p>
                  <p className="text-xl text-left pt-4 md:pb-12 text-white pb-6">
                    As a professional advisor, we help companies achieve their goals and streamline their operations
                    in specific business areas such as: management, IT, marketing, human resources and operations,
                    In addition, we provided individual support services to entrepreneurs.
                  </p>
                </div>
              </div>

              <div className="lg:w-6/12 md:w-6/12 px-4 md:pl-10 bg-neutral-100">
                <h1 className="font-bold md:text-2xl text-left text-blue md:block hidden md:pt-12">
                  What makes us a high-level consulting firm?
                </h1>
                <h1 className="font-bold md:text-2xl text-xl text-left text-blue md:hidden block  pt-6">
                  What makes us a high-level consulting firm?
                </h1>
                <p className="text-xl text-left pt-4">
                  IBC's team of subject matter expert’s work with law firms and accountants to provide the organizational knowledge,
                  expertise, confidence and energy to achieve your business goals today's business world.
                </p>
              </div>
            </div>
          </div>
        </div> 
  </section> */}


      {/*Desktop View */}
      <section className="container mx-auto md:pb-12 lg:px-32 md:block hidden">
        <div className="w-full lg:px-32 md:pt-6 pt-6">
          <div className="flex flex-wrap">
            <div className="lg:w-1/12 md:w-1/12">
              <img
                alt="..."
                className="p-2 w-24 md:block hidden"
                src={require("assets/img/icertificate02.png").default}
              />
            </div>
            <div className="lg:w-10/12 md:w-10/12">
              <h1 className="md:text-3xl text-xl text-left text-black md:block hidden pl-4">
                How did we become <span className="font-extrabold">experts?</span>
              </h1>
              <h1 className="font-bold md:text-2xl text-xl text-left text-blue md:hidden block pl-4 pt-2 ">
                How did we become experts?
              </h1>
              <div className="px-4">
                <p className="text-lg text-left pt-4 text-black ttt text-justify">
                  The iBusiness consulting firm as a specialized business consultant that provides management consulting
                  to improve the performance and efficiency of organizations.

                  As a professional advisor, we help companies achieve their goals and streamline their operations
                  in specific business areas such as: management, IT, marketing, human resources and operations,
                  In addition, we provided individual support services to entrepreneurs.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:px-32  md:pt-12 lg:pl-4">
          <div className="flex flex-wrap">
            <div className="lg:w-1/12 md:w-1/12">
              <img
                alt="..."
                className=" p-2 w-24 md:block hidden"
                src={require("assets/img/ifinance02.png").default}
              />
            </div>
            <div className="lg:w-10/12 md:w-10/12">
              <h1 className="md:text-3xl text-left text-black md:block hidden pl-4">
                What makes us a <span className="font-extrabold">high-level consulting firm?</span>
              </h1>
              <h1 className="font-bold md:text-lg text-xl text-left bgtext md:hidden block  pt-6">
                What makes us a high-level consulting firm?
              </h1>
              <p className="text-lg text-left pt-4 ttt text-black md:pl-4 md:pb-12 text-justify pr-4 pb-8">
                iBC's team of subject matter expert’s work with law firms and accountants to provide the organizational knowledge,
                expertise, confidence and energy to achieve your business goals today's business world.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Mobile View */}
      <section className="container mx-auto md:pb-12 md:px-32 md:hidden block mt-12">
        <div className="w-full md:px-32 md:pt-6 pt-6">
          <div className="flex flex-wrap">
            <div className="lg:w-1/12 md:w-1/12 ">
              <img
                alt="..."
                className="p-2 w-24 md:block hidden"
                src={require("assets/img/icertificate02.png").default}
              />
            </div>
            <div className="lg:w-10/12 md:w-10/12  bg-white shadow pt-4 pb-4 mx-2 border-t-4 rounded border-lightBlue-500">
              <div className="flex px-4 border-b">
                <img
                  alt="..."
                  className="p-2 w-16"
                  src={require("assets/img/icertificate02.png").default}
                />
                <h1 className="font-bold md:text-2xl text-xl text-left text-blue md:hidden block  pt-4 ">
                  How did we become experts?
                </h1>
              </div>
              <div className="px-4 pt-4">
                <p className="text-lg text-left text-black ttt text-justify">
                  The iBusiness consulting firm as a specialized business consultant that provides management consulting
                  to improve the performance and efficiency of organizations.
                </p>
                <p className="text-lg text-left text-black ttt pt-2 text-justify">
                  As a professional advisor, we help companies achieve their goals and streamline their operations
                  in specific business areas such as: management, IT, marketing, human resources and operations,
                </p>
                <p className="text-lg text-left text-black ttt pt-2 text-justify">
                  In addition, we provided individual support services to entrepreneurs.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="md:px-32  md:pt-12  mt-6 mb-12 bg-white shadow  pb-4 mx-2 border-t-4 rounded border-lightBlue-500">
          <div className="flex flex-wrap ">
            <div className="lg:w-1/12 md:w-1/12">
              <img
                alt="..."
                className=" p-2 w-24 md:block hidden"
                src={require("assets/img/ifinance02.png").default}
              />
            </div>
            <div className="lg:w-10/12 md:w-10/12">
              <div className="flex px-4 pt-2 border-b-2">
                <img
                  alt="..."
                  className="p-2 w-16"
                  src={require("assets/img/ifinance02.png").default}
                />
                <h1 className="font-bold md:text-lg text-xl text-left bgtext md:hidden block  pt-6">
                  What makes us a high-level firm?
                </h1>
              </div>
              <p className="text-lg text-left pt-2 ttt text-black md:pl-4 md:pb-12 text-justify px-4 pb-8">
                iBC's team of subject matter expert’s work with law firms and accountants to provide the organizational knowledge,
                expertise, confidence and energy to achieve your business goals today's business world.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*<section className="bg-neutral-100">
        <div className="container mx-auto  md:pt-28 md:pt-12   pt-6">
          <div className="w-full lg:w-12/12 md:w-12/12  md:pt-6 lg:px-32">
            <div className="row">
              <div className="md:w-4/12 lg:w-4/12  px-4 md:py-0 py-4" >
                <div className="pricing_inner white_bg pt-24 md:pb-6 pb-6 ">
                  <div className="md:px-6 md:-mt-16 -mt-16 px-4 md:pb-24 box" id="vision">
                    <h1 className="md:text-2xl text-xl font-extrabold  text-blue" >
                      Our Vision
                    </h1>
                    <p className="text-lg pt-2 pb-2 ttt text-justify">
                      Our professionalism and core values, our skills and what are best for our customer’s iBC vision
                      is to always be there for our customers,to grow and to realize
                    </p>
                    <div className="mt-6">
                      <a class="button rounded-lg px-2 py-2 ttt text-xl bg-lightBlue-400" href="#popup1">
                        Show More</a>
                    </div>
                  </div>
                  <div>
                    <div id="popup1" class="overlay">
                      <div class="popup">
                        <a class="close" href="#vision">×</a>
                        <div class="content">
                          <p className="text-lg pt-2 pb-2 ttt text-justify">
                            Our professionalism and core values, our skills and what are best for our customer’s iBC vision
                            is to always be there for our customers,to grow and to realize
                            <span class="text-justify">
                              innovative solutions for our future customers. It's about planning a creative company that is passionate about what you do. Designed and delivered by
                              the digital world.
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="md:w-4/12 lg:w-4/12  px-4 md:py-0 py-4" >
                <div className="pricing_inner white_bg pt-24 md:pb-6 pb-6 ">
                  <div className="md:px-6 md:-mt-16 -mt-16 px-4 md:pb-24 box" id="mision">
                    <h1 className="md:text-2xl text-xl font-extrabold  text-blue" >
                      Our Mision
                    </h1>
                    <p className="text-lg pt-2 pb-2 ttt text-justify">
                      We are strives to serve its clients by providing oriented and practical development services in the following areas:
                      <ul className="list-disc px-6">
                        <li>
                          Corporate Services
                        </li>
                      </ul>
                    </p>
                    <div className="mt-6">
                      <a class="button rounded-lg px-2 py-2 ttt text-xl bg-lightBlue-400" href="#popup2">
                        Show More</a>
                    </div>
                  </div>
                  <div>
                    <div id="popup2" class="overlay">
                      <div class="popup">
                        <a class="close" href="#mision">×</a>
                        <div class="content">
                          <p className="text-lg pt-2 pb-2 ttt text-justify">
                            We are strives to serve its clients by providing oriented and practical development services in the following areas:
                            <ul className="list-disc px-6">
                              <li>
                                Corporate Services
                              </li>
                              <li >
                                HR Services
                              </li>
                              <li >
                                IT Services
                              </li>
                              <li >
                                Intellectual property services
                              </li>
                            </ul>
                            We always think long-term for the benefit of our customers.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="md:w-4/12 lg:w-4/12  px-4 md:py-0 py-4" >
                <div className="pricing_inner white_bg pt-24 md:pb-6 pb-6 ">
                  <div className="md:px-6 md:-mt-16 -mt-16 px-4 md:pb-24 box" id="value">
                    <h1 className="md:text-2xl text-xl font-extrabold  text-blue" >
                      Our Value
                    </h1>
                    <p className="text-lg pt-2 pb-2 ttt text-justify">
                      Our success is a direct result of our employees. The work of all employees is guided by the following values:
                      We hold our clients accountable to each other
                    </p>
                    <div className="mt-6">
                      <a class="button  rounded-lg px-2 py-2 ttt text-xl bg-lightBlue-400" href="#popup3">
                        Show More</a>
                    </div>
                  </div>
                  <div>
                    <div id="popup3" class="overlay">
                      <div class="popup">
                        <a class="close" href="#value">×</a>
                        <div class="content">
                          <h1 className="text-black text-left  font-bold text-2xl ttt pt-1">
                  Our Value
                </h1>
                          <p className="text-lg pt-2 pb-2 ttt text-justify">
                            Our success is a direct result of our employees. The work of all employees is guided by the following values:
                            We hold our clients accountable to each other
                            <span class="text-justify">
                              and operate in a truly service-focused manner based on mutual respect and trust. We put the long-term
                              well being of our customers above our own interests and conduct our business ethically with humility,
                              empathy and fairness.We exceed our customers' expectations, do what we say and do it exceptionally well.
                              Each of us will be a steward of her CIB reputation to maintain and improve it. We maintain a stimulating,
                              diverse and inclusive work environment focused on client success that encourages camaraderie, creativity,
                              accountability and overall cohesion. We believe in professionalism.
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>*/}
      {/* Mission Vision */}

      <Footer />
    </>
  )
}