import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import smoothScrollPolyfill from 'smoothscroll-polyfill';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/style.css";
import "assets/styles/style.scss";
import "assets/styles/normal.css";


// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Edit from "views/Edit.js";
import Index from "views/Index.js";
import About from "components/About.js";
import Founder from "components/Founder.js";
import Ourcompany from "components/Ourcompany.js";
import Plan from "components/Plan.js";
import Contact from "views/Contact.js";
import Services from "views/Services.js";
import Corporation from "views/Corporation.js";
import Startup from "views/subservice/Startup";
import Advisory from "views/subservice/Advisory.js";
import Hrservices from "views/Hrservices.js";
import Hrmanage from "views/subservice/Hrmanage.js";
import Candidate from "views/subservice/Candidate.js";
import Employee from "views/subservice/Employee.js";
import Itservices from "views/Itservices.js";
import Webdesign from "views/subservice/Webdesign.js";
import Webdevelop from "views/subservice/Webdevelop.js";
import Mobileapp from "views/subservice/Mobileapp";
import Digital from "views/subservice/Digital";
import Intellect from "views/subservice/Intellect.js";
import Patend from "views/subservice/Patend.js";
import Trademark from "views/subservice/Trademark.js";
import Marks from "views/subservice/Marks.js";
import Franchise from "views/Franchise.js";
import Dropdown from "components/Dropdowns/Dropdown.js";
import Test from "views/Test.js";
import Invoice from "views/Invoice.js"
import Strategic from "components/Dropdowns/Strategic.js";
import Inter from "components/Dropdowns/Inter.js";
import Techno from "components/Dropdowns/Techno.js";
import Market from "components/Dropdowns/Market.js";
import Operation from "components/Dropdowns/Operation.js";
import Certificate from "components/Dropdowns/Certificate.js";
import Finance from "components/Dropdowns/Finance.js";
import Commerce from "components/Dropdowns/Commerce.js";
import Home2 from "views/Home2";
import Registerss from "views/auth/Registerss";
import Privacy from "views/subservice/Privacy.js";
import Terms from "views/subservice/Terms.js";
import Plans from "components/Plans.js";
import Wizard from "components/Wizard.js";
import Corporate from "components/Datas/Corporate.js";
import Hrserve from "components/Datas/Hrserve.js";
import Itserve from "components/Datas/Itserve.js";
import Ipserv from "components/Datas/Ipserv.js";
import Ipserve from "components/Datas/Ipserve.js";
import Business from "components/Datas/Business.js";
import Signin from "components/Datas/Signin.js";




smoothScrollPolyfill.polyfill();

//import Protected from "./Protected.js"

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/profile" component={Admin} />
      <Route path="/auth" component={Auth} />
      {/* add routes without layouts */}
      <Route path="/" exact component={Index} />
      <Route path="/landing" exact component={Landing} />
      <Route path="/profiles" exact component={Profile} />
      <Route path="/edit/:id" exact component={Edit} />
      <Route path="/home" component={Home2} />
      <Route path="/about" component={About} />
      <Route path="/founder" component={Founder} />
      <Route path="/company" component={Ourcompany} />
      <Route path="/plan" component={Plan} />
      <Route path="/contact" component={Contact} />
      <Route path="/services" component={Services} />
      <Route path="/corporation" component={Corporation} />
      <Route path="/startup" component={Startup} />
      <Route path="/advisory" component={Advisory} />
      <Route path="/hrservices" component={Hrservices} />
      <Route path="/candidate" component={Candidate} />
      <Route path="/employee" component={Employee} />
      <Route path="/itservices" component={Itservices} />
      <Route path="/webdesign" component={Webdesign} />
      <Route path="/webdevelop" component={Webdevelop} />
      <Route path="/mobileapp" component={Mobileapp} />
      <Route path="/digital" component={Digital} />
      <Route path="/intellect" component={Intellect} />
      <Route path="/patend" component={Patend} />
      <Route path="/trademark" component={Trademark} />
      <Route path="/marks" component={Marks} />
      <Route path="/franchise" component={Franchise} />
      <Route path="/dropdown" component={Dropdown} />
      <Route path="/test" component={Test} />
      <Route path="/strategic" component={Strategic} />
      <Route path="/inter" component={Inter} />
      <Route path="/hrmanage" component={Hrmanage} />
      <Route path="/techno" component={Techno} />
      <Route path="/market" component={Market} />
      <Route path="/operation" component={Operation} />
      <Route path="/certificate" component={Certificate} />
      <Route path="/finance" component={Finance} />
      <Route path="/commerce" component={Commerce} />
      <Route path="/invoice" component={Invoice} />
      <Route path="/registerss" component={Registerss} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/terms" component={Terms} />
      <Route path="/plans" component={Plans} />
      <Route path="/wizard" component={Wizard} />
      <Route path="/corporate" component={Corporate} />
      <Route path="/hrserv" component={Hrserve} />
      <Route path="/itserv" component={Itserve} />
      <Route path="/ipserv" component={Ipserv} />
      <Route path="/ipserve" component={Ipserve} />
      <Route path="/business" component={Business} />
      <Route path="/sign-in" component={Signin} />

      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
