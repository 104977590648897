import React from "react";
import { slide as Menu } from "react-burger-menu";
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";

export default (props) => {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    const [dropOpen, setdropOpen] = React.useState(false);
    const [dropdownOpen, setdropdownOpen] = React.useState(false);
    const [downOpen, setdownOpen] = React.useState(false);
    const [downsOpen, setdownsOpen] = React.useState(false);
    return (
        // Pass on our props
        <Menu {...props}>
            <ul>
                <div className="border-b">
                    <li className="flex items-center pl-4 relative lg:pb-0 pb-2">
                        <Link
                            to="/services"
                            className=" px-1 lg:py-2 flex items-center text-white text-base font-bold">
                            Services
                        </Link>
                    </li>
                </div>
                <div className="border-b lg:py-0 py-6 ">
                    <li className=" items-center relative lg:pb-0 pb-4 pl-2">
                        <button className=" flex cursor-pointer lg:static lg:text-lg pl-3 lg:py-1 text-3xl text-white absolute  lg:border-0 rounded outline-none focus:outline-none"
                            type="button"
                            onClick={() => setdropdownOpen(!dropdownOpen)}>
                            <div className="lg:py-2 flex items-center text-base font-bold">
                                Coporation Services
                            </div>
                            <i class="fas fa-caret-down lg:pt-2 lg:pb-0 pb-6 px-4 text-lg" ></i>
                        </button>
                        <div
                            className={
                                " flex lg:w-auto  " +
                                (dropdownOpen ? " block " : " hidden")
                            }
                        >
                            <button className="fixed inset-0 cursor-default"
                                onClick={(e) => {
                                    setdropdownOpen(false); setNavbarOpen(false);
                                }}>

                            </button>

                            <ul className="absolute right-0 left-0 lg:top-14 md:top-8 top-12  lg:mt-0   z-50 lg:relative  lg:rounded-md flex flex-col list-none"
                                onClick={(e) => {
                                    setdropdownOpen(false); setNavbarOpen(false);
                                }}>

                                <li className="flex items-center border-b py-1 w-full">
                                    <HashLink smooth to={'/startup'}
                                        className="hover:text-white text-white lg:py-1 pl-10 md:pl-10 py-2 flex items-center text-base   bg-transparent"
                                    >
                                        Startup Services
                                    </HashLink>
                                </li>
                                <li className="flex items-center border-b py-2">
                                    <HashLink smooth to={'/advisory'}
                                        className="hover:text-white text-white lg:py-1 pl-10 md:pl-10 py-2 flex items-center text-base   bg-transparent"
                                    >
                                        Business Advisory Services
                                    </HashLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                </div>

                <div className="border-b lg:py-0 py-6">
                    <div
                        className={
                            " lg:w-auto  " +
                            (dropdownOpen ? " lg:pt-26 md:pt-24 pt-30" : " pt-0")
                        }
                    >
                        <li className=" items-center relative lg:pb-0 pb-4 pl-3 ">
                            <button className="flex cursor-pointer lg:static lg:text-lg pl-3 lg:py-1 text-3xl text-white absolute  lg:border-0 rounded outline-none focus:outline-none"
                                type="button"
                                onClick={() => setdropOpen(!dropOpen)}>
                                <div className="lg:py-2 flex items-center text-base font-bold ">
                                    HR Services
                                </div>
                                <i class="fas fa-caret-down lg:pt-2 px-2 text-lg" ></i>
                            </button>
                            <div
                                className={
                                    " flex lg:w-auto  " +
                                    (dropOpen ? " block " : " hidden")
                                }
                            >
                                <button className="fixed inset-0 cursor-default"
                                    onClick={(e) => {
                                        setdropOpen(false); setNavbarOpen(false);
                                    }}>

                                </button>

                                <ul className="absolute right-0 left-0 lg:top-14 md:top-8 top-12 lg:mt-0 z-50 lg:relative  lg:rounded-md flex flex-col list-none"
                                    onClick={(e) => {
                                        setdropOpen(false); setNavbarOpen(false);
                                    }}>

                                    <li className="flex items-center border-b py-1 pl-10 md:pl-10">
                                        <HashLink smooth to={'/Hrmanage'}
                                            className="hover:text-white text-white lg:py-1 py-2 flex items-center text-base bg-transparent"
                                        >
                                            HR Management
                                        </HashLink>
                                    </li>
                                    <li className="flex items-center border-b py-1 pl-10 md:pl-10">
                                        <HashLink smooth to={'/employee'}
                                            className="hover:text-white text-white lg:py-1 py-2 flex items-center text-base bg-transparent"
                                        >
                                            Employee
                                        </HashLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </div>
                </div>

                <div className="border-b lg:py-0 py-6">
                    <div
                        className={
                            " lg:w-auto  " +
                            (dropOpen ? " lg:pt-24 md:pt-20 pt-28 " : " pt-0")
                        }
                    >
                        <li className=" items-center relative lg:pb-0 pb-4">
                            <button className="flex cursor-pointer lg:static lg:text-lg pl-4 lg:py-1 text-3xl text-white absolute  lg:border-0 rounded outline-none focus:outline-none"
                                type="button"
                                onClick={() => setdownOpen(!downOpen)}>
                                <div className=" px-2 lg:py-2 flex items-center text-base font-bold">
                                    IT Services
                                </div>
                                <i class="fas fa-caret-down lg:pt-2 px-2 text-lg" ></i>
                            </button>
                            <div
                                className={
                                    " flex lg:w-auto  " +
                                    (downOpen ? " block " : " hidden")
                                }
                            >
                                <button className="fixed inset-0 cursor-default"
                                    onClick={(e) => {
                                        setdownOpen(false); setNavbarOpen(false);
                                    }}>

                                </button>

                                <ul className=" absolute right-0 left-0 lg:top-14 md:top-8 top-12 lg:mt-0 z-50 lg:relative  lg:rounded-md flex flex-col list-none"
                                    onClick={(e) => {
                                        setdownOpen(false); setNavbarOpen(false);
                                    }}>

                                    <li className="flex items-center border-b py-1 pl-10 md:pl-10">
                                        <HashLink smooth to={'/webdesign'}
                                            className="hover:text-white text-white lg:py-1 py-2 flex items-center text-base bg-transparent"
                                        >Web Design
                                        </HashLink>
                                    </li>
                                    <li className="flex items-center border-b  py-1 pl-10 md:pl-10">
                                        <HashLink smooth to={'/webdevelop'}
                                            className="hover:text-white text-white lg:py-1 py-2 flex items-center text-base bg-transparent"
                                        >
                                            Web Development
                                        </HashLink>
                                    </li>
                                    <li className="flex items-center border-b py-1 pl-10 md:pl-10">
                                        <HashLink smooth to={'/mobileapp'}
                                            className="hover:text-white text-white lg:py-1 py-2 flex items-center text-base bg-transparent"
                                        >
                                            App Development
                                        </HashLink>
                                    </li>
                                    <li className="flex items-center border-b py-1 pl-10 md:pl-10">
                                        <HashLink smooth to={'/digital'}
                                            className="hover:text-white text-white lg:py-1 py-2 flex items-center text-base bg-transparent"
                                        >
                                            Digital Marketing
                                        </HashLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </div>
                </div>

                <div className="">
                    <div
                        className={
                            " flex lg:w-auto  " +
                            (downOpen ? " lg:pt-44 md:pt-48 pt-52" : " pt-0")
                        }
                    >
                        <li className="flex items-center relative pl-4 pt-2">
                            <Link
                                to="/intellect"
                                className=" px-2 py-4 lg:py-2 pl-3 flex items-center text-base font-bold">
                                IP Services
                            </Link>
                        </li>
                    </div>
                </div>

            </ul>
        </Menu>
    );
};
