import React from "react";

// components

import CardRenew from "components/Cards/CardRenew.js";

export default function Renew() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-full px-4">
          <CardRenew />
        </div>
      </div>
    </>
  )
}