import React, { Component } from "react";
import { Link } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import 'font-awesome/css/font-awesome.min.css';
import Spinner from "react-spinkit";
import Popup from "reactjs-popup";
import Plans from "components/Plans.js";
import Wizard from "components/Wizard.js";


export default class Plan extends Component {
  // Constructor 
  constructor(props) {
    super(props);

    // Set initial state 
    this.state = {
      items: [],
      planserviceitems: [],
      DataisLoaded: false
    };

  }

  // ComponentDidMount is used to execute the code 
  componentDidMount() {
    fetch("https://ibusinessconsulting.ae/admin/Plansreact")
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          items: json,
          DataisLoaded: true,
        });
      })

    fetch("https://ibusinessconsulting.ae/admin/PlansServicesreact")
      .then((result) => result.json())
      .then((json) => {
        this.setState({
          planserviceitems: json,
          DataisLoaded: true,
        });
      })
  }

  render() {

    const { DataisLoaded, items, planserviceitems } = this.state;
    if (!DataisLoaded) return <div class="flex h-screen justify-center items-center">
      <div class="text-center bg-blue-400">
        <Spinner name="chasing-dots" />
      </div>
    </div>;
    return (

      <>
        <IndexNavbar fixed />
       {/* <section className="container mx-auto w-full pb-6 pt-36">
          <div className=" px-4 min-h-screen flex flex-wrap items-center justify-center content-center ">
            <div className=" ">
              <h2 className="font-bold pb-4 text-4xl text-blueGray-600 ">
                Select Your Plan
              </h2>
            </div>


            {/* <section className="items-center ">
          <div className="pt-10 mt-10 pb-10">
            <h3 className="font-bold text-4xl text-center text-black hover:text-blueGray-500 ">
              <div class="font-sans">
              Select your Plan
              </div>
            </h3>
          </div>
        </section>

            <div>
              <div className="mt-2 pb-8 md:pb-48 px-8 md:px-52 lg:px-40 flex lg:flex-row flex-col justify-center">
                {

                  items.map((item) => (

                    <div className="hover:-mt-4 flex break-words  transition ease-out duration-150" style={{
                      padding: "1%"
                    }}>
                      <div className="w-96 text-black bg-white hover:text-blueGray-800 hover:bg-lightBlue-200 border-b-6 border-lightBlue-500  rounded-2xl shadow-xl ">
                        <ul key={item.id} >
                          <div className="mt-6 mb-4 rounded-lg cursor-pointer ">
                            <h6 className="text-3xl px-6 font-bold text-center">{item.plan_name}</h6>
                            <h2 className="text-l mt-4 break-words font-semibold text-center">{item.about}</h2>
                          </div>
                          <div classname="py-20 ">
                            <div className="mt-6 flex flex-start justify-center lg:pb-12">
                              <p className="text-l font-normal">د.إ</p>
                              <p className="text-4xl font-bold">{item.price}</p>
                              <p className="text-3xl font-light"> AED</p>
                            </div>
                          </div>
                        </ul>
                        <div className="h-300-px overflow-y-scroll border-t-2 border-dotted">
                          <div className="px-2 py-6 text-base font-semibold cursor-pointer  z-50" > {item.services.split(',').map(str => <p><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }}></i>{str}</p>)}
                          </div>
                          {/*<div className=" border-b-2 border-t-2 py-2">
                            <h1 className="lg:px-8 px-4 pt-1 text-3xl px-6 font-bold text-center ttt">
                              Website Design
                            </h1>
                          </div>
                          <div className=" py-2 px-1">
                            <p className="lg:px-2 lg:py-1 text-lg font-semibold cursor-pointer  z-50"><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }} />
                              Logo Business card design
                            </p>
                            <p className="lg:px-2 lg:py-1 text-lg font-semibold cursor-pointer  z-50"><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }} />
                              3 Pages 100% Custom Website Design
                            </p>
                            <p className="lg:px-2 lg:py-1 text-lg font-semibold cursor-pointer  z-50"><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }} />
                              Complete Web Development
                            </p>
                            <p className="lg:px-2 lg:py-1 text-lg font-semibold cursor-pointer  z-50"><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }} />
                              Unlimited Revisions
                            </p>
                            <p className="lg:px-2 lg:py-1 text-lg font-semibold cursor-pointer  z-50"><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }} />
                              3 Royalty-Free Stock Images
                            </p>
                            <p className="lg:px-2 lg:py-1 text-lg font-semibold cursor-pointer  z-50"><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }} />
                              1 jQuery slider banner
                            </p>
                            <p className="lg:px-2 lg:py-1 text-lg font-semibold cursor-pointer  z-50"><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }} />
                              Lead Capturing Form
                            </p>
                            <p className="lg:px-2 lg:py-1 text-lg font-semibold cursor-pointer  z-50"><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }} />
                              Complete W3C Certified HTML
                            </p>
                            <p className="lg:px-2 lg:py-1 text-lg font-semibold cursor-pointer  z-50"><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }} />
                              Complete Deployment
                            </p>
                            <p className="lg:px-2 lg:py-1 text-lg font-semibold cursor-pointer  z-50"><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }} />
                              100% Ownership Rights
                            </p>
                            <p className="lg:px-2 lg:py-1 text-lg font-semibold cursor-pointer  z-50"><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }} />
                              100% Satisfaction Guarantee
                            </p>
                            <p className="lg:px-2 lg:py-1 text-lg font-semibold cursor-pointer  z-50"><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }} />
                              100% Unique Design Guarantee
                            </p>
                            <p className="lg:px-2 lg:py-1 text-lg font-semibold cursor-pointer  z-50"><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }} />
                              Hosting for one year
                            </p>
                          </div>
                        </div>
                        <div className="px-10 py-8 flex justify-center">
                          <Link to={{
                            pathname: "/auth/register",
                            state: {
                              plan_name: item.plan_name,
                              price: item.price
                            }
                          }}
                            className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center" style={{ padding: "2" }}>
                            Buy Plan
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                }
                <div >
                  <Plans />

                </div>

              </div>
            </div>
          </div>
        </section>*/}


        {/* <section className="mt-4 flex flex-row bg-neutral-100 py-20 justify-center">
          <div className="py-6 min-w-0 break-words hidden lg:block">
            <table className="table border border-blueGray-300">
              <thead className="border-b  text-black bg-lightBlue-200 sticky z-50 top-24">
                <tr>
                  <th scope="col" className="pr-72 pl-4 py-8 text-left text-2xl text-black font-bold  whitespace-nowrap ">Plan Features</th>
                  {
                    items.map((item) => (

                      <th scope="col" className="px-12 py-6 text-center text-2xl font-bold hover:text-blueGray-500  whitespace-nowrap " key={item.id}>
                        {item.plan_name}
                        <div className="mt-6 flex flex-start">
                          <p className="text-sm font-normal">$</p>
                          <p className="text-3xl font-bold">{item.price}</p>
                          <p className="text-2xl font-light" >/Month</p>
                        </div></th>
                    ))
                  }

                </tr>
              </thead>
              <tbody >

                {
                  planserviceitems.map((service) => (
                    <tr className="bg-white  py-12  hover:bg-lightBlue-200 " key={items.id}>

                      <th scope="row" className="pl-4 text-left text-lg font-bold text-blueGray-800 hover:text-blueGray-500 whitespace-nowrap "> {service.services} </th>
                      <td className="text-center"> {(service.Affiliate === "1") ? <i className="fa fa-solid fa-check px-6 py-4" style={{ color: "green" }}></i> : <i className="fa fa-solid fa-times px-6 py-4" style={{ color: "red" }}></i>} </td>
                      <td className="text-center"> {(service.Primary === "1") ? <i className="fa fa-solid fa-check px-6 py-4" style={{ color: "green" }}></i> : <i className="fa fa-solid fa-times px-6 py-4" style={{ color: "red" }}></i>} </td>
                      <td className="text-center"> {(service.Associate === "1") ? <i className="fa fa-solid fa-check px-6 py-4" style={{ color: "green" }}></i> : <i className="fa fa-solid fa-times px-6 py-4" style={{ color: "red" }}></i>} </td>

                    </tr>
                  ))
                }

              </tbody>
            </table>
          </div>

          <div className="py-6 md:px-52 break-words flex w-full lg:hidden">
            <div className="overflow-scroll relative ">
              <table className="table border border-blueGray-300">
                <thead className="border-b bg-white text-black hover:bg-lightBlue-200">
                  <tr>
                    <th scope="col" className="sticky z-2 left-0 bg-lightBlue-200 py-8 text-left text-2xl font-bold hover:text-blueGray-500  whitespace-nowrap ">Plan Features</th>
                    {
                      items.map((item) => (

                        <th scope="col" className="static px-8 py-8 text-center text-2xl font-bold hover:text-blueGray-500  whitespace-nowrap " key={item.id}>
                          {item.plan_name}
                          <div className="mt-6 flex flex-start">
                            <p className="text-sm font-normal">$</p>
                            <p className="text-3xl font-bold">{item.price}</p>
                            <p className="text-2xl font-light" >/Month</p>
                          </div></th>
                      ))
                    }

                  </tr>
                </thead>
                <tbody >

                  {
                    planserviceitems.map((service) => (
                      <tr className="bg-white pl-10 py-12  hover:bg-lightBlue-200 " key={items.id}>

                        <th scope="row" className="sticky z-2 left-0 bg-lightBlue-200 text-left text-lg font-bold text-blueGray-800 hover:text-blueGray-500  "> {service.services}</th>
                        <td className="static text-center  "> {(service.Affiliate === "1") ? <i className="fa fa-solid fa-check px-6 py-4" style={{ color: "green" }}></i> : <i className="fa fa-solid fa-times px-6 py-4" style={{ color: "red" }}></i>} </td>
                        <td className="static text-center  "> {(service.Primary === "1") ? <i className="fa fa-solid fa-check px-6 py-4" style={{ color: "green" }}></i> : <i className="fa fa-solid fa-times px-6 py-4" style={{ color: "red" }}></i>} </td>
                        <td className="static text-center  "> {(service.Associate === "1") ? <i className="fa fa-solid fa-check px-6 py-4" style={{ color: "green" }}></i> : <i className="fa fa-solid fa-times px-6 py-4" style={{ color: "red" }}></i>} </td>

                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
                </section> */}
             <Wizard/>
        <Footer />
      </>
    );
  }
}