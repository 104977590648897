import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import { useForm } from "react-hook-form";
import ibccontact from "assets/img/ibccontact.gif";
import axios from "axios";

export default function Contact() {

  const { register, resetField, handleSubmit, formState: { errors } } = useForm();
  const [err, setErr] = useState("")
  const [suc, setSuc] = useState("")

  const onSubmit = (data) => {
    console.log(data);
    axios.post("https://customer.ibusinessconsulting.ae/admin/public/index.php/api/addContact",{data})
    .then((response) => {
      console.log(response.data);
      if(response.data.status){
        setSuc("Message has been sent successfully. Our representative will contact you shortly.");
        resetField("name");
        resetField("email");
        resetField("mobile");
        resetField("message");
      }
      else{
        setErr(response.data.message);
      }
    });
  }

  return (
    <>
      <IndexNavbar fixed />
      <section className=" flex items-center  md:pt-20 " >
        <div className="w-full flex flex-row  flex-wrap items-center justify-center content-center ">
          <div className="w-full lg:w-6/12 px-8 ">
            <div className=" lg:px-20  md:pb-20 md:pt-20 pt-32">
              {/*<h2 className="font-bold text-center ttt text-3xl md:text-5xl text-blueWhale"><br></br>
              Contact Us
            </h2>*/}
              <div className="justify-center flex flex-wrap relative md:mt-14">
                <div className="w-full lg:px-0 md:px-24">
                  <h1 className="ttt font-bold text-center text-4xl">
                    Contact Us
                  </h1>
                  <div className="flex-auto px-2 lg:px-10 py-10 mt-4 pt-0">
                    <p className="font-bold text-green-600 text-center">{suc}</p>
                    <p className="font-bold text-red-500 text-center">{err}</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="relative w-full py-3 ">
                        <input
                          type="text" {...register("name",
                            {
                              required: {
                                value: "required",
                                message: "Please enter your Name"
                              }
                            })}
                          className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Name"
                        />
                        {errors.name && <p className="font-bold text-red-500">{errors.name.message}</p>}
                      </div>

                      <div className="relative w-full py-3">
                        <input
                          type="email" {...register("email",
                            {
                              required: {
                                value: "required",
                                message: "Please enter Email id"
                              },
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Please enter a valid email id"
                              }
                            })}
                          className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Email"
                        />
                        {errors.email && <p className="font-bold text-red-500">{errors.email.message}</p>}
                      </div>

                      <div className="relative w-full py-3">
                        <input
                          type="text" {...register("contact",
                            {
                              required: {
                                value: "required",
                                message: "Please enter Contact Number"
                              },
                              pattern: {
                                value: /^[6-9]\d{9}$/,
                                message: "Please enter a valid Contact Number"
                              }
                            })}
                          className="border-1 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600  rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Contact Number"
                        />
                        {errors.contact && <p className="font-bold text-red-500">{errors.contact.message}</p>}
                      </div>

                      <div className="relative w-full py-3">

                        <input
                          type="text" {...register("message",
                            {
                              required: {
                                value: "required",
                                message: "Please enter Message"
                              }
                            })}
                          className="border-1  mt-2 px-3 py-10 placeholder-blueGray-300 text-blueGray-600  rounded-lg text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Message"
                        />
                        {errors.message && <p className="font-bold text-red-500">{errors.message.message}</p>}
                      </div>

                      <div className="text-center mt-6">
                        <button
                          className="bg-lightBlue-500 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-6/12  md:w-12/12 h-450-px lg:h-700-px md:h-600-px px-8  bg-scroll bg-cover bg-no-repeat bg-white " style={{ backgroundImage: `url(${ibccontact})` }}>
            <div className="flex flex-col flex-wrap text-center items-center justify-center content-center ">
              <h2 className="font-bold pt-32 ttt text-3xl md:text-5xl text-blueWhale"><br></br>

                The Fastest way<br></br>
                to Contact Us

              </h2>
              <h2 className="text-blueWhale ttt text-xl py-2">
                you’re already well-established organization or
                startup we help you make decisions about your business every way.</h2>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}