import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import { useForm } from "react-hook-form";
import { Form, Button } from 'semantic-ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Spinner from "react-spinkit";

export default function Maps() {
  const { id } = useParams();
  const [err, setErr] = useState("")
  const [isActive, setActive] = useState(false);
  const [isActivep, setActivep] = useState(false);
  const [isActivecp, setActivecp] = useState(false);

  // Add method
  Yup.addMethod(Yup.string, 'stripEmptyString', function () {
    return this.transform((value) => (value === '' ? undefined : value));
  });
  // Usage
  const formSchema = Yup.object().shape({
    old: Yup.string()
      .required('Old Password is mandatory'),
    new: Yup.string()
      .required('New Password is mandatory')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirm: Yup.string()
      .required('Confirm Password is mandatory')
      .oneOf([Yup.ref('new')], 'New Password & Confirm Password does not match'),
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors } = formState

  const onSubmit = (data) => {
    console.log(JSON.stringify(data, null, 4))
    updatePassword(data)
    return false
  }

  async function updatePassword(data) {
    //  console.warn(data)
    let result = await fetch(`https://ibusinessconsulting.ae/admin/customerreact/password/${id}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
    result = await result.json()
    //  console.warn("Result", result)
    if (result.status === 400) {
      setErr(result.messages.error);
    }
    else {
      setErr("Updated successfully");
    }
  }

  function myFunction() {
    var x = document.getElementById("old");
    if (isActive) {
      setActive(false);
      x.type = "password";
    }
    else {
      setActive(true);
      x.type = "text";
    }
  }

  function myFunctionone() {
    var x = document.getElementById("pass");
    if (isActivep) {
      setActivep(false);
      x.type = "password";
    }
    else {
      setActivep(true);
      x.type = "text";
    }
  }

  function myFunctiontwo() {
    var x = document.getElementById("cpass");
    if (isActivecp) {
      setActivecp(false);
      x.type = "password";
    }
    else {
      setActivecp(true);
      x.type = "text";
    }
  }

  return (
    <>
    <div className="px-8 md:px-4">
      <div className="relative flex flex-col break-words max-w-580-px mb-6 mt-4   rounded-lg bg-white border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">My account</h6>
          </div>
          <hr className="mt-2 border-b-1 border-blueGray-300" />
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h6 className="text-blueGray-400 text-base mt-3 mb-6 font-bold uppercase">
              Change Password
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <Form.Field>
                    <label
                      className=" text-blueGray-600 text-base font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Old Password*
                    </label>
                    <div className="absolute inset-y-px right-0 pr-4 pt-5 flex items-center">
                      <a class="btn" onClick={myFunction} role="button"><i className={isActive ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></a>
                    </div>
                    <input name="old" id="old"
                      type="password" {...register('old')}
                      className="border-1 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Old Password"
                    />
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.old?.message}</div>
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <Form.Field>
                    <label
                      className=" text-blueGray-600 text-base font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      New Password*
                    </label>
                    <div className="absolute inset-y-px right-0 pr-4 pt-5 flex items-center">
                      <a class="btn" onClick={myFunctionone} role="button"><i className={isActivep ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></a>
                    </div>
                    <input name="new" id="pass"
                      type="password" {...register('new')}
                      className="border-1 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="New Password"
                    />
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.new?.message}</div>
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <Form.Field>
                    <label
                      className=" text-blueGray-600 text-base font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Confirm Password*
                    </label>
                    <div className="absolute inset-y-px right-0 pr-4 pt-5 flex items-center">
                      <a class="btn" onClick={myFunctiontwo} role="button"><i className={isActivecp ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></a>
                    </div>
                    <input name="confirm" id="cpass"
                      type="password" {...register('confirm')}
                      className="border-1 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Confirm Password"
                    />
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.confirm?.message}</div>
                </div>
              </div>

            </div>
            <br />
            <div className="container mx-auto">
              <div className="flex flex-wrap justify-center -mt-20 py-16 px-12">
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type='submit'
                >
                  Change Password
                </button>
              </div>
            </div>
            <p className="font-bold text-red-500 text-center">{err}</p>
          </form>
        </div>
      </div>

      </div>
    </>
  );
}