/*eslint-disable*/
import React from "react";
import { useHistory, Link } from "react-router-dom";
//import { createPopper } from "@popperjs/core";
import { HashLink } from 'react-router-hash-link';

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [dropOpen, setdropOpen] = React.useState(false);
  const [dropdownOpen, setdropdownOpen] = React.useState(false);
  const [downOpen, setdownOpen] = React.useState(false);
  const [downsOpen, setdownsOpen] = React.useState(false);



  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center  px-0 lg:px-8  py-1 navbar-expand-lg bg-white shadow md:block hidden">
        <div className="container px-8  mx-auto flex flex-wrap items-center just">
          <div className=" relative flex lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className=" leading-relaxed inline-block py-2"
            >
              <img
                alt="..."
                src={require("assets/img/ibcae.png").default}
                className="w-full max-w-280-px"
              />
            </Link>
          </div>

          <div className="lg:w-6/12 md:w-7/12 sm:w-6/12 flex flex-wrap align lg:pr-12 items-center">
            <div className="md:w-12/12 lg:w-12/12 sm:w-12/12">
              <div className="flex flex-wrap align font-2xl items-center">
                <div className="font-2xl  pt-2  dropdown border-r">
                  <h1 className="font-bold md:text-base px-2 uppercase text-black">
                    <a href="/services">
                      Services
                    </a>
                  </h1>
                </div>
                <div className="font-2xl px-2  dropdown ">
                  <button className="cursor-pointer lg:static pt-2 md:text-base text-sm  text-black  rounded outline-none focus:outline-none"
                    type="button"
                    onClick={() => setNavbarOpen(!navbarOpen)}>
                    <div className="flex">
                      <img
                        alt="..."
                        src={require("assets/img/imgs/uaeflag.png").default}
                        className=" w-8"
                      />
                      <h1 className="font-bold pl-1 pr-2 pt-2">
                        - UAE
                      </h1>
                      <i class="fas fa-caret-down pt-2" ></i>
                    </div>
                  </button>
                  {/* Dropdown flag */}
                  <div
                    className={
                      " flex lg:w-auto  " +
                      (navbarOpen ? " block " : " hidden")
                    }
                  >
                    <button className="fixed inset-0 cursor-default"
                      onClick={(e) => {
                        setNavbarOpen(false); setNavbarOpen(false);
                      }}>
                    </button>
                    <ul className="bg-neutral-100 shadow-lg absolute "
                      onClick={(e) => {
                        setNavbarOpen(false); setNavbarOpen(false);
                      }}>
                      <li className="flex items-center border-b py-1">
                        <a href="https://ibusinessconsulting.ca" target="blank">
                          <div className="font-2xl px-2 flex">
                            <img
                              alt="..."
                              src={require("assets/img/imgs/canadaflag.png").default}
                              className=" w-10"
                            />
                            <h1 className="font-bold pt-2 px-2">
                              CANADA
                            </h1>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className=" px-2 dropdown border-r border-l">
                  <button className="cursor-pointer lg:static md:text-base text-sm text-black lg:border-0 rounded outline-none focus:outline-none"
                    type="button"
                    onClick={() => setdownOpen(!downOpen)}>
                    <div className="flex">
                      <i className="fa fa-globe pt-2 pl-1"></i>
                      <h1 className="font-bold pl-1 pr-2 pt-2">
                        IN - EN
                      </h1>
                      <i class="fas fa-caret-down pt-2" ></i>
                    </div>
                  </button>
                  {/* Dropdown flag */}
                  <div
                    className={
                      " flex lg:w-auto  " +
                      (downOpen ? " block " : " hidden")
                    }
                  >
                    <button className="fixed inset-0 cursor-default"
                      onClick={(e) => {
                        setdownOpen(false); setNavbarOpen(false);
                      }}>
                    </button>
                    <ul className="bg-neutral-100 shadow-lg absolute  top-16  lg:mt-0 px-8  z-50 lg:relative lg:px-2 lg:rounded-md flex flex-col list-none"
                      onClick={(e) => {
                        setdownOpen(false); setNavbarOpen(false);
                      }}>
                      <li className="flex items-center border-b py-1">
                        <HashLink smooth to={'/index'}>
                          <div className="font-2xl px-2 flex py-2">
                            <i className="fa fa-globe pt-1 pl-1"></i>
                            <h1 className="font-bold px-4 pt-1">
                              UAE - AE
                            </h1>
                          </div>
                        </HashLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="font-2xl px-4  border-r">
                  <a href="tel:+971 55 626 9728">
                    <i className="fa fa-phone pt-2"></i>
                  </a>
                </div>
                <div className="font-2xl px-4 border-r">
                  <a href="https://customer.ibusinessconsulting.ae/">
                    <i className="fa fa-user pt-2"></i>
                  </a>
                </div>
                <div id="menuToggle" className="pt-2 pl-4 ">
                  <input type="checkbox" />
                  <span></span>
                  <span></span>
                  <span></span>
                  <ul id="menu">

                    <div className="border-b">
                      <li className="flex items-center relative ">
                        <Link
                          to="/about"
                          className=" px-2 py-4 lg:py-2 flex items-center text-lg uppercase font-semibold ">
                          About Us
                        </Link>
                      </li>
                    </div>
                    <div className="border-b md:py-6 lg:py-0 py-0">

                      <li className="flex items-center relative md:py-6 ">
                        <button className="flex cursor-pointer lg:static lg:text-lg lg:pr-2 lg:pl-0 lg:py-1 text-3xl text-white absolute   py-2 lg:border-0 rounded outline-none focus:outline-none"
                          type="button"
                          onClick={() => setdropdownOpen(!dropdownOpen)}>
                          <Link
                            to="/services"
                            className=" px-2 py-4 lg:py-1 flex items-center text-lg uppercase font-semibold">
                            Services
                          </Link>

                          <i class="fas fa-caret-down pt-1 md:pt-4 lg:pt-1 pl-2 text-lg" ></i>
                        </button>
                        <div
                          className={
                            " flex lg:w-auto  " +
                            (dropdownOpen ? " block " : " hidden")
                          }
                        >
                          <button className="fixed inset-0 cursor-default"
                            onClick={(e) => {
                              setdropdownOpen(false); setNavbarOpen(false);
                            }}>

                          </button>

                          <ul className=" uppercase absolute right-0 left-0 lg:top-16 top-16 md:top-12  lg:mt-0  pl-2 z-50 lg:relative lg:px-2 lg:rounded-md flex flex-col list-none"
                            onClick={(e) => {
                              setdropdownOpen(false); setNavbarOpen(false);
                            }}>

                            <li className="flex items-center border-b py-1">
                              <HashLink smooth to={'/startup'}
                                className="hover:text-white text-white lg:py-1 pl-4 flex items-center text-base  font-semibold bg-transparent"
                              >
                                Corporation Services
                              </HashLink>
                            </li>
                            <li className="flex items-center border-b py-1">
                              <HashLink smooth to={'/webdesign'}
                                className="hover:text-white text-white lg:py-1 pl-4 flex items-center text-base  font-semibold bg-transparent"
                              >
                                IT Services
                              </HashLink>
                            </li>
                            <li className="flex items-center border-b py-1">
                              <HashLink smooth to={'/hrmanage'}
                                className="hover:text-white text-white lg:py-1 pl-4 flex items-center text-base  font-semibold bg-transparent"
                              >
                                HR Services
                              </HashLink>
                            </li>
                            <li className="flex items-center border-b py-1">
                              <HashLink smooth to={'/patend'}
                                className="hover:text-white text-white lg:py-1 pl-4 flex items-center text-base  font-semibold bg-transparent"
                              >
                                Intellectual Property Services
                              </HashLink>
                            </li>

                          </ul>
                        </div>
                      </li>
                    </div>
                    {/* <div className="border-b">
                      <div
                        className={
                          " flex lg:w-auto  " +
                          (dropdownOpen ? " pt-48 lg:pt-48 md:pt-40" : " pt-0")
                        }
                      >
                        <li className="flex items-center">
                          <Link
                            to="/plan"
                            className=" px-2 py-2 lg:py-2 flex items-center text-base uppercase font-bold"
                          >
                            Plans
                          </Link>
                        </li>
                      </div>
                    </div>*/}
                    <div className="border-b">
                      <div
                        className={
                          " flex lg:w-auto  " +
                          (dropdownOpen ? " pt-48 lg:pt-48 md:pt-40" : " pt-0")
                        }
                      >
                        <li className="flex items-center relative ">
                          <Link
                            to="/contact"
                            className=" px-2 py-4 lg:py-2 flex items-center text-base uppercase font-bold">
                            Contact Us
                          </Link>
                        </li>
                      </div>
                    </div>
                    <div className="text-white lg:mt-96 lg:pl-12 md:mt-96 md:pl-12 ">
                      {/*<h2 className="text-2xl mt-6 font-bold">
                        +971 55 626 9728,
                      </h2>*/}
                      <h2 className="text-xl pt-3">
                        info@ibusinessconsulting.ae
                      </h2>
                      <div className="text-white pt-3">
                        <a
                          className="hover:text-blueGray-500 text-white pr-2 py-4 lg:py-2  items-center text-lg uppercase font-bold"
                          href=""
                        >
                          <i className="text-white fab fa-facebook text-xl leading-lg text-center " />
                          {/*<span className="lg:hidden inline-block ml-2">Share</span>*/}
                        </a>

                        <a
                          className="hover:text-blueGray-500 text-white px-2 py-4 lg:py-2  items-center text-lg uppercase font-bold"
                          href=""
                        >
                          <i className="text-white fab fa-twitter text-xl leading-lg text-center" />
                          {/*<span className="lg:hidden inline-block ml-2">Tweet</span>*/}
                        </a>

                        <a
                          className="hover:text-blueGray-500 text-white px-2 py-4 lg:py-2  items-center text-lg uppercase font-bold"
                          href=""
                        >
                          <i className="text-white fab fa-instagram-square text-xl leading-lg text-center" />
                          {/*<span className="lg:hidden inline-block ml-2">Post</span>*/}
                        </a>

                        <a
                          className="hover:text-blueGray-500 text-white px-2 py-4 lg:py-2  items-center text-lg uppercase font-bold"
                          href=""
                        >
                          <i className="text-white fab fa-linkedin text-xl leading-lg text-center" />
                          {/*<span className="lg:hidden inline-block ml-2">Follow</span>*/}
                        </a>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>



      {/* mobile View */}

      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center  px-0 md:px-20 py-2 navbar-expand-lg bg-white shadow md:hidden block ">
        <div className="container pl-4 mx-auto flex flex-wrap items-center ">
          <div className="w-full relative flex lg:w-auto lg:static lg:block lg:justify-start justify-between">
            <Link
              to="/"
              className=" leading-relaxed inline-block py-2"
            >
              <img
                alt="..."
                src={require("assets/img/ibcae.png").default}
                className="w-96"
              />
            </Link>

            <div className="w-9/12 sm:pr-2 pr-16 alignright">
              <div className="flex justify-between">
                <div className="sm:w-8/12"></div>
                <div className="sm:w-4/12 flex items-center">
                <div className="sm:w-4/12">
                    
                  </div>
                  <div className="sm:w-4/12 pr-2">
                  <a href="https://customer.ibusinessconsulting.ae/"><i class="fa fa-user pt-4 w-16"></i></a>
                   
                  </div>
                  <div className="sm:w-4/12">
                    <img
                      alt="..."
                      src={require("assets/img/imgs/uaeflag.png").default}
                      className="w-10 pt-4"
                    />
                  </div>
                </div>


              </div>

            </div>
          </div>
          <div id="menuToggle1">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul id="menu1">

              <div className="border-b">
                <li className="flex items-center relative ">
                  <a href="/about">
                    <h1
                      className="pl-1 flex items-center text-base uppercase font-semibold">
                      About Us
                    </h1>
                  </a>
                </li>
              </div>
              <div className="border-b">
                <div
                  className={
                    " " +
                    (dropOpen ? " pt-32" : " py-4")
                  }
                >
                  <li className="flex items-center relative ">
                    <button className="flex cursor-pointer lg:static lg:text-lg lg:pr-2 -ml-5 lg:pl-0 lg:py-1 text-3xl text-white absolute px-6 py-2   rounded outline-none focus:outline-none"
                      type="button"
                      onClick={() => setdropdownOpen(!dropdownOpen)}>
                      <Link
                        to="/services"
                        className=" py-4 lg:py-2  items-center text-lg uppercase font-semibold">
                        Services
                      </Link>

                      <i class="fas fa-caret-down pt-4 pl-2 text-lg" ></i>
                    </button>
                    <div
                      className={
                        " flex lg:w-auto  " +
                        (dropdownOpen ? " block " : " hidden")
                      }
                    >
                      <ul className="uppercase absolute right-0 left-0 top-16 lg:w-80   z-50 lg:relative lg:px-2 lg:rounded-md flex flex-col list-none"
                        onClick={(e) => {
                          setdropdownOpen(false); setNavbarOpen(false);
                        }}>

                        <li className="flex items-center border-b py-1 -mt-8 ">
                          <HashLink smooth to={'/startup'}
                            className="hover:text-white text-white lg:py-1 pl-4 flex items-center text-base  font-semibold bg-transparent"
                          >
                            Corporation Services
                          </HashLink>

                        </li>
                        <li className="flex items-center border-b py-1">
                          <HashLink smooth to={'/webdesign'}
                            className="hover:text-white text-white lg:py-1 pl-4 flex items-center text-base  font-semibold bg-transparent"
                          >
                            IT Services
                          </HashLink>
                        </li>
                        <li className="flex items-center border-b py-1">
                          <HashLink smooth to={'/hrmanage'}
                            className="hover:text-white text-white lg:py-1 pl-4 flex items-center text-base  font-semibold bg-transparent"
                          >
                            HR Services
                          </HashLink>
                        </li>
                        <li className="flex items-center border-b py-1">
                          <HashLink smooth to={'/intellect'}
                            className="hover:text-white text-white lg:py-1 pl-4 flex items-center text-base  font-semibold bg-transparent"
                          >
                            Intellectual Property Services
                          </HashLink>
                        </li>

                      </ul>
                    </div>
                  </li>
                </div>
              </div>
              {/*<div className="border-b">
                <div
                  className={
                    " flex lg:w-auto  " +
                    (dropdownOpen ? " pt-44 " : " pt-0")
                  }
                >
                  <li className="flex items-center">
                    <Link
                      to="/plan"
                      className=" py-1 lg:py-2 flex items-center text-base uppercase font-semibold pl-1"
                    >
                      Plans
                    </Link>
                  </li>
                </div>
                </div>*/}
              <div className="border-b">
                <div
                  className={
                    " flex lg:w-auto  " +
                    (dropdownOpen ? " pt-44 " : " pt-0")
                  }
                >
                  <li className="flex items-center relative ">
                    <Link
                      to="/contact"
                      className=" lg:py-2 flex items-center text-base uppercase font-semibold pl-1">
                      Contact Us
                    </Link>
                  </li>
                </div>
              </div>
              <div className="text-white pt-68 pl-12 ">
                {/*<h2 className="text-2xl mt-6 font-bold">
                  +971 55 626 9728,
                </h2>*/}
                <h2 className="text-xl pt-3">
                  info@ibusinessconsulting.ae
                </h2>
                <div className="text-white pt-3">
                  <a
                    className="hover:text-blueGray-500 text-white pr-2 py-4 lg:py-2  items-center text-lg uppercase font-bold"
                    href=""
                  >
                    <i className="text-white fab fa-facebook text-xl leading-lg text-center " />
                    {/*<span className="lg:hidden inline-block ml-2">Share</span>*/}
                  </a>

                  <a
                    className="hover:text-blueGray-500 text-white px-2 py-4 lg:py-2  items-center text-lg uppercase font-bold"
                    href=""
                  >
                    <i className="text-white fab fa-twitter text-xl leading-lg text-center" />
                    {/*<span className="lg:hidden inline-block ml-2">Tweet</span>*/}
                  </a>

                  <a
                    className="hover:text-blueGray-500 text-white px-2 py-4 lg:py-2  items-center text-lg uppercase font-bold"
                    href=""
                  >
                    <i className="text-white fab fa-instagram-square text-xl leading-lg text-center" />
                    {/*<span className="lg:hidden inline-block ml-2">Post</span>*/}
                  </a>

                  <a
                    className="hover:text-blueGray-500 text-white px-2 py-4 lg:py-2  items-center text-lg uppercase font-bold"
                    href=""
                  >
                    <i className="text-white fab fa-linkedin text-xl leading-lg text-center" />
                    {/*<span className="lg:hidden inline-block ml-2">Follow</span>*/}
                  </a>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </nav >

    </>
  );
}
