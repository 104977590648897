import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Spinner from "react-spinkit";
import Moment from 'moment';

// components

export default function CardPageVisits() {

  const [purchased, setPurchased] = useState("");
  const [starts, setStarts] = useState("");
  const [expired, setExpired] = useState("");
  const [payment, setPayment] = useState("");
  const [amount, setAmount] = useState("");
  const [items, setItems] = useState([]);
  const { id } = useParams();
  const [DataisLoaded, setDataisLoaded] = useState(false)

  useEffect(() => {
    getUserById();
  }, []);

  const getUserById = async () => {
    await fetch(`https://ibusinessconsulting.ca/admin/customerreact/single/${id}`)
      .then(res => res.json())
      .then(
        (result) => {
          //     console.log("Result",result)
        })
    await fetch(`https://ibusinessconsulting.ca/admin/Plansreact`)
      .then(result => result.json())
      .then(
        (response) => {
          setItems(response)
        })
  }

  useEffect(() => {
    fetch(`https://ibusinessconsulting.ca/admin/upgradereact/customers_upgrade/${id}`)
      .then(res => res.json())
      .then(
        (results) => {
          setPurchased(results[0].purchased)
          setPayment(results[0].payment)
          setAmount(results[0].amount)
          setStarts(results[0].starts)
          setExpired(results[0].expired)
          setDataisLoaded(true)
        })
  }, [])

  if (!DataisLoaded) return <div class="flex h-screen justify-center items-center">
    <div class="text-center bg-blue-400">
      <Spinner name="chasing-dots" />
    </div>
  </div>;
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-2 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-xl text-blueGray-700">
                Payment History
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-base uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Invoice Details
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">

                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">

                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">

                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="border-t-0 px-6 align-middle font-semibold border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                  Plan Purchased
                </th>
                <td className="border-t-0 px-6 align-middle font-semibold border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                </td>
                <td className="border-t-0 px-6 align-middle font-semibold border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                </td>
                <td className="border-t-0 px-6 align-middle  border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                  {purchased}
                </td>
              </tr>
              {items.map((item, index) => (
                <tr data-index={index}>
                  {item.plan_name === purchased ?
                    <>
                      <th className="border-t-0 px-6 align-middle font-semibold border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                        Services
                      </th>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                      </td>
                      <td className="border-t-0 px-6 align-middle  border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                      </td>
                      <td className="border-t-0 px-6 align-middle  border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                        {item.services.split(',').map(str => <p>{str}</p>)}
                      </td>
                    </>
                    :
                    null
                  }
                </tr>
              ))}
              <tr>
                <th className="border-t-0 px-6 align-middle font-semibold border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                  Duration
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                </td>
                <td className="border-t-0 px-6 align-middle  border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                </td>
                <td className="border-t-0 px-6 align-middle  border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                  {Moment(starts).format('DD/MM/YYYY')} - {Moment(expired).format('DD/MM/YYYY')}
                </td>
              </tr>
              <tr>
                <th className="border-t-0 px-6 align-middle font-semibold border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                  Amount paid
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                  {amount}
                </td>
              </tr>
              <tr>
                <th className="border-t-0 px-6 align-middle font-semibold border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                  Payment mode
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                  {payment}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
