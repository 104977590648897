import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Spinner from "react-spinkit";

export default function CardRenew() {

    const [purchased, setPurchased] = useState("");
    const [amount, setAmount] = useState("");
    const [duration, setDuration] = useState("");
    const { id } = useParams();
    const [DataisLoaded, setDataisLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        getUserById();
    }, []);

    const getUserById = async () => {
        await fetch(`https://ibusinessconsulting.ca/admin/upgradereact/customers_upgrade/${id}`)
            .then(res => res.json())
            .then(
                (result) => {
                    //     console.log("Result",result)
                    setPurchased(result[0].purchased)
                    setAmount(result[0].amount)
                    setDuration(result[0].duration)
                    setDataisLoaded(true)
                })

        await fetch(`https://ibusinessconsulting.ca/admin/Plansreact`)
            .then(result => result.json())
            .then(
                (response) => {
                    setItems(response)
                })
    }

    if (!DataisLoaded) return <div class="flex h-screen justify-center items-center">
        <div class="text-center bg-blue-400">
            <Spinner name="chasing-dots" />
        </div>
    </div>;
    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg lg:mt-16">
                <div className="px-4">
                    <div className="flex">
                        <div className="flex-1 w-64 text-left mt-12">
                            <div className="mt-6  flex lg:flex-row md:flex-col sm:flex-col justify-center">
                                {
                                    items.map((item) => (
                                        <>
                                            {(amount / duration) == item.price ?
                                                <div className="hover:-mt-4 flex break-words bg-white transition ease-out duration-150" style={{
                                                    padding: "1%"
                                                }}>
                                                    <div className="pb-6 w-96 text-black  hover:text-blueGray-800 hover:bg-lightBlue-200 border-2 rounded-lg shadow-xl ">
                                                        <ul key={item.id} >
                                                            <div className="mt-6 mb-4 rounded-lg cursor-pointer ">
                                                                <h6 className="text-1xl px-6 mb-2 text-center font-bold uppercase">Current Plan</h6>
                                                                <h6 className="text-3xl px-6 font-sans font-bold text-center">{item.plan_name}</h6>
                                                                <h2 className="text-l mt-4 break-words font-semibold text-center">{item.about}</h2>
                                                            </div>

                                                            <div classname="py-20">
                                                                <div className="mt-6 flex flex-start justify-center">
                                                                    <p className="text-l font-normal">$</p>
                                                                    <p className="text-4xl font-bold">{item.price}</p>
                                                                    <p className="text-3xl font-light">/Month</p>
                                                                </div>

                                                                <div className="px-10 py-8 flex justify-center ">
                                                                    <Link to={{
                                                                        pathname: "/auth/payment",
                                                                        state: {
                                                                            plan_name: item.plan_name,
                                                                            price: item.price,
                                                                            type: 'Renew'
                                                                        }
                                                                    }}
                                                                        className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center" style={{ padding: "2" }}>
                                                                        Renew Now
                                                                    </Link>
                                                                </div>
                                                            </div>

                                                            <div className="px-2 py-2 text-lg font-semibold cursor-pointer" > {item.services.split(',').map(str => <p><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }}></i>{str}</p>)}
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            {(amount / duration) < item.price ?
                                                <div className="hover:-mt-4 flex break-words bg-white transition ease-out duration-150" style={{
                                                    padding: "1%"
                                                }}>
                                                    <div className="pb-6 w-96 text-black  hover:text-blueGray-800 hover:bg-lightBlue-200 border-2 rounded-lg shadow-xl ">
                                                        <ul key={item.id} >
                                                        <div className="mt-6 mb-4 rounded-lg cursor-pointer ">
                                                                <h6 className="text-1xl px-6 mb-2  text-center  font-bold uppercase">Upgrade To</h6>
                                                                <h6 className="text-3xl px-6 font-bold font-sans text-center">{item.plan_name}</h6>
                                                                <h2 className="text-l mt-4 break-words font-semibold text-center">{item.about}</h2>
                                                            </div>

                                                            <div classname="py-20">
                                                                <div className="mt-6 flex flex-start justify-center">
                                                                    <p className="text-l font-normal">$</p>
                                                                    <p className="text-4xl font-bold">{item.price}</p>
                                                                    <p className="text-3xl font-light">/Month</p>
                                                                </div>

                                                                <div className="px-10 py-8 flex justify-center ">
                                                                    <Link to={{
                                                                        pathname: "/auth/payment",
                                                                        state: {
                                                                            plan_name: item.plan_name,
                                                                            price: item.price,
                                                                            type: 'Upgrade'
                                                                        }
                                                                    }}
                                                                        className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center" style={{ padding: "2" }}>
                                                                        Upgrade Now
                                                                    </Link>
                                                                </div>
                                                            </div>

                                                            <div className="px-2 py-2 text-lg font-semibold cursor-pointer" > {item.services.split(',').map(str => <p><i className="fa fa-solid fa-check px-4 py-2" style={{ color: "green" }}></i>{str}</p>)}
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    {(amount / duration) == Math.max.apply(Math, items.map(function (o) { return o.price; })) ? "Awesome! You are at highest level" : null}
                </div>
            </div>
        </>
    )
}
