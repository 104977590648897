/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import ibusiness from "assets/img/imgs/ibusiness.png";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
//import g_04 from "assets/img/g_04.jpg";



export default function Index() {
  return (
    <>
      <IndexNavbar fixed />
      <section className="header w-full md:h-600-px lg:h-700-px h-450-px bg-contain bg-no-repeat homebanner bg-white md:block hidden">
        <div className="container mx-auto md:pt-32">
          <div className="items-center flex md:flex-nowrap lg:px-12 md:px-4  md:pt-32 ">
            <div className="text-left md:w-8/12 lg:w-8/12 xl:w-11/12 md:px-6">
              <h2 className="font-bold hometext md:text-5xl text-4xl">
                Welcome To
              </h2>
              <h2 className="font-bold hometext lg:text-5xl md:text-4xl text-4xl">
                iBusiness Consulting Firm
              </h2>
              <h2 className="homepara">
                Helping Hand For Entrepreneurs
              </h2>
            </div>
          </div>
        </div>
      </section>


      <section className="bg-white lg:-mt-16 lg:pb-0 md:pb-12">
        <div className="container  pt-16 mx-auto text-center h-250-px hidden md:block">
          <div className="w-full flex flex-wrap lg:px-48 md:px-12 px-60">
            <div className="mt-4 md:text-lg lg:text-xl leading-relaxed ttt text-blueGray-500">
              Our key goal is to give you all the tools and guidance to conquer your passion in
              your company this can be challenging, especially when it comes to bringing the best in performance growth.

            </div>
            <div className="w-full mt-12">
              <a
                href="/wizard"
                className="get-started whitespace-nowrap text-white font-bold px-6 py-4 rounded-full outline-none focus:outline-none mr-2 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-base shadow hover:shadow-lg ease-linear transition-all duration-150"
              >
                Get started
              </a>
              <a
                href="/contact"
                className="github-star ml-1 whitespace-nowrap text-white font-bold px-6 py-4 rounded-full outline-none focus:outline-none mr-1 mb-2 bg-blueGray-700 active:bg-blueGray-600 uppercase text-base shadow hover:shadow-lg ease-linear transition-all duration-150"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="block md:hidden">
        <div className="container mx-auto items-center flex flex-wrap px-4">
          <div className="w-full pt-6">
            <h2 className="font-bold hometext md:text-5xl text-3xl pt-36 text-blueGray-600">
              Welcome To
            </h2>
            <h2 className=" hometext font-bold text-3xl  lg:text-5xl pt-2 text-blueGray-600">
              iBusiness Consulting Firm
            </h2> 
            <h2 className=" homepara1 mt-4  font-bold text-xl  lg:text-5xl text-blueGray-600">
              Helping Hand for Entrepreneurs
            </h2>
            <p className=" mt-4 text-base leading-relaxed ttt ext-blueGray-500">
              Our key goal is to give you all the tools and guidance to conquer your passion in
              your company this can be challenging, especially when it comes to bringing the best in performance growth.

            </p>
            <div className=" mt-6">
              <a
                href="/wizard"
                className="get-started whitespace-nowrap text-white font-bold px-5 py-3 rounded-2xl outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600  text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
              >
                Get started
              </a>
              <a
                href="/contact"
                className="github-star ml-1 whitespace-nowrap text-white font-bold px-5 py-3 rounded-2xl outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600  text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
        <img
          className="pt-4 flex w-full relative"
          src={require("assets/img/imgs/ibusinessmbl.png").default}
          alt="..."
        />
      </section>

      <Footer />
    </>
  );
}
