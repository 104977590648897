import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Button } from 'semantic-ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Spinner from "react-spinkit";

// components

const CardSettings = () => {
  //   const [error, setError] = useState(null);
  //   const [items, setItems] = useState([]);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [company, setCompany] = useState("");
  const [mobile, setMobile] = useState("");
  const history = useHistory();
  const { id } = useParams();
  const [err, setErr] = useState("")

  // Add method
  Yup.addMethod(Yup.string, 'stripEmptyString', function () {
    return this.transform((value) => (value === '' ? undefined : value));
  });
  // Usage
  const formSchema = Yup.object().shape({
    fname: Yup.string('First Name must be a string').stripEmptyString().default(`${fname}`)
      .required('Please enter First name')
      .min(4, 'First name is too short'),
    lname: Yup.string('Last Name must be a string').stripEmptyString().default(`${lname}`)
      .required('Please enter Last name')
      .min(4, 'Last name is too short'),
    street: Yup.string('Street Address must be a string').stripEmptyString().default(`${street}`)
      .required('Please enter Street Address')
      .min(10, 'Street Address is too short'),
    city: Yup.string('City must be a string').stripEmptyString().default(`${city}`)
      .required('Please enter City')
      .min(4, 'City is too short'),
    zipcode: Yup.string('ZIP Code must be a string').stripEmptyString().default(`${zipcode}`)
      .required('Please enter ZIP Code')
      .min(4, 'ZIP Code is too short')
      .matches(/^[0-9]*$/, "ZIP Code is numeric"),
    country: Yup.string('Country must be a string').stripEmptyString().default(`${country}`)
      .required('Please enter Country')
      .min(4, 'Country is too short'),
    state: Yup.string('State must be a string').stripEmptyString().default(`${state}`)
      .required('Please enter State')
      .min(4, 'State is too short'),
    company: Yup.string('Company Name must be a string').stripEmptyString().default(`${company}`)
      .nullable()
      .notRequired(),
    mobile: Yup.string('Mobile number must be a string').stripEmptyString().default(`${mobile}`)
      .required('Please enter Mobile number')
      .min(10, 'Mobile number is too short')
      .matches(
        /^[6-9]\d{9}$/,
        "Please enter valid Mobile number"
      )
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors } = formState
  const [DataisLoaded, setDataisLoaded] = useState(false)

  const onSubmit = (data) => {
    console.log(JSON.stringify(data, null, 4))
    updateUser(data)
    return false
  }
  /*
    useEffect(() => {
      if (!localStorage.getItem('user-info')) {
        history.push("/auth/login")
      }
    }, [])
  */
  useEffect(() => {
    getUserById();
  }, []);

  const getUserById = async () => {
    await fetch(`https://ibusinessconsulting.ca/admin/customerreact/single/${id}`)
      .then(res => res.json())
      .then(
        (result) => {
          //   console.log("Result",result)
          setFname(result.fname)
          setLname(result.lname)
          setEmail(result.email)
          setStreet(result.street)
          setCity(result.city)
          setCountry(result.country)
          setState(result.state)
          setZipcode(result.zipcode)
          setCompany(result.company)
          setMobile(result.mobile)
          setDataisLoaded(true)
        })
  }

  async function updateUser(data) {
    console.warn(data)
    let result = await fetch(`https://ibusinessconsulting.ca/admin/customerreact/update/${id}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
    result = await result.json()
    console.warn("Result", result)
    if (result.status === 400 || result.status === 404 || result.status === 500) {
      setErr("Error");
    }
    else {
      setErr("Updated successfully");
      history.push(`/profile/settings/${id}`)
    }
  }

  if (!DataisLoaded) return <div class="flex h-screen justify-center items-center">
    <div class="text-center bg-blue-400">
      <Spinner name="chasing-dots" />
    </div>
  </div>;
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">My account</h6>
          </div>
          <hr className="mt-2 border-b-1 border-blueGray-300" />
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 ">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <Form.Field>
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      First Name
                    </label>
                    <input name="fname"
                      type="text" {...register('fname')}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="First Name" defaultValue={fname}
                    />
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.fname?.message}</div>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <Form.Field>
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Last Name
                    </label>
                    <input name="lname"
                      type="text" {...register('lname')}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Last Name" defaultValue={lname}
                    />
                  </Form.Field>
                  <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.lname?.message}</div>
                </div>
              </div>
            </div>

            
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <Form.Field>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Mobile number*
                    </label>
                    <input name="mobile" defaultValue={mobile}
                      type="text" {...register('mobile')}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Mobile number"
                    />
                  </div>
                </Form.Field>
                <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.mobile?.message}</div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <Form.Field>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input name="email" defaultValue={email}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email" disabled
                    />
                  </div>
                </Form.Field>
                <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.email?.message}</div>
              </div>
            </div>

            <Form.Field>
              <div className="relative w-full px-4 mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Street Address*
                </label>
                <input name="street" defaultValue={street}
                  type="text" {...register('street')}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Street Address"
                />
              </div>
            </Form.Field>
            <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.street?.message}</div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <Form.Field>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      City*
                    </label>
                    <input name="city" defaultValue={city}
                      type="text" {...register('city')}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="City"
                    />
                  </div>
                </Form.Field>
                <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.city?.message}</div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <Form.Field>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      ZIP Code*
                    </label>
                    <input name="zipcode" defaultValue={zipcode}
                      type="text" {...register('zipcode')}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="ZIP Code"
                    />
                  </div>
                </Form.Field>
                <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.zipcode?.message}</div>
              </div>
            </div>
            <div className="flex  flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <Form.Field>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      State*
                    </label>
                    <input name="state" defaultValue={state}
                      type="text" {...register('state')}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="State"
                    />
                  </div>
                </Form.Field>
                <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.state?.message}</div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <Form.Field>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Country*
                    </label>
                    <input name="country" defaultValue={country}
                      type="text" {...register('country')}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Country"
                    />
                  </div>
                </Form.Field>
                <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.country?.message}</div>
              </div>
            </div>

            <Form.Field>
              <div className="relative w-full px-4 mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Company
                </label>
                <input name="company" defaultValue={company}
                  type="text" {...register('company')}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Company"
                />
              </div>
            </Form.Field>
            <div className="invalid-feedback font-bold text-red-500 mb-3">{errors.company?.message}</div>

            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="text-center mt-6">
                  <Button className="ui secondary button bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type='submit'>Update</Button>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="text-center mt-6">
                  <Link
                    to={`/profile/settings/${JSON.parse(localStorage.getItem('user-info')).id}`}
                    className="ui secondary button bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  >Back</Link>
                </div>
              </div>
            </div>
          </Form>
          <p className="font-bold text-red-500 text-center">{err}</p>
        </div>
        {/*<div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              User Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue="lucky.jesse"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue="jesse@example.com"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue="Lucky"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue="Jesse"
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Contact Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    City
                  </label>
                  <input
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue="New York"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Country
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue="United States"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Postal Code
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue="Postal Code"
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              About Me
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    About me
                  </label>
                  <textarea
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue="A beautiful UI Kit and Admin for React & Tailwind CSS. It is Free and Open Source."
                    rows="4"
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
  </div>*/}
      </div>
    </>
  );
}
export default CardSettings