/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
//import g_04 from "assets/img/g_04.jpg";



export default function Home2() {
  return (
    <>
      <IndexNavbar fixed />

      <section className="w-full items-center content-center justify-center min-h-screen pb-8 hidden  md:flex ">
        <div className="container mx-auto">
          <div className="items-center flex md:flex-nowrap px-12  ">
            <div className="text-left md:w-8/12 lg:w-6/12 xl:w-11/12 px-8 ">
              <h2 className="pt-24 font-bold md:text-2xl  ttt lg:text-5xl text-blueGray-600">
                iBusiness Consulting
              </h2>
              <h2 className="font-bold md:text-2xl mt-4 lg:text-4xl text-blueGray-600">
                Helping Hand For Entrepreneurs
              </h2>
              
            <div className="mt-4 md:text-lg lg:text-xl leading-relaxed ttt ext-blueGray-500">
              Our key goal is to give you all the tools and guidance to conquer your passion in
              your company this can be challenging, especially when it comes to bringing the best in performance growth.

            </div>
            <div className="w-full mt-12">
              <a
                href="/plan"
                className="get-started whitespace-nowrap text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-2 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-base shadow hover:shadow-lg ease-linear transition-all duration-150"
              >
                Get started
              </a>
              <a
                href="/contact"
                className="github-star ml-1 whitespace-nowrap text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-blueGray-700 active:bg-blueGray-600 uppercase text-base shadow hover:shadow-lg ease-linear transition-all duration-150"
              >
                Contact Us
              </a>
            </div>

          
            </div>
            <div>
              <img
                className="pt-24 w-full flex relative"
                src={require("assets/img/g_05.png").default}
                alt="..."
              />
            </div>
          </div>
         
          
        </div>
      </section>

      

      <section className="block md:hidden ">
        <div className="container mx-auto text-center flex flex-wrap px-8">
          <div className="w-full ">
            <h2 className="pt-36 font-bold text-5xl ttt lg:text-5xl text-blueGray-600">
              iBusiness Consulting
            </h2>
            <h2 className="px-2 mt-4  font-bold text-3xl  lg:text-5xl text-blueGray-600">
              Helping Hand for Entrepreneurs
            </h2>
            <p className="px-2 mt-4 text-xl leading-relaxed ttt text-blueGray-500">
              Our key goal is to give you all the tools and guidance to conquer your passion in
              your company this can be challenging, especially when it comes to bringing the best in performance growth.

            </p>
            <div className="px-2 mt-12">
              <a
                href="/plan"
                className="get-started whitespace-nowrap text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-base shadow hover:shadow-lg ease-linear transition-all duration-150"
              >
                Get started
              </a>
              <a
                href="/contact"
                className="github-star ml-1 whitespace-nowrap text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-base shadow hover:shadow-lg ease-linear transition-all duration-150"
              >
                Contact Us
              </a>

            </div>

            <img
              className="pt-4 flex w-full relative"
              src={require("assets/img/g_05.png").default}
              alt="..."
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}