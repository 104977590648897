import React, { useState } from "react";
import { useForm } from "react-hook-form";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Accordion from "components/Dropdowns/Accordion.js";
import Footer from "components/Footers/Footer.js";
import operation001 from "assets/img/operation001.png";
import Popup from "reactjs-popup";

const contentStyle = {
  maxWidth: "600px",
  width: "90%"
};
export default function Operation() {
  //const [stepzero, setStepzero] = useState(true);
  const [stepfir, setStepfir] = useState(true);
  const [stepsec, setStepsec] = useState(false);
  const [stepthi, setStepthi] = useState(false);
  const [stepfou, setStepfou] = useState(false);
  const [stepfiv, setStepfiv] = useState(false);
  const [stepsix, setStepsix] = useState(false);
  const [stepsev, setStepsev] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [suc, setSuc] = useState(false);
  const [err, setErr] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmitOne = async data => {
    //alert(JSON.stringify(data));
    if (suc) {
      // setStepzero(false);
      setStepfir(false);
      setStepsec(true);
      setStepthi(false);
      setStepfou(false);
      setStepfiv(false);
      setStepsix(false);
      setStepsev(false);
    }
  };
  function validateMedium() {
    var mediumCheckboxes = document.getElementsByName("consulting");
    var okay = false;

    for (var i = 0, len = mediumCheckboxes.length; i < len; i++) {
      if (mediumCheckboxes[i].checked) {
        okay = true;
        break;
      }
    }

    if (okay) {
      setSuc(true);
      setErr(false);
      //setStepzero(false);
      //setStepzero(false);
      //setStepfir(false);
      //setStepsec(true);
      //setStepthi(false);
      //setStepfou(false);
      //setStepfiv(false);
      //setStepsix(false);
      //setStepsev(false);
    } else {
      setSuc(false);
      setErr(true);
    }
  }

  const onSubmitTwo = async data => {
    //alert(JSON.stringify(data));
    //setStepzero(false);
    setStepfir(false);
    setStepsec(false);
    setStepthi(true);
    setStepfou(false);
    setStepfiv(false);
    setStepsix(false);
    setStepsev(false);
  };

  const onSubmitThree = async data => {
    //alert(JSON.stringify(data));
    //setStepzero(false);
    setStepfir(false);
    setStepsec(false);
    setStepthi(false);
    setStepfou(true);
    setStepfiv(false);
    setStepsix(false);
    setStepsev(false);
  };

  const onSubmitFour = async data => {
    //alert(JSON.stringify(data));
    //setStepzero(false);
    setStepfir(false);
    setStepsec(false);
    setStepthi(false);
    setStepfou(false);
    setStepfiv(true);
    setStepsix(false);
    setStepsev(false);
  };

  const onSubmitFive = async data => {
    //alert(JSON.stringify(data));
    //setStepzero(false);
    setStepfir(false);
    setStepsec(false);
    setStepthi(false);
    setStepfou(false);
    setStepfiv(false);
    setStepsix(true);
    setStepsev(false);
  };

  const onSubmitSix = async data => {
    //alert(JSON.stringify(data));
    //setStepzero(false);
    setStepfir(false);
    setStepsec(false);
    setStepthi(false);
    setStepfou(false);
    setStepfiv(false);
    setStepsix(false);
    setStepsev(true);
  };

  const onSubmitSeven = async data => {
    //  console.log(JSON.stringify(data));
    //setStepzero(false);
    setStepfir(false);
    setStepsec(false);
    setStepthi(false);
    setStepfou(false);
    setStepfiv(false);
    setStepsix(false);
    setStepsev(false);
    let result = await fetch("https://ibusinessconsulting.ca/admin/Questionreact", {
      method: 'POST',
      body: JSON.stringify(
        {
          consulting: JSON.stringify(data.consulting),
          business: data.business,
          running: data.running,
          revenue: data.revenue,
          industry: data.industry,
          start: data.start,
          email: data.email
        }
      ),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
    result = await result.json()
    //  console.warn("Result", result)
    if (result.status === 201) {
      setSuccess(true);
      setError(false);
    }
    else {
      setSuccess(false);
      setError(true);
    }
  };

  return (
    <>
      <IndexNavbar fixed />

      <div className="container mx-auto px-8 w-full pb-8 pt-36 ">

        <div className="px-3 md:px-2 md:min-h-screen flex flex-col items-center justify-center content-center ">
          <div className="flex flex-wrap md:flex-row md:flex-nowrap">
            <div className="md:w-1/4 bg-white px-4 mr-4">
              <Accordion />
            </div>
            <div className=" md:w-9/12 bg-white">
              <div className="bg-neutral-100 py-12 px-4 relative" style={{ backgroundImage: `url(${operation001})` }}>
                <h2 className="text-center text-5xl mt-2 pt-12 text-blueWhale font-extrabold ttt">Operational Efficiency</h2>
                <div className="text-center mt-8">
                  <Popup trigger={
                    <button
                      className="github-star  absolute right-0  ml-1 justify-center text-white font-bold px-6 py-3  outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-blueGray-600 uppercase text-base shadow hover:shadow-lg ease-linear transition-all duration-150"
                    >
                      Get Quote
                    </button>
                  }

                    modal
                    contentStyle={contentStyle}
                  >

                    {close => (
                      <div className="w-full p-12 border bg-white">

                        <div className="text-lg">
                          <a className="cursor-pointer rounded right-6 top-0  pb-2  absolute text-5xl " onClick={close}>
                            &times;
                          </a>
                          {/*<div className="w-full border-b p-4 text-xl text-center">Get Quote</div>*/}
                          <div className="p-4">
                            {" "}
                            {/*} {stepzero ?
                                  <>
                                    Answer the following questions to get your quote
                                  </>
                                  :
                                  null
                                }*/}
                            {
                              stepfir ?
                                <>
                                  <form onSubmit={handleSubmit(onSubmitOne)}>
                                    <div className="relative w-full text-xl ">
                                      <label
                                        className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                        htmlFor="grid-password"
                                      >
                                        Which type(s) of consulting are you interested in?
                                      </label>
                                      <input {...register("consulting", { required: false, validate: validateMedium })} type="checkbox" value="Financial Management" />
                                      <label
                                        className="text-blueGray-600 pl-2 "
                                      >
                                        Financial management
                                      </label>
                                      <br />

                                      <input {...register("consulting", { required: false, validate: validateMedium })} type="checkbox" value="Technology Selection" />
                                      <label
                                        className="text-blueGray-600 pl-2 "
                                      >
                                        Technology selection
                                      </label>
                                      <br />

                                      <input {...register("consulting", { required: false, validate: validateMedium })} type="checkbox" value="HR Management" />
                                      <label
                                        className="text-blueGray-600 pl-2 "
                                      >
                                        HR management
                                      </label>
                                      <br />

                                      <input {...register("consulting", { required: false, validate: validateMedium })} type="checkbox" value="Strategic Planning" />
                                      <label
                                        className="text-blueGray-600 pl-2 "
                                      >
                                        Strategic planning
                                      </label>
                                      <br />

                                      <input {...register("consulting", { required: false, validate: validateMedium })} type="checkbox" value="Certification Support" />
                                      <label
                                        className="text-blueGray-600 pl-2 "
                                      >
                                        Certification support
                                      </label>
                                      <br />

                                      <input {...register("consulting", { required: false, validate: validateMedium })} type="checkbox" value="Trademark" />
                                      <label
                                        className="text-blueGray-600 pl-2 "
                                      >
                                        Trademark
                                      </label>
                                      <br />

                                      <input {...register("consulting", { required: false, validate: validateMedium })} type="checkbox" value="E-Commerce" />
                                      <label
                                        className="text-blueGray-600 pl-2 "
                                      >
                                        E-Commerce
                                      </label>
                                      <br />

                                      <input {...register("consulting", { required: false, validate: validateMedium })} type="checkbox" value="Marketing" />
                                      <label
                                        className="text-blueGray-600 pl-2 "
                                      >
                                        Marketing
                                      </label>
                                      <br /><br />
                                      {err ? <span role="alert" className="text-red-500 pt-4">Please select your option</span> : null}
                                    </div>
                                    <br />
                                    <div className="lg:px-4 pb-2">
                                      <button
                                        type="submit"
                                        className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                      >
                                        Continue
                                      </button>
                                    </div>



                                  </form>
                                </>
                                :
                                null
                            }
                            {stepsec ?
                              <>
                                <form onSubmit={handleSubmit(onSubmitTwo)}>
                                  <div className="relative w-full text-xl ">
                                    <label
                                      className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                      htmlFor="grid-password"
                                    >
                                      How large is the business?
                                    </label>
                                    <input {...register("business", { required: true })} type="radio" value="Small (fewer than 10 employees)" />
                                    <label
                                      className="text-blueGray-600 pl-2 "
                                    >
                                      Small (fewer than 10 employees)
                                    </label>
                                    <br />

                                    <input {...register("business", { required: true })} type="radio" value="Medium (11-50 employees)" />
                                    <label
                                      className="text-blueGray-600 pl-2"
                                    >
                                      Medium (11-50 employees)
                                    </label>
                                    <br />

                                    <input {...register("business", { required: true })} type="radio" value="Large (more than 50 employees)" />
                                    <label
                                      className="text-blueGray-600 pl-2"
                                    >
                                      Large (more than 50 employees)
                                    </label>
                                    <br />

                                    <input {...register("business", { required: true })} type="radio" value="Not Sure" />
                                    <label
                                      className="text-blueGray-600 pl-2"
                                    >
                                      Not sure
                                    </label>
                                    <br /><br />
                                    {errors.business?.type === 'required' && <span role="alert" className="text-red-500 pt-4">Please select your option</span>}
                                  </div>
                                  <br />
                                  <div className="w-full flex flex-col md:flex-row px-4 text-center">
                                    <div className="lg:px-4 pb-2">
                                      <button
                                        className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                        onClick={() => { setStepfir(true); setStepsec(false); }}
                                      >
                                        Back
                                      </button>
                                    </div>
                                    <div className="lg:px-4 pb-2">
                                      <button
                                        type="submit"
                                        className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                      >
                                        Continue
                                      </button>
                                    </div>

                                  </div>

                                </form>
                              </>
                              :
                              null
                            }
                            {
                              stepthi ?
                                <>
                                  <form onSubmit={handleSubmit(onSubmitThree)}>
                                    <div className="relative w-full text-xl ">
                                      <label
                                        className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                        htmlFor="grid-password"
                                      >
                                        How long has the business been running?
                                      </label>
                                      <input {...register("running", { required: true })} type="radio" value="Less than 1 year" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        Less than 1 year
                                      </label>
                                      <br />

                                      <input {...register("running", { required: true })} type="radio" value="2-3 years" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        2-3 years
                                      </label>
                                      <br />

                                      <input {...register("running", { required: true })} type="radio" value="4-5 years" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        4-5 years
                                      </label>
                                      <br />

                                      <input {...register("running", { required: true })} type="radio" value="6 years or more" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        6 years or more
                                      </label>
                                      <br /><br />
                                      {errors.running?.type === 'required' && <span role="alert" className="text-red-500 pt-4">Please select your option</span>}
                                    </div>
                                    <br />
                                    <div className="w-full flex flex-col md:flex-row px-4 text-center">
                                      <div className="lg:px-4 pb-2">
                                        <button
                                          className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                          onClick={() => { setStepsec(true); setStepthi(false); }}
                                        >
                                          Back
                                        </button>
                                      </div>
                                      <div className="lg:px-4 pb-2">
                                        <button
                                          type="submit"
                                          className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                        >
                                          Continue
                                        </button>
                                      </div>

                                    </div>
                                  </form>
                                </>
                                :
                                null
                            }
                            {
                              stepfou ?
                                <>
                                  <form onSubmit={handleSubmit(onSubmitFour)}>
                                    <div className="relative w-full text-xl ">
                                      <label
                                        className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                        htmlFor="grid-password"
                                      >
                                        What is the business' current annual revenue?
                                      </label>
                                      <input {...register("revenue", { required: true })} type="radio" value="Less than C$50k" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        Less than C$50k
                                      </label>
                                      <br />

                                      <input {...register("revenue", { required: true })} type="radio" value="C$50k - C$100k" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        C$50k - C$100k
                                      </label>
                                      <br />

                                      <input {...register("revenue", { required: true })} type="radio" value="C$100k - C$250k" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        C$100k - C$250k
                                      </label>
                                      <br />

                                      <input {...register("revenue", { required: true })} type="radio" value="C$250k - C$500k" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        C$250k - C$500k
                                      </label>
                                      <br />

                                      <input {...register("revenue", { required: true })} type="radio" value="C$500k - C$1m" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        C$500k - C$1m
                                      </label>
                                      <br />

                                      <input {...register("revenue", { required: true })} type="radio" value="C$1m - C$5m" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        C$1m - C$5m
                                      </label>
                                      <br />

                                      <input {...register("revenue", { required: true })} type="radio" value="More than C$5m" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        More than C$5m
                                      </label>
                                      <br />

                                      <input {...register("revenue", { required: true })} type="radio" value="I'd prefer not to say" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        I'd prefer not to say
                                      </label>
                                      <br /><br />
                                      {errors.revenue?.type === 'required' && <span role="alert" className="text-red-500 pt-4">Please select your option</span>}
                                    </div>
                                    <br />
                                    <div className="w-full flex flex-col md:flex-row px-4 text-center">
                                      <div className="lg:px-4 pb-2">
                                        <button
                                          className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                          onClick={() => { setStepthi(true); setStepfou(false); }}
                                        >
                                          Back
                                        </button>
                                      </div>
                                      <div className="lg:px-4 pb-2">
                                        <button
                                          type="submit"
                                          className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                        >
                                          Continue
                                        </button>
                                      </div>

                                    </div>
                                  </form>
                                </>
                                :
                                null
                            }
                            {
                              stepfiv ?
                                <>
                                  <form onSubmit={handleSubmit(onSubmitFive)}>
                                    <div className="relative w-full text-xl ">
                                      <label
                                        className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                        htmlFor="grid-password"
                                      >
                                        What industry is your business in?
                                      </label>
                                      <input
                                        type="text"
                                        {...register("industry", {
                                          required: {
                                            value: "required",
                                            message: "Please enter your industry"
                                          }
                                        })}
                                        className="border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                      />
                                      <br /><br />
                                      {errors.industry && <span role="alert" className="text-red-500 pt-4">{errors.industry.message}</span>}
                                    </div>
                                    <br />
                                    <div className="w-full flex flex-col md:flex-row px-4 text-center">
                                      <div className="lg:px-4 pb-2">
                                        <button
                                          className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                          onClick={() => { setStepfou(true); setStepfiv(false); }}
                                        >
                                          Back
                                        </button>
                                      </div>
                                      <div className="lg:px-4 pb-2">
                                        <button
                                          type="submit"
                                          className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                        >
                                          Continue
                                        </button>
                                      </div>

                                    </div>
                                  </form>
                                </>
                                :
                                null
                            }
                            {
                              stepsix ?
                                <>
                                  <form onSubmit={handleSubmit(onSubmitSix)}>
                                    <div className="relative w-full text-xl ">
                                      <label
                                        className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                        htmlFor="grid-password"
                                      >
                                        When would you like the consultant to start?
                                      </label>
                                      <input {...register("start", { required: true })} type="radio" value="As soon as possible" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        As soon as possible
                                      </label>
                                      <br />

                                      <input {...register("start", { required: true })} type="radio" value="Within the next month" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        Within the next month
                                      </label>
                                      <br />

                                      <input {...register("start", { required: true })} type="radio" value="Within the next 3 months" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        Within the next 3 months
                                      </label>
                                      <br />

                                      <input {...register("start", { required: true })} type="radio" value="Within the next 6 months" />
                                      <label
                                        className="text-blueGray-600 pl-2"
                                      >
                                        Within the next 6 months
                                      </label>
                                      <br /><br />
                                      {errors.start?.type === 'required' && <span role="alert" className="text-red-500 pt-4">Please select your option</span>}
                                    </div>
                                    <br />
                                    <div className="w-full flex flex-col md:flex-row px-4 text-center">
                                      <div className="lg:px-4 pb-2">
                                        <button
                                          className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                          onClick={() => { setStepfiv(true); setStepsix(false); }}
                                        >
                                          Back
                                        </button>
                                      </div>
                                      <div className="lg:px-4 pb-2">
                                        <button
                                          type="submit"
                                          className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                        >
                                          Continue
                                        </button>
                                      </div>

                                    </div>
                                  </form>
                                </>
                                :
                                null
                            }
                            {
                              stepsev ?
                                <>
                                  <form onSubmit={handleSubmit(onSubmitSeven)}>
                                    <div className="relative w-full text-xl ">
                                      <label
                                        className="block uppercase text-blueGray-600 text-2xl md:text-xl font-bold mb-4"
                                        htmlFor="grid-password"
                                      >
                                        What email address would you like quotes sent to?
                                      </label>
                                      <input
                                        type="text"
                                        {...register("email", {
                                          required: {
                                            value: "required",
                                            message: "Please enter your email id"
                                          },
                                          pattern: {
                                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: "Please enter valid email id"
                                          }
                                        })}
                                        className="border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Email"
                                      />
                                      <br /><br />
                                      {errors.email && <span role="alert" className="text-red-500 pt-4">{errors.email.message}</span>}
                                    </div>
                                    <br />
                                    <div className="w-full flex flex-col md:flex-row px-4 text-center">
                                      <div className="lg:px-4 pb-2">
                                        <button
                                          className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                          onClick={() => { setStepsix(true); setStepsev(false); }}
                                        >
                                          Back
                                        </button>
                                      </div>
                                      <div className="lg:px-4 pb-2">
                                        <button
                                          type="submit"
                                          className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-16 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                        >
                                          Get Quote
                                        </button>
                                      </div>

                                    </div>
                                  </form>
                                </>
                                :
                                null
                            }
                            {
                              success ?
                                <p className="text-center text-green-600 px-4 md:px-16">
                                  <p className="text-4xl"><i class="fas fa-check-circle"></i></p>
                                  We appreciate you contacting iBusiness Consulting.<br />One of our colleagues will get back in touch with you soon!<br />Have a great day!
                                </p>
                                :
                                null
                            }
                            {
                              error ?
                                <p className="text-center text-red-500 px-20">
                                  <p className="text-4xl"><i class="fa fa-times-circle" aria-hidden="true"></i></p>
                                  Oops! Something went wrong. Please try again.
                                </p>
                                :
                                null
                            }
                          </div>
                          {/* {
                                stepzero ?
                                  <div className="w-full flex flex-col md:flex-row px-4 text-center">
                                    <div className="lg:px-2 pb-2">
                                    <button
                                      className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-20 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                      onClick={() => {
                                        console.log("modal closed");
                                        close();
                                      }}
                                    >
                                      close
                                    </button>
                                    </div>
                                    <div className="lg:px-2 pb-2">
                                    <button
                                      className="bg-lightBlue-500 hover:bg-lightBlue-600 text-white hover:text-white text-m font-bold uppercase px-8 py-3 rounded-full justify-center" style={{ padding: "2" }}
                                      onClick={() => { setStepzero(false); setStepfir(true); setStepsec(false); setStepthi(false); setStepfou(false); setStepfiv(false); setStepsix(false); setStepsev(false); }}
                                    >
                                      Let's Get Started
                                    </button>
                                    </div>
                                  </div>
                                  :
                                  null
                              }*/}
                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
              </div>
              <h1 className="text-blueWhale text-4xl mt-6 font-extrabold">Operational Efficiency Services</h1>
              <p className="text-lg py-4 ">In a business context, operational efficiency is a measurement of resource
                allocation and can be defined as the ratio between an output gained from the business and an input to
                run a business operation. When improving operational efficiency, the output to input ratio improves which is the a growth oriented.</p>
            </div>
          </div>
        </div>

      </div>



      <Footer />
    </>
  );
}