import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from 'semantic-ui-react';
import Corporate from "components/Datas/Corporate.js"
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Webdevelop() {



    return (
        <>
            <IndexNavbar fixed />
            <section className="lg:h-600-px md:h-screen">
                <div className="container mx-auto lg:pt-32 md:pt-32 pt-24">
                    <div className="w-full lg:w-12/12 md:w-12/12 lg:pt-16 pt-12 lg:px-12">
                        <div className="lg:flex flex-wrap lg:px-12 ">
                            <div className="lg:w-2/12">
                            </div>
                            <div className="lg:w-8/12 items-center lg:pt-6 md:pt-24">
                                <div className="">
                                    {" "}

                                    <h1 className="ttt text-3xl text-black text-center pb-12 pt-12">
                                        Which type (s) of consulting are you interested in ?
                                    </h1>

                                    <div className="flex flex-wrap aligncenters pt-6">
                                        <div className="lg:w-3/12 w-12/12 md:w-6/12 lg:px-2 px-6">
                                            <a href="/corporate">
                                                <label className="card ">
                                                    <div className="hover:shadow-lg shadow-sm border bg-lightred border-lightred pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4 ">
                                                        <img
                                                            className=" w-16 pb-2"
                                                            src={require("assets/img/SERVICE/CS.png").default}
                                                            alt="..."
                                                        />
                                                        <div className=" ttt text-black lg:text-lg text-2xl pt-4 md:text-xl">
                                                            Corporation Services
                                                        </div>
                                                        <br />

                                                    </div>
                                                </label>
                                            </a>
                                        </div>
                                        <div className="lg:w-3/12 w-12/12 md:w-6/12 lg:px-2 px-6 lg:pt-0 md:pt-0 pt-8">
                                            <a href="/hrserv">
                                                <label className="card ">
                                                    <div className="hover:shadow-lg shadow-sm bg-lightblue border-lightblue border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4 ">
                                                        <img
                                                            className=" w-16 pb-2"
                                                            src={require("assets/img/SERVICE/HRS.png").default}
                                                            alt="..."
                                                        />
                                                        <div className=" ttt text-black  lg:text-lg  text-2xl pt-4 md:text-xl">
                                                            HR Services
                                                        </div>
                                                        <br />

                                                    </div>
                                                </label>
                                            </a>
                                        </div>
                                        <div className="lg:w-3/12 w-12/12 md:w-6/12 lg:px-2 px-6 lg:pt-0 md:pt-6 pt-8">
                                            <a href="/itserv">
                                                <label className="card ">
                                                    <div className="hover:shadow-lg shadow-sm border-lightgreen bg-lightgreen border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4 ">
                                                        <img
                                                            className=" w-16 pb-2"
                                                            src={require("assets/img/SERVICE/IPS.png").default}
                                                            alt="..."
                                                        />
                                                        <div className="ttt text-black  lg:text-lg  text-2xl pt-4 md:text-xl">
                                                            IT Services
                                                        </div>
                                                        <br />
                                                    </div>
                                                </label>
                                            </a>
                                        </div>
                                        <div className="lg:w-3/12 w-12/12 md:w-6/12 lg:px-2 px-6 lg:pt-0 md:pt-6 pt-8 lg:pb-0 pb-12">
                                            <a href="/ipserv">
                                                <label className="card ">
                                                    <div className="hover:shadow-lg shadow-sm bg-lightorange border-lightorange border pt-6 pb-4 rounded-lg hover:border-lightBlue-600 px-4 ">
                                                        <img
                                                            className=" w-16 pb-2"
                                                            src={require("assets/img/SERVICE/ITS.png").default}
                                                            alt="..."
                                                        />
                                                        <div className="ttt text-black lg:text-lg text-2xl pt-4 md:text-xl">
                                                            IP Services
                                                        </div>
                                                        <br />

                                                    </div>
                                                </label>
                                            </a>
                                        </div>
                                        <br />
                                        <br />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />


        </>


    );
}


