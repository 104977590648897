import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Spinner from "react-spinkit";
import Moment from 'moment';

export default function CardTable({ color }) {

  const [purchased, setPurchased] = useState("");
  const [starts, setStarts] = useState("");
  const [expired, setExpired] = useState("");
  const [duration, setDuration] = useState("");
  const [upgrades, setUpgrades] = useState([]);
  const { id } = useParams();
  const [DataisLoaded, setDataisLoaded] = useState(false)

  let year = new Date().getFullYear();
  let month = ((new Date().getMonth() + 1) < 10) ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`;
  let date = ((new Date().getDate()) < 10) ? `0${new Date().getDate()}` : `${new Date().getDate()}`;
  let date1 = new Date(year + '-' + month + '-' + date);
  let date2 = new Date(expired);

  useEffect(() => {
    fetch(`https://ibusinessconsulting.ca/admin/upgradereact/customers_upgrade/${id}`)
      .then(res => res.json())
      .then(
        (results) => {
        //  console.log(results)
          setUpgrades(results)
          setPurchased(results[0].purchased)
          setDuration(results[0].duration)
          setStarts(results[0].starts)
          setExpired(results[0].expired)
          setDataisLoaded(true)
        })
  }, [])

  if (!DataisLoaded) return <div class="flex h-screen justify-center items-center">
    <div class="text-center bg-blue-400">
      <Spinner name="chasing-dots" />
    </div>
  </div>;
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-4/12 mb-12 xl:mb-0 ">
          <div
            className={
              "shadow-lg rounded " +
              (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
            }
          >
            <div className="rounded-t mb-0 px-2 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                  <h3
                    className={
                      "font-semibold text-xl " +
                      (color === "light" ? "text-blueGray-700" : "text-white")
                    }
                  >
                    Membership
                  </h3>
                </div>
              </div>
            </div>
            <div className="block w-full overflow-x-auto">
              <hr />
              <table className="items-center w-full bg-transparent border-collapse">
                <tbody>
                  <tr>
                    <th className="border-t-0 lg:px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left flex items-center">
                      <span
                        className={
                          "ml-3 font-semibold " +
                          +(color === "light" ? "text-blueGray-600" : "text-white")
                        }
                      >
                        Purchased Plan
                      </span>
                    </th>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                      {purchased}
                    </td>
                  </tr>
                  <tr>
                    <th className="border-t-0 lg:px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left flex items-center">
                      <span
                        className={
                          "ml-3 font-semibold " +
                          +(color === "light" ? "text-blueGray-600" : "text-white")
                        }
                      >
                        Duration
                      </span>
                    </th>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                      {duration}
                    </td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <th className="border-t-0 lg:px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left flex items-center">
                      <span
                        className={
                          "ml-3 font-semibold " +
                          +(color === "light" ? "text-blueGray-600" : "text-white")
                        }
                      >
                        Starts From
                      </span>
                    </th>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                      {Moment(starts).format('DD/MM/YYYY')}
                    </td>
                  </tr>
                  <tr>
                    <th className="border-t-0 lg:px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left flex items-center">
                      <span
                        className={
                          "ml-3 font-semibold " +
                          +(color === "light" ? "text-blueGray-600" : "text-white")
                        }
                      >
                        {expired >= (year + '-' + month + '-' + date) ? "Expires On" : "Expired On"}
                      </span>
                    </th>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                      {Moment(expired).format('DD/MM/YYYY')}
                    </td>
                  </tr>
                  <tr>
                    <th className="border-t-0 lg:px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left flex items-center">
                      <span
                        className={
                          "ml-3 font-semibold " +
                          +(color === "light" ? "text-blueGray-600" : "text-white")
                        }
                      >
                        Status
                      </span>
                    </th>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                      {expired >= (year + '-' + month + '-' + date) ? "Active" : "Inactive"}
                    </td>
                  </tr>
                  <tr>
                    <th className="border-t-0 lg:px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left flex items-center">
                      <span
                        className={
                          "ml-3 font-semibold " +
                          +(color === "light" ? "text-blueGray-600" : "text-white")
                        }
                      >
                        Remaining days to expire
                      </span>
                    </th>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                      {expired >= (year + '-' + month + '-' + date) ? Math.ceil((Math.abs(date2 - date1)) / (1000 * 60 * 60 * 24)) : "0"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

        {/*Purchased History*/}

        <div className="w-full xl:w-8/12 pl-0 md:pl-4">
          <div
            className={
              "shadow-lg rounded " +
              (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
            }
          >
            <div className="rounded-t mb-0 px-2 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                  <h3
                    className={
                      "font-semibold text-xl " +
                      (color === "light" ? "text-blueGray-700" : "text-white")
                    }
                  >
                    Purchased History
                  </h3>
                </div>
              </div>
            </div>
            <div className="block w-full overflow-x-auto">
              {/* Projects table */}
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-base uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                    >
                      Plan
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-base uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                    >
                      Duration
                    </th>
                    {/*<th
                  className={
                    "px-6 align-middle border border-solid py-3 text-base uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Original Amount
                </th>*/}
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-base uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                    >
                      Amount Paid
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-base uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                    >
                      Starts From
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-base uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                    >
                      Ends On
                    </th>
                    {/*<th
                  className={
                    "px-6 align-middle border border-solid py-3 text-base uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Mode of Payment
                </th>*/}
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-base uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                    >
                      Invoice
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {upgrades.map(item => (
                    <tr key={item.id}>
                      <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left flex items-center">
                        <span
                          className={
                            "ml-3 font-semibold " +
                            +(color === "light" ? "text-blueGray-600" : "text-white")
                          }
                        >
                          {item.purchased}
                        </span>
                      </th>
                      <td className="border-t-0 px-2 align-middle pl-14 border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                        {item.duration}
                      </td>
                      {/*<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item.amount}
                    </td>*/}
                      <td className="border-t-0 px-6 align-middle pl-14 border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                        {item.original_amount - item.coupon_discount}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                        {item.starts}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                        {item.expired}
                      </td>
                      {/*<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                    {item.payment}
                    </td>*/}
                      <td className="border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                        {/*  <a href="/invoice" target="_blank"><i className="fa fa-download" aria-hidden="true"></i></a> */}
                        <Link to={{
                          pathname: "/invoice",
                          state: {
                            purchased: item.purchased,
                            duration: item.duration,
                            amount: item.amount,
                            discount: item.coupon_discount,
                            amount_paid: item.original_amount,
                            expired: item.expired,
                            starts: item.starts
                          }
                        }}>
                          <i className="fa fa-file-pdf-o" title="View Invoice" aria-hidden="true">&nbsp;PDF</i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
